/* eslint-disable no-lonely-if */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable-next-line no-underscore-dangle */

/**
 * React component for Single Select Picker.
 * @module src/components/shared/AutocompleteSingleSelectPicker
 */
import React, { FC, useCallback, useState, useEffect } from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Icon from '@salesforce/design-system-react/components/icon';
import escapeRegExp from 'lodash.escaperegexp';
import moment from 'moment';

interface Selection {
  eventSubType?: string;
  eventType?: string;
  eventId?: any;
  eventStartDate?: any;
  productDescription?: any;
  productdescription?: any;
  productNumber?: any;
  isPrimaryAddress?: any;
  address1?: any;
  address2?: any;
  state?: any;
  postalcode?: any;
  __typename: string;
  number?: any;
  name?: string;
  id: string;
  label: string;
  labelpage?: string;
  value: string;
  isRepStockAccount?: boolean;
  isOtherAddress?: boolean;
  locationDescription?: string;
  city?: string;
  itHasInventory?: boolean;
}

interface SelectedData {
  selection: Selection[];
}

interface ValueInterface {
  value: string;
}

type SelectionType = Selection[];

interface SurgeonWorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}

interface Props {
  items: any;
  selectedItem: any;
  setSelectedItem: (items: SelectionType | null) => void;
  label: string;
  labelpage?: string;
  placeholder?: string;
  addItemLabel?: string;
  withAddMenuButton: boolean;
  fetchItems: (arg0: string) => string;
  filter: boolean;
  disabled: boolean;
  key?: string;
  required?: boolean;
  name: string;
  autoSelectValue?: boolean;
  multiple?: boolean;
  variant?: string;
  modalHeading?: string;
  isLoading?: boolean;
}

const AutocompleteSingleSelectPicker: FC<Props> = props => {
  const [inputValue, setInputValue] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isShipToChanged, setIsShipToChanged] = useState(false);
  const [isAccountChanged, setIsAccountChanged] = useState(false);
  const [locked, setLocked] = useState(false);
  const {
    items,
    setSelectedItem,
    selectedItem,
    label,
    labelpage,
    placeholder,
    addItemLabel = '',
    withAddMenuButton = false,
    fetchItems,
    filter = false,
    disabled,
    required,
    autoSelectValue = true,
    name,
    multiple,
    variant,
    modalHeading,
    isLoading,
  } = props;

  const selectedItemLength = selectedItem ? selectedItem.length : 0;
  const itemLength = items ? items.length : 0;
  useEffect(() => {
    if (itemLength === 1 && selectedItemLength === 0 && inputValue === '' && autoSelectValue) {
      if (!withAddMenuButton) {
        setSelectedItem(items);
      }
    }
  }, []);

  useEffect(() => {
    if ((name === 'shipTo' || name === 'billTo') && items?.length) {
      if (window.location.href.includes('Orders')) {
        if (items?.length && !locked && !selectedItem) {
          setSelectedItem(items);
        }
        if (selectedItem.length === 0) setSelectedItem(items);
      } else {
        if (items?.length && !locked) {
          setSelectedItem(items);
        }
      }
      if (!autoSelectValue && selectedItemLength === 0 && inputValue === '') {
        setSelectedItem([]);
      } else {
        if (
          name === 'billTo' &&
          (items?.filter((item: any) => item.isPrimaryAccount).length > 1 ||
            items?.filter((item: any) => item.isPrimaryAccount).length === 0)
        ) {
          setSelectedItem([]);
        } else if (items?.length > 1) {
          if (items?.filter((item: any) => item.isPrimaryAccount).length === 1) {
            setSelectedItem(items?.filter((item: any) => !item.id || item.isPrimaryAccount));
          }
          //  setSelectedItem([]);
        } else if (items?.length === 1) {
          setSelectedItem(items);
        } else if (!autoSelectValue && selectedItemLength === 0 && inputValue === '') {
          setSelectedItem([]);
        } else {
          const record = (items || []).find(
            (rec: any) => rec.isPrimaryAddress || rec.isOtherAddress
          );
          if (record && !(modalHeading?.includes('Standard') || modalHeading?.includes('Pickup'))) {
            setSelectedItem([record as any]);
          } else setSelectedItem([]);
        }
      }
    }
  }, [
    autoSelectValue,
    inputValue,
    itemLength,
    items,
    // selectedItemLength,
    // setSelectedItem,
    withAddMenuButton,
  ]);

  useEffect(() => {
    setLocked(false);
  }, [items, itemLength]);

  // USeEffect to check only selected item length and update the formik form with default value
  useEffect(() => {
    if (selectedItemLength === 1) {
      setSelectedItem([selectedItem[0]]);
    }
  }, [selectedItemLength, disabled]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        (name === 'primaryAccount' || name === 'sourceAccount' || name === 'destinationAccount') &&
        !(modalHeading?.includes('Standard') || modalHeading?.includes('Pickup')) &&
        !selectedItem.length &&
        !isAccountChanged &&
        items?.length
      ) {
        const record = (items || []).filter(
          (item: any) => item.isRepStockAccount || items.length === 1
        );
        if (record.length === 1) {
          setIsAccountChanged(true);
          setSelectedItem(record);
        }
      } else if (
        name === 'sourceAccount' &&
        isAccountChanged &&
        (modalHeading?.includes('Rep Transfer') || modalHeading?.includes('Rep to Rep'))
      ) {
        if (items?.length > 0) {
          const recordNew = (items || [])?.filter(
            (item: any) => item?.isRepStockAccount || items?.length === 1
          );
          if (recordNew?.length === 1) {
            setSelectedItem(recordNew);
          } else {
            setSelectedItem([]);
          }
        } else {
          setSelectedItem([]);
        }
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [items, modalHeading]);

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout;
    if (name === 'PO') {
      if (inputValue && inputValue.length >= 1) {
        delayDebounceFn = setTimeout(() => {
          const itemsData: any = [];
          itemsData.push({ label: inputValue, id: inputValue, value: inputValue });
          setSelectedItem(itemsData);
        }, 2000);
      }
    } else if (inputValue && inputValue?.trim()?.length >= 3 && name === 'InventorySearchaccount') {
      delayDebounceFn = setTimeout(() => {
        fetchItems(inputValue?.trim());
      }, 1000);
    } else {
      delayDebounceFn = setTimeout(() => {
        if (inputValue && inputValue.length >= 3) {
          fetchItems(inputValue);
        }
      }, 2000);
    }

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  const handleChange = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);

      if (value.length === 0 && filter && name === 'InventorySearchaccount') {
        fetchItems('');
      }
    },
    [fetchItems, filter, setInputValue, name]
  );

  const handleRemoveSelection = useCallback(
    (_event, data: SelectedData) => {
      if (!disabled) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { selection } = data;
        setInputValue('');
        setSelectedItem([]);
        setLocked(true);
      }
    },
    [disabled, setSelectedItem]
  );

  const handleSelectItem = useCallback(
    (_event, data: SelectedData) => {
      const { selection } = data;
      setInputValue('');
      setSelectedItem(selection);
    },
    [setInputValue, setSelectedItem]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const comboboxFilterAndLimit = (parameters: any): SelectionType => {
    const { searchedValue, options, selection } = parameters;
    const inputValueRegExp = new RegExp(escapeRegExp(searchedValue), 'ig');
    return options.filter((option: Selection) => {
      const searchTermFound = option.label ? option.label.match(inputValueRegExp) : false;
      const isSeparator = false;
      const notAlreadySelected = !selection.some((sel: Selection) => sel.id === option.id);
      return (!searchedValue || isSeparator || searchTermFound) && notAlreadySelected;
    });
  };

  const getSanitizedArray = (array: any[]): any[] => {
    const cleanedArray = array?.filter((item: any) => item !== undefined && item !== null);
    return cleanedArray?.filter((item: any, index: number, self: any[]) => {
      if (item.id) return index === self?.findIndex((element: any) => element.id === item.id);
      else return item;
    });
  };

  const sanitizedData = getSanitizedArray(items);

  const optionsWithLabel =
    sanitizedData &&
    sanitizedData.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === 'ProcedureDetail' || elem.__typename === 'State') {
        return { ...elem, label: elem.name };
      }
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === 'ProcedureType') {
        return { ...elem, label: elem.value };
      }
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === 'ShipToAddress') {
        if (elem.isOtherAddress) {
          return {
            ...elem,
            label: `${elem.number ? `${elem?.number}-` : ''} ${elem.address1 || ''}, ${
              elem.address2 ? `${elem.address2},` : ''
            } ${elem.city || ''}, ${elem.state || ''}, ${
              elem.name ? `${elem.name},` : ''
            } ${elem.postalcode || ''}`,
          };
        } else {
          return {
            ...elem,
            label: `${
              elem.address1 && elem.name && elem.postalcode
                ? `${elem.isPrimaryAddress ? '*' : ''} ${elem.name} - ${
                    elem.address1
                  } ${elem.address2 || ''}, ${elem?.city || ''}, ${
                    elem.state ? `${elem.state},` : ''
                  } ${elem.postalcode || ''}`
                : `${elem.name}`
            }`,
          };
        }
      }
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === 'ProductList') {
        return {
          ...elem,
          label: `${elem.productNumber ? elem.productNumber : ''} ${elem.productdescription}`,
        };
      }
      if (elem.__typename === 'Account') {
        return {
          ...elem,
          label: elem.label ? `${elem.label}` : `${elem.number ? elem.number : ''} ${elem.value}`,
        };
      }
      if (elem.__typename === 'OpportunitiesList') {
        return {
          ...elem,
          label: `${elem.name}`,
          // id: `${elem.name}`,
          value: `${elem.name}`,
        };
      }
      if (elem.__typename === 'UpcomingEventsList') {
        return {
          ...elem,
          label: `${moment(elem.eventStartDate).format('YYYY/MM/DD')} - ${elem.eventId}`,
        };
      }
      if (elem.type === 'assignedTo') {
        return {
          ...elem,
          label: `${elem?.value}`,
        };
      }
      return {
        ...elem,
        // eslint-disable-next-line no-nested-ternary
        label: `${elem.label ? elem.label : elem.number ? elem.number : elem.value}`,
      };
    });

  const optionalText = required ? '' : '';

  // eslint-disable-next-line no-nested-ternary
  const noOptionsFoundLabel = name === 'PO' ? '' : isLoading ? 'Loading ...' : 'No data found';

  return (
    <div className={`slds-form-element ${filter ? '' : 'slds-m-bottom_small'}`} key={label}>
      {/* {JSON.stringify(selectedItem)}
      123 */}
      {filter &&
        (label === 'Sales Rep' ||
          label === 'Product Group' ||
          label === 'Account' ||
          label === 'Assigned To' ||
          label === 'Region' ||
          label === 'Territory') && (
          <p
            style={{ fontSize: labelpage === 'Product Group transfer' ? '' : 15 }}
            className="selectbox_label"
          >
            {label}
          </p>
        )}
      <Combobox
        id={label}
        optionsAddItem={
          withAddMenuButton
            ? [
                {
                  id: 'options-add-id-1',
                  icon: (
                    <Icon
                      assistiveText={{ label: 'Add' }}
                      category="utility"
                      size="x-small"
                      name="add"
                    />
                  ),
                  label: (): [JSX.Element] => [<span key={label}>{addItemLabel}</span>],
                },
              ]
            : []
        }
        events={{
          onChange: handleChange,
          onRequestRemoveSelectedOption: handleRemoveSelection,
          onSelect: handleSelectItem,
        }}
        labels={{
          label: `${!filter ? label + optionalText : ''}`,
          placeholder,
          noOptionsFound: noOptionsFoundLabel,
        }}
        singleInputDisabled={disabled}
        options={comboboxFilterAndLimit({
          searchedValue: inputValue,
          options: optionsWithLabel || [],
          selection: selectedItem,
        })}
        multiple={multiple || false}
        menuItemVisibleLength={5}
        selection={selectedItem}
        value={inputValue}
        menuPosition="relative"
        variant={variant || 'inline-listbox'}
        required={required}
        hasInputSpinner={isLoading}
      />
    </div>
  );
};

/** React component for Autocomplete Single Select Picker. */
export default AutocompleteSingleSelectPicker;
