/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * Module for Events List.
 * @module src/Events/EventsList
 */
import React, { FC, useEffect, useState, useCallback } from 'react';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useSelector } from 'react-redux';

import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import Button from '@salesforce/design-system-react/components/button';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import EventsListAccountEventNameCell from './EventsListAccountEventNameCell';
import EventsListDateTimeCell from './EventsListDateTimeCell';
import EventsListEventIdCell from './EventsListEventIdCell';
import EventsListEventStatusCell from './EventsListEventStatusCell';
import EventsListEventTypeCell from './EventsListEventTypeCell';
import EventsListProcedureCell from './EventsListProcedureCell';
import EventsListRepCoveringCell from './EventsListRepCoveringCell';
import EventsListSurgeonCell from './EventsListSurgeonCell';
import EventsListEventsEditCell from './EventsListEventsEditCell';
import EventsListEventDescriptionCell from './EventsListEventDescriptionCell';
import { GET_EVENT_DETAILS } from '../../../graphql/getEventDetails';
import { GET_LIST_VIEW_CONFIGURATIONS } from '../../../graphql/getListViewConfigurations';
import CANCEL_EVENT from '../../../graphql/mutations/cancelEvent';

import { Event } from '../../../graphql/getEventItems';

import { LIMIT, NEXT_DATA, PREV_DATA } from '../../../util/constants';
import { getUserId } from '../../../store/ducks/userId';
import { getUserInfo } from '../../../store/ducks/userInfo';
import EventsListOracleStatusCell from './EventsListOracleStatusCell';
import CreateEditSurgeryEvent from '../../CreateEditSurgeryEvent';
import CancelEvent from '../../SurgicalDetail/SurgicalDetailCancelEvent';
import { GET_WORKFLOW_PERMISSION } from '../../../graphql/workflowPermissions';
import './index.scss';

// interface Props {
//   type: string;
//   // orders: any[];
//   getEventsData: (list: any) => void;
//   sortedcolumn: (property: any) => void;
//   setSortDirection: (property: any) => void;
//   // filters: any;
//   offsetData?: any;
// }

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}
// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
EventsListEventTypeCell.displayName = DataTableCell.displayName;
EventsListEventIdCell.displayName = DataTableCell.displayName;
EventsListAccountEventNameCell.displayName = DataTableCell.displayName;
EventsListEventStatusCell.displayName = DataTableCell.displayName;
EventsListSurgeonCell.displayName = DataTableCell.displayName;
EventsListOracleStatusCell.displayName = DataTableCell.displayName;
EventsListDateTimeCell.displayName = DataTableCell.displayName;
EventsListProcedureCell.displayName = DataTableCell.displayName;
EventsListEventDescriptionCell.displayName = DataTableCell.displayName;
EventsListEventsEditCell.displayName = DataTableCell.displayName;
EventsListRepCoveringCell.displayName = DataTableCell.displayName;

interface Filters {
  status?: string[];
  salesReps?: string[];
  coveringReps?: string[];
  account?: string;
  branch?: string[];
  franchise?: string[];
  surgeon?: string;
  procedureDetail?: string[];
  startDate?: Date | string;
  endDate?: Date | string;
  usage?: string;
  eventType?: string[];
  search?: string;
}

interface OracleWorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}
interface Props {
  sortDirectionData: string;
  sortedcolumn: (property: any) => void;
  setSortDirection: (property: any) => void;
  offsetData?: any;
  getEventsData: (variables: any) => void;
  eventItems: Event[];
  setOffset: (item: number) => void;
  offset: number;
  enableOracleStatus: OracleWorkflowPermission;
  activeTab: string;
  refetchEventItems: () => void;
  selectedFilterApiValues: Filters | null;
}

const EventsList: FC<Props> = ({
  getEventsData,
  eventItems,
  setOffset,
  offset,
  enableOracleStatus,
  activeTab,
  sortDirectionData,
  sortedcolumn,
  setSortDirection,
  refetchEventItems,
  selectedFilterApiValues,
}) => {
  const id = useSelector(getUserId);
  const [sortObj, setSortObj] = useState<Sort>();
  const handleSort = (sortColumn: any): void => {
    const sortProperty = sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    // needs to work in both directions
    const newDirection = sortDirectionData === 'asc' ? 'desc' : 'asc';
    setSortObj(sort);
    sortedcolumn(sortColumn);
    setSortDirection(newDirection);
    //  variables: { offset: finalOffset, limit: LIMIT, id, filters: selectedFilterApiValues },
    getEventsData({
      variables: {
        limit: LIMIT,
        id,
        orderBy: sortProperty,
        orderSortType: newDirection,
        offset,
        //   orderType: 'INVENTORY_REQUEST',
        // screenType: type.toUpperCase(),
        filters: selectedFilterApiValues,
      },
    });
  };
  const { data: showSurgeonData } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      action: 'view_surgeon_cases',
    },
  });

  const [getEventDetail, { data: eventDetailData, loading: eventDetailLoading }] = useLazyQuery(
    GET_EVENT_DETAILS
  );

  const { data: getListViewConfigurations } = useQuery(GET_LIST_VIEW_CONFIGURATIONS);

  const [setCancelEvent, { loading: cancelEventLoading, data: cancelEventData }] = useMutation(
    CANCEL_EVENT
  );
  const [getEditPermission, { data: enableEditButton }] = useLazyQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
  });
  const [getCancelPermission, { data: enableCancelButton }] = useLazyQuery(
    GET_WORKFLOW_PERMISSION,
    {
      fetchPolicy: 'cache-only',
    }
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState('');
  const [selectedEventSubType, setSelectedEventSubType] = useState('');
  const [selectedEventId, setSelectedEventId] = useState('');
  const [selectedCaseId, setSelectedCaseId] = useState('');
  const [selectedEventExternalId, setSelectedExternalId] = useState('');
  const [alert, showAlert] = useState(false);
  const userInfo = useSelector(getUserInfo);

  // This function fetches the next and previous records of the events list
  const handlePaginationButtonClicked = (type: string): void => {
    let finalOffset;
    if (type === NEXT_DATA) {
      finalOffset = offset + LIMIT;
    } else {
      finalOffset = offset - LIMIT;
    }
    if (activeTab === 'Requested' && !selectedFilterApiValues) {
      getEventsData({
        variables: {
          offset: finalOffset,
          limit: LIMIT,
          id,
          filters: { status: ['Requested', 'Failed'] },
        },
      });
    } else {
      getEventsData({
        variables: { offset: finalOffset, limit: LIMIT, id, filters: selectedFilterApiValues },
      });
    }

    setOffset(finalOffset);
  };
  const updatedEventItems =
    eventItems &&
    eventItems.map(element => {
      const event = { ...element };
      event.surgeonName = `${element.surgeonFirstName} ${element.surgeonLastName}`;
      return event;
    });

  const handleEditEvent = (
    eventId: string,
    eventExternalId: string,
    eventType: string,
    eventSubType: string
  ): void => {
    setSelectedEventType(eventType);
    setSelectedEventSubType(eventSubType);
    setSelectedEventId(eventId);
    getEventDetail({
      variables: { id: eventId, externalId: eventExternalId },
    });
    if (
      eventDetailData &&
      eventDetailData.getEventDetails &&
      eventDetailData.getEventDetails.caseId === eventId
    ) {
      setShowEditModal(true);
    }
  };
  const handleCancelEventModal = useCallback(
    (
      caseId?: string,
      eventExternalId?: string,
      eventType?: string,
      eventSubType?: string,
      eventId?: string
    ): void => {
      setSelectedEventType(eventType || '');
      setSelectedEventSubType(eventSubType || '');
      setSelectedCaseId(caseId || '');
      setSelectedEventId(eventId || '');
      setSelectedExternalId(eventExternalId || '');
      setShowCancelModal(!showCancelModal);
    },
    [showCancelModal]
  );

  const cancelEvent = (cancelReason: string): void => {
    const mutation = {
      // sfid: selectedCaseId,
      externalId: selectedEventExternalId,
      // reason: cancelReason || '',
    };
    setCancelEvent({
      variables: mutation,
    })
      .then(response => {
        if (
          response.data &&
          response.data.cancelEvent &&
          response.data.cancelEvent.message === 'success'
        ) {
          showAlert(true);
          setTimeout(() => {
            showAlert(false);
          }, 10000);
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error); // TODO change when some operation needs to be run on error.
      });
  };

  useEffect(() => {
    if (eventDetailData && eventDetailData.getEventDetails) {
      setShowEditModal(true);
    }
  }, [eventDetailData]);

  const eventListViewConfiguration = getListViewConfigurations?.getListViewConfigurations.eventlist;

  const getConfiguration = (key: string) => {
    if (eventListViewConfiguration) {
      return eventListViewConfiguration?.some(
        (listConfig: { key: string; isShow: boolean }) =>
          listConfig.key === key && listConfig.isShow === true
      );
    }
    return true;
  };

  useEffect(() => {
    if (
      cancelEventData &&
      cancelEventData.cancelEvent &&
      cancelEventData.cancelEvent.message === 'success'
    ) {
      if (refetchEventItems) {
        refetchEventItems();
      }
      handleCancelEventModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelEventData, refetchEventItems]);

  const handleShowDropdownOptions = (eventStatus?: string) => (): void => {
    getEditPermission({
      variables: {
        division: 'EU',
        state: eventStatus,
        action: 'enable_button_edit',
      },
    });
    getCancelPermission({
      variables: {
        division: 'EU',
        state: eventStatus,
        action: 'enable_button_cancel',
      },
    });
  };

  const handleModalToggle = useCallback((): void => {
    setShowEditModal(!showEditModal);
  }, [showEditModal]);

  return (
    <div style={{ overflow: 'auto' }}>
      <DataTable
        assistiveText={{
          columnSort: 'sort this column',
          columnSortedDescending: 'desc',
          columnSortedAscending: 'asc',
        }}
        fixedLayout
        items={updatedEventItems || []}
        id="DataTableExample-fixedLayout"
        className="font-12"
        fixedHeader
        joined
        keyboardNavigation
        onSort={handleSort}
      >
        {[
          <DataTableColumn
            key="eventType"
            label="Event Type"
            property="eventType"
            width="150px"
            primaryColumn
            isSorted={sortObj?.sortColumn === 'eventType'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.eventType}
          >
            <EventsListEventTypeCell />
          </DataTableColumn>,
          <DataTableColumn
            key="eventId"
            label="Event Id"
            property="eventId"
            width="112px"
            isSorted={sortObj?.sortColumn === 'eventId'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.eventId}
          >
            <EventsListEventIdCell />
          </DataTableColumn>,
          <DataTableColumn
            key="status"
            label="Status"
            property="status"
            width="112px"
            isSorted={sortObj?.sortColumn === 'status'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.status}
          >
            <EventsListEventStatusCell />
          </DataTableColumn>,
          <DataTableColumn
            key="eventName"
            label="Account"
            property="eventName"
            width="200px"
            isSorted={sortObj?.sortColumn === 'eventName'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.eventName}
          >
            <EventsListAccountEventNameCell />
          </DataTableColumn>,
          <DataTableColumn
            key="eventDescription"
            label="Event Description"
            property="eventDescription"
            width="140px"
            isSorted={sortObj?.sortColumn === 'eventDescription'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.eventDescription}
          >
            <EventsListEventDescriptionCell />
          </DataTableColumn>,
          getConfiguration('surgeonName') && (
            <DataTableColumn
              key="surgeonName"
              label="Surgeon"
              property="surgeonName"
              width="208px"
              isSorted={sortObj?.sortColumn === 'surgeonName'}
              sortable
              sortDirection={sortObj?.sortColumnDirection.surgeonName}
            >
              <EventsListSurgeonCell />
            </DataTableColumn>
          ),
          enableOracleStatus && (
            <DataTableColumn
              key="oracleStatus"
              label="Oracle Status/Number"
              property="oracleStatus"
              width="208px"
              isSorted={sortObj?.sortColumn === 'oracleStatus'}
              sortable
              sortDirection={sortObj?.sortColumnDirection.oracleStatus}
            >
              <EventsListOracleStatusCell />
            </DataTableColumn>
          ),
          !enableOracleStatus && getConfiguration('procedures') && (
            <DataTableColumn
              key="procedures"
              label="Procedure"
              property="procedures"
              width="208px"
              isSorted={sortObj?.sortColumn === 'procedures'}
              sortable
              sortDirection={sortObj?.sortColumnDirection.procedures}
            >
              <EventsListProcedureCell />
            </DataTableColumn>
          ),
          <DataTableColumn
            key="Sales"
            label="Sales Rep"
            property="sales"
            width="108px"
            isSorted={sortObj?.sortColumn === 'sales'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.sales}
          >
            <EventsListRepCoveringCell showSalesRep />
          </DataTableColumn>,
          <DataTableColumn
            key="territory"
            label="Territory"
            property="territory"
            width="108px"
            isSorted={sortObj?.sortColumn === 'territory'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.territory}
          />,
          getConfiguration('covering') && (
            <DataTableColumn
              key="covering"
              label="Covering Rep"
              property="covering"
              width="208px"
              isSorted={sortObj?.sortColumn === 'covering'}
              sortable
              sortDirection={sortObj?.sortColumnDirection.covering}
            >
              <EventsListRepCoveringCell showCoveringRep />
            </DataTableColumn>
          ),
          <DataTableColumn
            key="date"
            label="Date/Time"
            property="date"
            width="230px"
            isSorted={sortObj?.sortColumn === 'date'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.date}
          >
            <EventsListDateTimeCell />
          </DataTableColumn>,
          userInfo && userInfo?.personas !== '3PL Ops' && (
            <DataTableColumn
              key="more"
              label=""
              property="more"
              width="48px"
              isSorted={sortObj?.sortColumn === 'more'}
              sortable
              sortDirection={sortObj?.sortColumnDirection.more}
            >
              <EventsListEventsEditCell
                handleEditEvent={handleEditEvent}
                handleCancelEvent={handleCancelEventModal}
                handleShowDropdownOptions={handleShowDropdownOptions}
                enableEditButton={enableEditButton}
                enableCancelButton={enableCancelButton}
              />
            </DataTableColumn>
          ),
        ]}
      </DataTable>
      {true && (
        <CreateEditSurgeryEvent
          toggleOpen={handleModalToggle}
          isOpen={showEditModal}
          eventType={selectedEventType}
          eventSubType={selectedEventSubType}
          eventLabel={selectedEventType}
          eventDetail={eventDetailData && eventDetailData.getEventDetails}
          refetchEventItems={refetchEventItems}
          showSurgeonData={showSurgeonData}
        />
      )}
      {eventDetailLoading && (
        <Spinner
          size="large"
          variant="base"
          assistiveText={{ label: 'Loading...' }}
          key="spinner"
        />
      )}
      {showCancelModal && (
        <CancelEvent
          cancelModalVisible={showCancelModal}
          handleCancelEventModal={handleCancelEventModal}
          eventType={selectedEventType}
          eventId={selectedEventId}
          cancelEvent={cancelEvent}
          cancelEventLoading={cancelEventLoading}
        />
      )}
      {updatedEventItems && updatedEventItems.length > 0 ? (
        <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronleft"
              iconSize="small"
              disabled={!(offset > 0)}
              iconVariant="bare"
              // will fetch the previous events data
              onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
              variant="icon"
            />
          </div>
          <div className="slds-col slds-p-left_medium slds-p-right_medium">
            <span>{`${offset + 1} - ${offset + LIMIT}`}</span>
          </div>
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronright"
              iconSize="small"
              iconVariant="bare"
              disabled={eventItems && eventItems.length < 50}
              // will fetch the next events data
              onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
              variant="icon"
            />
          </div>
        </div>
      ) : (
        <div className="no-data-found-container">
          <h1 className="no-data-found-text">No Records Found</h1>
        </div>
      )}
      <div className="cancel-success">
        {alert && (
          <Pill
            labels={{
              label: 'Success: case successfully cancelled',
            }}
            icon={
              <Icon
                title="Success"
                category="utility"
                name="success"
                className="slds-icon-text-success"
              />
            }
          />
        )}
      </div>
    </div>
  );
};

/** Custom Component for Events List **/
export default EventsList;
