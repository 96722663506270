/**
 * Redux file for getting/setting exposure filters
 * @module src/store/ducks/exposureFilters
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_EXPOSURE_FILTERS = 'SET_EXPOSURE_FILTERS';
const CLEAR_EXPOSURE_FILTERS = 'CLEAR_EXPOSURE_FILTERS';

interface SetExposureFiltersActionType {
  payload: string | null;
  type: typeof SET_EXPOSURE_FILTERS;
}

interface ClearExposureFiltersActionType {
  type: typeof CLEAR_EXPOSURE_FILTERS;
}

/** EXPOSUREFilters action type */

export type ExposureFiltersActionType =
  | SetExposureFiltersActionType
  | ClearExposureFiltersActionType;

/** set EXPOSUREFilters action creator */

export const setExposureFilters = (state: string | null): ExposureFiltersActionType => {
  return {
    payload: state,
    type: SET_EXPOSURE_FILTERS,
  };
};

/** clear EXPOSUREFilters action creator */

export const clearExposureFilters = (): ClearExposureFiltersActionType => {
  return {
    type: CLEAR_EXPOSURE_FILTERS,
  };
};

/** EXPOSUREFilters state type */

export type ExposureFiltersState = string | null;

/** EXPOSUREFilters reducer */

export default (state: ExposureFiltersState = null, action: ActionType): ExposureFiltersState => {
  switch (action.type) {
    case SET_EXPOSURE_FILTERS: {
      return action.payload;
    }
    case CLEAR_EXPOSURE_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getExposureFilters = (state: State): ExposureFiltersState => {
  return state.exposureFilters;
};
