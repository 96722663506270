/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-indent */
import React, { useState, useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Spinner from '@salesforce/design-system-react/components/spinner';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import '../index.scss';
import Button from '@salesforce/design-system-react/components/button';
import SnackBar from '../../Shared/SnackBar';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import OrderRequestStatusCell from '../OrderDataTable/OrderRequestStatusCell';
import OrderMultiplePdf from '../OrderDataTable/OrderMultiplePdf';
import GeneratePdfHeader from './GeneratePdfHeader';
import debounced from '../../../util/debounced';
import { LIMIT, NEXT_DATA, PREV_DATA } from '../../../util/constants';
import { GET_MULTIPLE_PDF_DATA } from '../../../graphql/getMultiplePDFData';
import { getUserInfo } from '../../../store/ducks/userInfo';

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}
// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
OrderRequestStatusCell.displayName = DataTableCell.displayName;
OrderMultiplePdf.displayName = DataTableCell.displayName;

const GeneratedPdf = () => {
  const { open, notification } = useSnackBar();
  const [offset, setOffset] = useState(0);
  // const [sortColumn, setSortColumn] = useState<any>();
  const [sortObj, setSortObj] = useState<Sort>();
  const [selectedData, setSelectedData] = useState([]);
  const userInfo = useSelector(getUserInfo);
  const [getMultiplePDFData, { data: multiplePdfData, loading: loadingPdfList }] = useLazyQuery(
    GET_MULTIPLE_PDF_DATA,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const setRequestSearch = (searchedItem: string): void => {
    getMultiplePDFData({
      variables: {
        limit: LIMIT,
        offset,
        salesRepId: userInfo?.id,
        filters: { searchText: searchedItem },
      },
    });
  };

  useEffect(() => {
    getMultiplePDFData({
      variables: {
        limit: LIMIT,
        offset,
        salesRepId: userInfo?.id,
        filters: {},
      },
    });
  }, [userInfo, getMultiplePDFData, offset]);

  useEffect(() => {
    const PdfData = multiplePdfData?.getMultiplePDFData;
    const pdfItems: any = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pdfD = PdfData?.map((item: any) => {
      pdfItems.push({
        accountName: item.details[0].accountName,
        requestId: item.details[0].requestId,
        eventStatus: item.details[0].parentStatus,
        failureReason: item.details[0].failure_reason,
        requestedDate: item.details[0].requestedDate,
        expiredOn: item.details[0].expiredOn,
        salesrepId: item.details[0].salesrepId,
        accountId: item.details[0].accountId,
        surgeryDate: item.details[0].surgeryDate,
        allCases: item.details,
      });
    });
    setSelectedData(pdfItems);
  }, [multiplePdfData, setSelectedData]);

  const debouncedFetchRequestedItems = useCallback(debounced(500, setRequestSearch), [
    debounced,
    setRequestSearch,
  ]);

  const handleOrderSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchRequestedItems(value);
      } else {
        debouncedFetchRequestedItems(value);
      }
    },
    [debouncedFetchRequestedItems]
  );
  const handlePaginationButtonClicked = (nav: string): void => {
    let finalOffset;
    if (nav === NEXT_DATA) {
      finalOffset = offset + LIMIT;
    } else {
      finalOffset = offset - LIMIT;
    }
    setOffset(finalOffset);
    getMultiplePDFData({
      variables: {
        limit: LIMIT,
        offset: finalOffset,
        salesRepId: userInfo?.id,
        filters: {},
      },
    });
  };
  const handleSort = (sortColumn: any): void => {
    const sortProperty = sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    setSortObj(sort);
    // sortedcolumn(sortColumn);
    // getOrdersList({
    //   variables: {
    //     limit: 50,
    //     offset,
    //     type: type.toUpperCase(),
    //     orderBy: sortProperty,
    //     orderSortType: sortDirection,
    //   },
    // });
  };
  // const iconPath = getEventIcon(ORDERS);
  return (
    <IconSettings iconPath="/icons">
      {loadingPdfList && <Spinner />}
      <GeneratePdfHeader label="" handleOrderSearch={handleOrderSearch} />
      <div className="order-list-container">
        <DataTable
          assistiveText={{
            columnSort: 'sort this column',
            columnSortedAscending: 'asc',
            columnSortedDescending: 'desc',
            selectAllRows: 'Select all rows',
            selectRow: 'Select this row',
          }}
          items={selectedData}
          id="DataTableExample-2"
          className="font-12"
          fixedLayout
          keyboardNavigation
          joined
          onSort={handleSort}
        >
          <DataTableColumn
            key="requestId"
            label="Request Id"
            property="requestId"
            width="100px"
            isSorted={sortObj?.sortColumn === 'requestId'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.requestId}
          />
          <DataTableColumn
            key="accountName"
            label="Account"
            property="accountName"
            width="230px"
            isSorted={sortObj?.sortColumn === 'accountName'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.accountName}
          />
          <DataTableColumn
            key="parentStatus"
            label="Status"
            property="parentStatus"
            width="120px"
            isSorted={sortObj?.sortColumn === 'parentStatus'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.parentStatus}
          >
            <OrderRequestStatusCell />
          </DataTableColumn>
          <DataTableColumn
            key="failureReason"
            label="Failure Reason"
            property="failureReason"
            isSorted={sortObj?.sortColumn === 'failureReason'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.failureReason}
          />
          <DataTableColumn
            key="requestedDate"
            label="Requested On"
            property="requestedDate"
            isSorted={sortObj?.sortColumn === 'requestedDate'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.requestedDate}
          />
          <DataTableColumn
            key="expiredOn"
            label="Expired On"
            property="expiredOn"
            isSorted={sortObj?.sortColumn === 'expiredOn'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.expiredOn}
          />
          <DataTableColumn key="viewPdf" label="View PDF" property="viewPdf" width="100px">
            <OrderMultiplePdf selectedRows={selectedData} />
          </DataTableColumn>
        </DataTable>
      </div>
      <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
        <div className="slds-col">
          <Button
            assistiveText={{ icon: 'Icon Bare Small' }}
            iconCategory="utility"
            iconName="chevronleft"
            iconSize="small"
            disabled={!(offset > 0)}
            iconVariant="bare"
            onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
            variant="icon"
          />
        </div>
        <div className="slds-col slds-p-left_medium slds-p-right_medium">
          <span>{`${offset + 1} - ${offset + LIMIT}`}</span>
        </div>
        <div className="slds-col">
          <Button
            assistiveText={{ icon: 'Icon Bare Small' }}
            iconCategory="utility"
            iconName="chevronright"
            iconSize="small"
            iconVariant="bare"
            disabled={offset > 0}
            onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
            variant="icon"
          />
        </div>
      </div>
      <SnackBar open={open} notification={notification} />
    </IconSettings>
  );
};

export default GeneratedPdf;
