/**
 * Graphql mutation to update filter preset
 * @module src/graphql/updateFilterPreset
 */
import gql from 'graphql-tag';

interface FilterData {
  id: string;
  label: string;
}

interface FilterPresets {
  status: [FilterData];
  salesReps: [FilterData];
  coveringReps: [FilterData];
  account: [FilterData];
  branch: [FilterData];
  franchise: [FilterData];
  surgeon: [FilterData];
  procedureDetail: [FilterData];
  startDate: string;
  endDate: string;
  usage: string;
  eventType: [FilterData];
  type: [FilterData];
}

/** update filter preset */
const UPDATE_FILTER_PRESET = gql`
  mutation updateFilterPreset($filterValues: FilterPresets, $name: String!, $externalId: String!) {
    updateFilterPreset(filterValues: $filterValues, name: $name, externalId: $externalId) {
      message
    }
  }
`;

export default UPDATE_FILTER_PRESET;
