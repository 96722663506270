import React, { FC, useState } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { isValidEmail } from '../../../util/utilityFunctions';
import SurgerySheetMultiplePdf from '../../Usage/UsageDetails/UsageTabs/SurgerySheet/SurgerySheetMultiplePdf';
import EmailMultiplePdfModal from '../../Usage/UsageDetails/UsageTabs/SurgerySheet/EmailMultiplePdfModal';
import { API, NOTIFY_TYPE } from '../../../util/constants';
import { GET_ADMIN_FROM_CONFIG } from '../../../graphql/getAdminFormConfigurations';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { openSnackBar } from '../../../store/ducks/snackBar';
//  Interface for Data table data type for all the columns
export interface Props {
  children?: FC;
  item?: any;
  selectedRows: any;
}

const OrderMultiplePdf: FC<Props> = ({ item, selectedRows }) => {
  const [generatePdf, setGeneratePdf] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const { data: formConfigurations } = useQuery(GET_FORM_CONFIGURATIONS, {
    fetchPolicy: 'no-cache',
  });
  const userInfo = useSelector(getUserInfo);
  const businessUnit = userInfo?.businessunit || 'Sports Med';
  const { data: getAdminFormConfigurations } = useQuery(GET_ADMIN_FROM_CONFIG, {
    fetchPolicy: 'no-cache',
    variables: {
      businessunit: businessUnit,
      screen: 'Usage Sheet Template',
    },
  });
  const handleEmail = (): void => {
    setGeneratePdf(false);
    setShowEmailModal(true);
  };

  const generateMultiplePdf = () => {
    setGeneratePdf(true);
  };

  const handleClosePdfModal = (): void => {
    setGeneratePdf(false);
  };
  const handleSendEmail = (
    emailToAddresses: string[],
    emailBody: string,
    subject: string,
    emailAttachments: any
  ): void => {
    const validEmails = emailToAddresses.every((email: any) => isValidEmail(email));

    if (!validEmails) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'Invalid Email(s) exist(s). Please remove to proceed');
      return;
    }
    setSendingEmail(true);
    const withOrWithoutPriceString = '_SurgSheet_WPrice';

    const Items = emailAttachments?.map((cases: any) => {
      return { caseId: cases.CaseSfid, surgeryDate: cases.surgeryDate };
    });
    try {
      const body = JSON.stringify({
        items: Items,
        withOrWithoutPriceString,
        showHidePriceFlag: true,
        toAddresses: emailToAddresses.join(';'),
        subject,
        emailFrom: userInfo?.email,
        htmlBody: emailBody.replace(/\n\r?/g, '<br />') || 'Please find attached pdf',
        requestId: selectedRows[0]?.requestId,
      });

      fetch(`${API.MASTER_DATA.EMAIL_SERVICE}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body,
      }).then((res: any) => {
        setShowEmailModal(false);
        setSendingEmail(false);
        if (res && res.status === 200) {
          openSnackBar(NOTIFY_TYPE.SUCCESS, 'Email has been sent');
        }
      });
    } catch (error) {
      setSendingEmail(false);
      openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again');
    }
  };
  const handleToggleEmailModal = (): void => {
    setShowEmailModal(!showEmailModal);
  };

  return (
    <DataTableCell>
      <Button
        assistiveText={{ icon: 'View PDF' }}
        iconCategory="utility"
        iconName="preview"
        iconSize="medium"
        iconVariant="bare"
        onClick={() => generateMultiplePdf()}
        disabled={!(item.eventStatus === 'Completed' || item.eventStatus === 'Partial')}
        style={{ marginLeft: '15px' }}
        variant="icon"
      />
      {item && (
        <SurgerySheetMultiplePdf
          open={generatePdf}
          showPrice
          selectedCases={item}
          handleClosePdfModal={handleClosePdfModal}
          handleEmail={handleEmail}
        />
      )}
      {showEmailModal && (
        <EmailMultiplePdfModal
          open={showEmailModal}
          usageDetails={item}
          pdfTitle="invoice"
          sendingEmail={sendingEmail}
          handleToggleModal={handleToggleEmailModal}
          handleSendEmail={handleSendEmail}
          adminFormConfigurationsData={getAdminFormConfigurations?.getAdminFormConfigurations}
          formConfigurationsData={formConfigurations?.getFormConfigurations}
        />
      )}
    </DataTableCell>
  );
};

/** Custom Account/Event Name cell */
export default OrderMultiplePdf;
