/**
 * Graphql mutation to Generate Multiple PDF
 * @module src/graphql/generateMultiplePDF
 */
import gql from 'graphql-tag';

/** Generate Multiple PDF */
const GENERATE_MULTIPLE_PDF = gql`
  mutation generateMultiplePDF($requestId: String, $userId: String, $items: [ItemsData]) {
    generateMultiplePDF(requestId: $requestId, userId: $userId, items: $items) {
      message
      __typename
    }
  }
`;

export default GENERATE_MULTIPLE_PDF;
