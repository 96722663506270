/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-indent */
import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/react-hooks';
import DataTable from '@salesforce/design-system-react/components/data-table';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import '../index.scss';
import ASSIGN_INVENTORY_REQUEST from '../../../graphql/mutations/assignInventoryRequest';
import SnackBar from '../../Shared/SnackBar';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import { NOTIFY_TYPE, MULTIPLE_PDF_PERSONAS } from '../../../util/constants';
import OrderStatusCell from './OrderStatusCell';
import OrderIdCell from './OrderIdCell';
import AccountCell from './AccountCell';
import OrderRequestIdCell from './OrderRequestIdCell';
import EventCell from './EventCell';
import OrderRequestStatusCell from './OrderRequestStatusCell';
import InventoryRequestsAssignDataCell from './InventoryAssignDataCell';
import ResetCell from './ResetCell';
import { getUserInfo } from '../../../store/ducks/userInfo';
import OrderAccountNameCell from './OrderAccountNameCell';
import { GET_LIST_VIEW_CONFIGURATIONS } from '../../../graphql/getListViewConfigurations';
import OrderFailureRason from './OrderFailureReason';
import OrderType from './OrderType';
import OrderSurgeonNameCell from './OrderSurgeonName';
import OrderPOCell from './OrderPOCell';
import OrderProcedureCell from './OrderProcedureCell';
import EventIdCell from '../../Inventory/InventoryReturns/ReturnDataTable/EventIdCell';
import OrderListDateTimeCell from './OrderListDateTimeCell';
import OrderTotalAmountCell from './OrderTotalAmountCell';
import OrderSurgeryDateCell from './OrderSurgeryDateCell';

interface Props {
  type: string;
  orders: any[];
  isCsrUser: boolean;
  sortOrderList: (list: any[]) => void;
  sortedcolumn: (property: any) => void;
  getOrdersList: (variables: any) => void;
  orderResubmitPermission?: boolean;
  selectedFilterApiValues: any;
  refetch?: any;
  offset?: any;
  handleSelectedRows?: (item: any) => void;
}

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}

// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
OrderIdCell.displayName = DataTableCell.displayName;
OrderStatusCell.displayName = DataTableCell.displayName;
OrderRequestIdCell.displayName = DataTableCell.displayName;
OrderRequestStatusCell.displayName = DataTableCell.displayName;
AccountCell.displayName = DataTableCell.displayName;
EventCell.displayName = DataTableCell.displayName;
OrderAccountNameCell.displayName = DataTableCell.displayName;
OrderFailureRason.displayName = DataTableCell.displayName;
InventoryRequestsAssignDataCell.displayName = DataTableCell.displayName;
OrderType.displayName = DataTableCell.displayName;
OrderSurgeonNameCell.displayName = DataTableCell.displayName;
OrderListDateTimeCell.displayName = DataTableCell.displayName;
OrderPOCell.displayName = DataTableCell.displayName;
OrderTotalAmountCell.displayName = DataTableCell.displayName;
OrderProcedureCell.displayName = DataTableCell.displayName;
OrderSurgeryDateCell.displayName = DataTableCell.displayName;

ResetCell.displayName = DataTableCell.displayName;

const OrdersDataTable: FC<Props> = ({
  orders,
  type,
  sortedcolumn,
  getOrdersList,
  selectedFilterApiValues,
  refetch,
  offset,
  // isCsrUser,
  handleSelectedRows = () => null,
}) => {
  const [sortObj, setSortObj] = useState<Sort>();
  const [orderLengthExceed, setOrderLengthExceed] = useState(false);
  const userInfo = useSelector(getUserInfo);
  const isMultiplePdfPersonas =
    userInfo && MULTIPLE_PDF_PERSONAS?.find((item: any) => item === userInfo?.personas);
  const { open, notification, openSnackBar } = useSnackBar();
  const [assignToOps, { data: assignRespone }] = useMutation(ASSIGN_INVENTORY_REQUEST);
  const { data: getListViewConfigurations } = useQuery(GET_LIST_VIEW_CONFIGURATIONS);

  const orderListViewConfiguration = getListViewConfigurations?.getListViewConfigurations.orderlist;

  // eslint-disable-next-line consistent-return
  const multiplePDFRule: any = userInfo?.businessRules?.find(
    (br: any) => br.rules === 'Allow to generate Multiple PDF'
  );

  const getConfiguration = (key: string) => {
    if (orderListViewConfiguration) {
      return orderListViewConfiguration?.some(
        (orderConfig: { key: string; isShow: boolean }) =>
          orderConfig.key === key && orderConfig.isShow === true
      );
    }
    return true;
  };
  const handleSort = (sortColumn: any): void => {
    const sortProperty = sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    setSortObj(sort);
    sortedcolumn(sortColumn);
    if (type === 'Open') {
      getOrdersList({
        variables: {
          limit: 50,
          offset,
          type: type.toUpperCase(),
          orderBy: sortProperty,
          orderSortType: sortDirection,
          filters: selectedFilterApiValues,
        },
      });
    } else if (type === 'Failed') {
      getOrdersList({
        variables: {
          limit: 50,
          offset: 0,
          orderType: 'BILL_ONLY',
          screenType: type.toUpperCase(),
          orderBy: sortProperty,
          orderSortType: sortDirection,
          filters: selectedFilterApiValues,
        },
      });
    } else {
      getOrdersList({
        variables: {
          limit: 50,
          offset,
          orderType: 'BILL_ONLY',
          type: type.toUpperCase(),
          orderBy: sortProperty,
          orderSortType: sortDirection,
          filters: selectedFilterApiValues,
        },
      });
    }
  };

  useEffect(() => {
    if (assignRespone && assignRespone.assignInventoryRequest.message === NOTIFY_TYPE.SUCCESS) {
      openSnackBar(NOTIFY_TYPE.SUCCESS, 'Reset Assignment Successfully');
      refetch();
    }
  }, [assignRespone, openSnackBar, refetch]);
  const handleReset = (externalId: any): void => {
    assignToOps({
      variables: {
        externalId,
        assignedUser: '',
      },
    });
  };

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const handleRowChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: { selection: React.SetStateAction<string[]> }
  ) => {
    const items: any = data.selection;
    const isSameAccounts = items?.every((accountId: any) => {
      return accountId.accountId === items[0].accountId;
    });
    if (data.selection?.length > 5 && isSameAccounts) {
      setOrderLengthExceed(true);
    } else {
      setSelectedRows(data.selection);
      handleSelectedRows(data.selection);
    }
  };

  const handleCancelModal = (): void => {
    setOrderLengthExceed(false);
  };

  return (
    <IconSettings iconPath="/icons">
      <DataTable
        assistiveText={{
          columnSort: 'sort this column',
          columnSortedAscending: 'asc',
          columnSortedDescending: 'desc',
          selectAllRows: 'Select all rows',
          selectRow: 'Select this row',
        }}
        items={orders || []}
        id="DataTableExample-2"
        className="font-12"
        fixedLayout
        keyboardNavigation
        joined
        onSort={handleSort}
        onRowChange={handleRowChange}
        selection={selectedRows}
        selectRows={
          multiplePDFRule?.isRequired &&
          (type === 'Open' || type === 'Processed') &&
          isMultiplePdfPersonas
            ? 'checkbox'
            : null
        }
      >
        {type === 'Review'
          ? [
              <DataTableColumn
                key="createdDate"
                label="Submit Date"
                property="createdDate"
                isSorted={sortObj?.sortColumn === 'createdDate'}
                sortable
                width="110px"
                sortDirection={sortObj?.sortColumnDirection.createdDate}
              >
                <OrderListDateTimeCell />
              </DataTableColumn>,
              <DataTableColumn
                key="eventId"
                label="Order"
                property="eventId"
                width="100px"
                isSorted={sortObj?.sortColumn === 'eventId'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.eventId}
              >
                <OrderRequestIdCell type={type} />
              </DataTableColumn>,
              <DataTableColumn
                key="parentEventId"
                label="Event"
                property="parentEventId"
                width="100px"
                isSorted={sortObj?.sortColumn === 'parentEventId'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.parentEventId}
              >
                <EventIdCell type={type} />
              </DataTableColumn>,
              <DataTableColumn
                key="diplayordertype"
                label="Type"
                property="diplayordertype"
                isSorted={sortObj?.sortColumn === 'diplayordertype'}
                sortable
                width="95px"
                sortDirection={sortObj?.sortColumnDirection.diplayOrderType}
              />,
              <DataTableColumn
                key="eventStatus"
                label="Status"
                property="eventStatus"
                isSorted={sortObj?.sortColumn === 'eventStatus'}
                sortable
                width="120px"
                sortDirection={sortObj?.sortColumnDirection.eventStatus}
              >
                <OrderRequestStatusCell />
              </DataTableColumn>,
              <DataTableColumn
                key="failureReason"
                label="Failure Reason"
                property="failureReason"
                isSorted={sortObj?.sortColumn === 'failureReason'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.failureReason}
              />,
              <DataTableColumn
                key="accountName"
                label="Account"
                width="230px"
                property="accountName"
                isSorted={sortObj?.sortColumn === 'accountName'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.accountName}
              >
                <AccountCell type={type} />
              </DataTableColumn>,
              <DataTableColumn
                key="salesRep"
                label="Sales Rep"
                property="salesRep"
                isSorted={sortObj?.sortColumn === 'salesRep'}
                sortable
                width="100px"
                className="wrap-content"
                sortDirection={sortObj?.sortColumnDirection.salesRep}
              />,
              getConfiguration('po') && (
                <DataTableColumn
                  key="po"
                  label="PO"
                  property="PO"
                  isSorted={sortObj?.sortColumn === 'PO'}
                  sortable
                  width="80px"
                  sortDirection={sortObj?.sortColumnDirection.PO}
                >
                  <OrderPOCell />
                </DataTableColumn>
              ),
              <DataTableColumn
                key="territoryNumber"
                label="Territory"
                property="territoryNumber"
                isSorted={sortObj?.sortColumn === 'territoryNumber'}
                sortable
                width="100px"
                className="wrap-content"
                sortDirection={sortObj?.sortColumnDirection?.territoryNumber}
              />,
              <DataTableColumn
                key="assigned"
                property="assignedUserName"
                label="Assign To"
                width="125px"
              >
                <InventoryRequestsAssignDataCell openSnackBar={openSnackBar} refetch={refetch} />
              </DataTableColumn>,
              <DataTableColumn key="assigned" label="" width="80px">
                <ResetCell handleReset={handleReset} />
              </DataTableColumn>,
            ]
          : [
              type === 'Open' ? (
                <DataTableColumn
                  key="eventId"
                  label="Order"
                  property="eventId"
                  isSorted={sortObj?.sortColumn === 'eventId'}
                  sortable
                  width="90px"
                  sortDirection={sortObj?.sortColumnDirection.eventId}
                >
                  <OrderRequestIdCell />
                </DataTableColumn>
              ) : (
                <DataTableColumn
                  key="caseNumber"
                  label="Order"
                  property="caseNumber"
                  isSorted={sortObj?.sortColumn === 'caseNumber'}
                  sortable
                  width="90px"
                  sortDirection={sortObj?.sortColumnDirection.caseNumber}
                >
                  <OrderRequestIdCell type={type} />
                </DataTableColumn>
              ),
              <DataTableColumn
                key="parentEventId"
                label="Event"
                property="parentEventId"
                width="85px"
                isSorted={sortObj?.sortColumn === 'parentEventId'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.parentEventId}
              >
                <EventCell type={type} />
              </DataTableColumn>,
              <DataTableColumn
                key="diplayOrderType"
                label="Usage type"
                property="diplayOrderType"
                width="90px"
                isSorted={sortObj?.sortColumn === 'diplayOrderType'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.diplayOrderType}
              />,
              type !== 'Open' && (
                <DataTableColumn
                  key="type"
                  label="Order type"
                  property="type"
                  width="85px"
                  isSorted={sortObj?.sortColumn === 'type'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.type}
                >
                  <OrderType />
                </DataTableColumn>
              ),
              type === 'Open' ? (
                <DataTableColumn
                  key="eventStatus"
                  label="Status"
                  property="eventStatus"
                  width="120px"
                  isSorted={sortObj?.sortColumn === 'eventStatus'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.eventStatus}
                >
                  <OrderRequestStatusCell />
                </DataTableColumn>
              ) : (
                <DataTableColumn
                  key="status"
                  label="Status"
                  property="status"
                  width="120px"
                  isSorted={sortObj?.sortColumn === 'status'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.status}
                >
                  <OrderStatusCell />
                </DataTableColumn>
              ),
              type === 'Processed' && (
                <DataTableColumn
                  key="erpOrderNumber"
                  label="ERP Order"
                  property="erpOrderNumber"
                  width="85px"
                  isSorted={sortObj?.sortColumn === 'erpOrderNumber'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.erpOrderNumber}
                >
                  <OrderIdCell type={type} />
                </DataTableColumn>
              ),
              type === 'Failed' && (
                <DataTableColumn
                  key="failureReason"
                  label="Failure Reason"
                  property="failureReason"
                  width="120px"
                  isSorted={sortObj?.sortColumn === 'failureReason'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.failureReason}
                >
                  <OrderFailureRason />
                </DataTableColumn>
              ),
              <DataTableColumn
                key="account"
                label="Account"
                property="account"
                isSorted={sortObj?.sortColumn === 'account'}
                sortable
                width="150px"
                sortDirection={sortObj?.sortColumnDirection.account}
              >
                <OrderAccountNameCell />
              </DataTableColumn>,
              type !== 'Open'
                ? ((userInfo && userInfo.personas === 'CSR') ||
                    (userInfo && userInfo.personas === 'CSR;Branch Op') ||
                    (userInfo && userInfo.personas === 'Agency OPS;Agency CSR') ||
                    (userInfo && userInfo.personas === 'Agency CSR') ||
                    (userInfo && !!userInfo?.personas?.toLowerCase().includes('csr'))) && (
                    <DataTableColumn
                      key="salesRepName"
                      label="Sales Rep"
                      property="salesRepName"
                      isSorted={sortObj?.sortColumn === 'salesRepName'}
                      sortable
                      width="100px"
                      sortDirection={sortObj?.sortColumnDirection.salesRepName}
                    />
                  )
                : ((userInfo && userInfo.personas === 'CSR') ||
                    (userInfo && userInfo.personas === 'CSR;Branch Op') ||
                    (userInfo && userInfo.personas === 'Agency OPS;Agency CSR') ||
                    (userInfo && userInfo.personas === 'Agency CSR') ||
                    (userInfo && !!userInfo?.personas?.toLowerCase().includes('csr'))) && (
                    <DataTableColumn
                      key="salesRep"
                      label="Sales Rep"
                      property="salesRep"
                      isSorted={sortObj?.sortColumn === 'salesRep'}
                      sortable
                      width="100px"
                      sortDirection={sortObj?.sortColumnDirection.salesRep}
                    />
                  ),
              <DataTableColumn
                key="territoryNumber"
                label="Territory"
                property="territoryNumber"
                isSorted={sortObj?.sortColumn === 'territoryNumber'}
                sortable
                width="100px"
                sortDirection={sortObj?.sortColumnDirection.territoryNumber}
              />,
              getConfiguration('surgeon') && (
                <DataTableColumn
                  key="surgeon"
                  label="Surgeon"
                  property="surgeon"
                  isSorted={sortObj?.sortColumn === 'surgeon'}
                  sortable
                  width="80px"
                  sortDirection={sortObj?.sortColumnDirection.surgeon}
                >
                  <OrderSurgeonNameCell />
                </DataTableColumn>
              ),
              <DataTableColumn
                key="totalAmount"
                label="Amount ($)"
                property="totalAmount"
                isSorted={sortObj?.sortColumn === 'totalAmount'}
                sortable
                width="90px"
                sortDirection={sortObj?.sortColumnDirection.totalAmount}
              >
                <OrderTotalAmountCell />
              </DataTableColumn>,
              getConfiguration('po') && (
                <DataTableColumn
                  key="po"
                  label="PO"
                  property="PO"
                  isSorted={sortObj?.sortColumn === 'PO'}
                  sortable
                  width="80px"
                  sortDirection={sortObj?.sortColumnDirection.PO}
                >
                  <OrderPOCell />
                </DataTableColumn>
              ),
              getConfiguration('procedureName') && (
                <DataTableColumn
                  key="procedureName"
                  label="Procedure"
                  property="procedureName"
                  isSorted={sortObj?.sortColumn === 'procedureName'}
                  sortable
                  width="80px"
                  sortDirection={sortObj?.sortColumnDirection.procedureName}
                >
                  <OrderProcedureCell />
                </DataTableColumn>
              ),
              getConfiguration('eventDateTime') && (
                <DataTableColumn
                  isSorted={sortObj?.sortColumn === 'eventDateTime'}
                  key="eventDateTime"
                  label="Surgery Date"
                  property="eventDateTime"
                  sortable
                  width="90px"
                  sortDirection={sortObj?.sortColumnDirection.eventDateTime}
                >
                  <OrderSurgeryDateCell />
                </DataTableColumn>
              ),
            ]}
      </DataTable>
      <SnackBar open={open} notification={notification} />
      {orderLengthExceed && (
        <Modal
          className="cancel-creation-modal default-modal_header default-modal_footer"
          isOpen={orderLengthExceed}
          onRequestClose={handleCancelModal}
          ariaHideApp={false}
          footer={[<Button label="Close" variant="brand" onClick={handleCancelModal} key="no" />]}
          heading="Warning"
        >
          <div className="slds-text-align--center">
            <div>
              <h1 className="slds-m-around--large ">More than 5 accounts can not be selected.</h1>
            </div>
          </div>
        </Modal>
      )}
    </IconSettings>
  );
};

export default OrdersDataTable;
