/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode, useState, useCallback } from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Popover from '@salesforce/design-system-react/components/popover';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';

interface Props {
  handleToggleFilter: () => void;
  handleSearch: (event: any, searchItem: any) => void;
  filterPresets?: FilterPreset[];
  resetFilters: () => void;
  onEditPress: (filter: FilterPreset, isEditPress: boolean) => void;
  handleFilterEvents: (filterValues: Filter, filterName: string) => void;
  selectedFilterApiValues: any;
  totalFilterResult: number;
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}
interface Filter {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}
interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  filterName: string;
}

const InventoryQueueHeader: FC<Props> = ({
  handleToggleFilter,
  handleSearch,
  filterPresets,
  resetFilters,
  onEditPress,
  handleFilterEvents,
  selectedFilterApiValues,
  totalFilterResult,
}) => {
  const filtersLength = selectedFilterApiValues ? Object.keys(selectedFilterApiValues).length : 0;
  const [filterPresetOpen, setFilterPresetOpen] = useState(false);

  const handleFilterPresetPopover = useCallback((): void => {
    setFilterPresetOpen(!filterPresetOpen);
  }, [filterPresetOpen]);

  const handleFilterPresetPopoverClose = useCallback((): void => {
    setFilterPresetOpen(false);
  }, []);

  const handleFilterEventItems = (filterValues: Filter, filterName: string) => (): void => {
    setFilterPresetOpen(false);
    handleFilterEvents(filterValues, filterName);
  };

  const handleEditPress = (filter: FilterPreset) => (): void => {
    setFilterPresetOpen(false);
    onEditPress(filter, true);
  };

  const headerActions = (): ReactNode => (
    <>
      <PageHeaderControl>
        <div className="slds-align-middle">
          <div className="slds-col_padded">
            <Input
              iconRight={
                <InputIcon
                  assistiveText={{
                    icon: 'Search',
                  }}
                  name="search"
                  category="utility"
                />
              }
              id="search-case-id"
              placeholder="Search by ID / Zip Code"
              onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
            />
          </div>
        </div>
      </PageHeaderControl>
      <PageHeaderControl className="slds-align-middle">
        <ButtonGroup variant="list" id="button-group-page-header-controls">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleToggleFilter}
          />
          <div className="slds-dropdown-trigger slds-dropdown-trigger_click filter-preset-popover">
            <Popover
              align="bottom"
              isOpen={filterPresetOpen}
              onClose={handleFilterPresetPopoverClose}
              onRequestClose={handleFilterPresetPopoverClose}
              ariaLabelledby=""
              body={
                <div className="filter-preset_dropdown">
                  <ul className="slds-dropdown__list" role="menu" aria-label="Show More">
                    <li className="slds-dropdown__item" role="presentation" key="reset">
                      <Button
                        className="slds-button slds-button_neutral filter-preset-reset-button"
                        onClick={resetFilters}
                      >
                        Reset
                      </Button>
                    </li>
                    {filterPresets &&
                      filterPresets.map((filter: FilterPreset) => {
                        return (
                          <li
                            className="slds-dropdown__item"
                            role="presentation"
                            key={filter.filterExternalId}
                          >
                            <Button
                              className="slds-button slds-button_neutral filter-preset-heading-button"
                              onClick={handleFilterEventItems(
                                filter.filterValues,
                                filter.filterName
                              )}
                            >
                              {filter.filterName}
                            </Button>
                            <Button
                              className="slds-button slds-button_neutral filter-preset-edit-button"
                              onClick={handleEditPress(filter)}
                            >
                              Edit
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              }
              id="popover-walkthrough"
            >
              <Button
                className="filter-drop-trigger"
                iconCategory="utility"
                iconName="filter"
                iconPosition="left"
                label="Filter Presets"
                onClick={handleFilterPresetPopover}
              />
            </Popover>
          </div>
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  return (
    <IconSettings iconPath="/icons">
      <PageHeader
        className="transferdetail_header"
        icon={
          <Icon
            assistiveText={{ label: 'Opportunity' }}
            category="standard"
            name="assignment"
            style={{ backgroundColor: '#FFB500' }}
          />
        }
        label=""
        onRenderActions={headerActions}
        // onRenderControls={headerControls}
        title="Inventory Requests"
        truncate
        variant="object-home"
        info={
          <span key="text">
            {`${totalFilterResult} items ${filtersLength ? `• ${filtersLength} Filters ` : ''}`}
            {filtersLength && (
              <Button key="clear" className="refresh_button" onClick={resetFilters}>
                Clear
              </Button>
            )}
          </span>
        }
      />
    </IconSettings>
  );
};

export default InventoryQueueHeader;
