/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode, useCallback, useState } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
// import Popover from '@salesforce/design-system-react/components/popover';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import { useSelector } from 'react-redux';
import Popover from '@salesforce/design-system-react/components/popover';
import { getEventIcon } from '../../../util/utilityFunctions';
import { getUserInfo } from '../../../store/ducks/userInfo';

interface Props {
  // handleNewReturn: () => void;
  handleSearch: (event: any, val: string) => void;
  handleReturnsFilter: () => void;
  handleNewReturnsActionDropdownSelect: (val: any) => void;
  resetFilters: () => void;
  filterPresets: FilterPreset[];
  onEditPress: (filter: FilterPreset, isEditPress: boolean) => void;
  handleFilterEvents: (filterValues: Filter, filterName: string) => void;
  selectedFilterApiValues: any;
}

interface UserInfo {
  contactId?: string;
  division: string | null;
  divisions: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  personas: string;
  userName: string | null;
  userType: string;
  businessunit: string;
  assignedScreens: any;
}

interface SelectedData {
  id: string;
  label: string;
}
interface Filter {
  search?: string;
  status: SelectedData[];
  threePL: string[];
  submitToDate: Date | string;
  submitFromDate: Date | string;
}
interface FilterPreset {
  filterValues: Filter;
  filterExternalId: string;
  filterName: string;
}

export const getReturnLabels = (userInfo: any): any => {
  const labels: { label: string; value: string }[] = [];

  const requiredForms = userInfo?.assignedScreens;

  if (requiredForms && requiredForms.length > 0) {
    const pickUpReturnForm = requiredForms.find(
      (item: any) => item.screen === 'Pickup Return - Create'
    );

    const standardReturnForm = requiredForms.find(
      (item: any) => item.screen === 'Standard Return - Create'
    );

    if (pickUpReturnForm && pickUpReturnForm?.isRequired) {
      labels.push({ label: 'Pickup Return', value: 'pickupReturn' });
    }

    if (standardReturnForm && standardReturnForm?.isRequired) {
      labels.push({ label: 'Standard Return', value: 'inventoryReturns' });
    }
  }

  return labels;
};

const ReturnHeader: FC<Props> = ({
  handleSearch,
  handleReturnsFilter,
  // handleNewReturn,
  handleNewReturnsActionDropdownSelect,
  resetFilters,
  onEditPress,
  handleFilterEvents,
  filterPresets,
  selectedFilterApiValues,
}) => {
  const filtersLength = selectedFilterApiValues ? Object.keys(selectedFilterApiValues).length : 0;
  const userInfo: UserInfo | null = useSelector(getUserInfo);

  const labels = getReturnLabels(userInfo);

  const [filterPresetOpen, setFilterPresetOpen] = useState(false);

  const handleFilterPresetPopover = useCallback((): void => {
    setFilterPresetOpen(!filterPresetOpen);
  }, [filterPresetOpen]);

  const handleFilterPresetPopoverClose = useCallback((): void => {
    setFilterPresetOpen(false);
  }, []);

  const handleFilterEventItems = (filterValues: Filter, filterName: string) => (): void => {
    setFilterPresetOpen(false);
    handleFilterEvents(filterValues, filterName);
  };

  const handleEditPress = (filter: FilterPreset) => (): void => {
    setFilterPresetOpen(false);
    onEditPress(filter, true);
  };

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      {labels.length > 0 && (
        <ButtonGroup id="button-group-page-header-actions">
          {/* <Button
          iconCategory="utility"
          className="slds-text-color_default"
          iconName="add"
          label="New"
          iconPosition="left"
          onClick={handleNewReturn}
        /> */}
          <Dropdown
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="add"
            iconPosition="left"
            label="New"
            options={labels || []}
            onSelect={(value: string): void => handleNewReturnsActionDropdownSelect(value)}
          />
        </ButtonGroup>
      )}
    </PageHeaderControl>
  );
  const headerControls = (): ReactNode => (
    <>
      <PageHeaderControl className="slds-m-left_large">
        <Input
          iconRight={
            <InputIcon
              assistiveText={{
                icon: 'Search',
              }}
              name="search"
              category="utility"
            />
          }
          id="search-by-product"
          onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
          placeholder="Search by Product"
        />
      </PageHeaderControl>
      <PageHeaderControl className="slds-m-left_large">
        <ButtonGroup variant="list" id="button-group-page-header-controls">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleReturnsFilter}
          />
          <div className="slds-dropdown-trigger slds-dropdown-trigger_click filter-preset-popover">
            <Popover
              align="bottom"
              isOpen={filterPresetOpen}
              onClose={handleFilterPresetPopoverClose}
              onRequestClose={handleFilterPresetPopoverClose}
              ariaLabelledby=""
              body={
                <div className="filter-preset_dropdown">
                  <ul className="slds-dropdown__list" role="menu" aria-label="Show More">
                    <li className="slds-dropdown__item" role="presentation" key="reset">
                      <Button
                        className="slds-button slds-button_neutral filter-preset-reset-button"
                        onClick={resetFilters}
                      >
                        Reset
                      </Button>
                    </li>
                    {filterPresets &&
                      filterPresets.map((filter: FilterPreset) => {
                        return (
                          <li
                            className="slds-dropdown__item"
                            role="presentation"
                            key={filter.filterExternalId}
                          >
                            <Button
                              className="slds-button slds-button_neutral filter-preset-heading-button"
                              onClick={handleFilterEventItems(
                                filter.filterValues,
                                filter.filterName
                              )}
                            >
                              {filter.filterName}
                            </Button>
                            <Button
                              className="slds-button slds-button_neutral filter-preset-edit-button"
                              onClick={handleEditPress(filter)}
                            >
                              Edit
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              }
              id="popover-walkthrough"
            >
              <Button
                className="filter-drop-trigger"
                iconCategory="utility"
                iconName="filter"
                iconPosition="left"
                label="Filter Presets"
                onClick={handleFilterPresetPopover}
              />
            </Popover>
          </div>
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  const iconPath = getEventIcon('Inventory');

  return (
    <div>
      <IconSettings iconPath="/icons">
        <PageHeader
          className="events_header"
          icon={<img src={iconPath} className="logo-image" alt="orders" />}
          label="Inventory"
          onRenderActions={headerActions}
          onRenderControls={headerControls}
          title="Returns"
          truncate
          variant="object-home"
          info={
            <span key="text">
              {`${0} items ${filtersLength ? `• ${filtersLength} Filters ` : ''}`}
              {filtersLength && (
                <Button key="clear" className="refresh_button" onClick={resetFilters}>
                  Clear
                </Button>
              )}
              {/* •
              <Button key="refresh" className="refresh_button" onClick={resetFilters}>
                Refresh
              </Button> */}
            </span>
          }
        />
      </IconSettings>
    </div>
  );
};

export default ReturnHeader;
