/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */

/**
 * Module for showing Inventorys with their status.
 * @module src/components/Inventory/index
 */
import React, { FC, useState, useEffect, ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Tab, Tabs, Paper } from '@material-ui/core';
import InventoryList from './InventoryList';
import Transfer from './Transfer';
import './index.scss';
import InventoryQueue from './InventoryQueue';
import {
  GET_WORKFLOW_PERMISSION,
  GET_WORKFLOW_PERMISSIONS,
} from '../../graphql/workflowPermissions';
import { GET_USER_INFO } from '../../graphql/getUserInfoData';
import { setUserInfo } from '../../store/ducks/userInfo';
import { getUserId } from '../../store/ducks/userId';
import RepOnHold from './RepOnHold';
import { OPS_ACTION_TYPE } from '../../util/constants';
import InventoryRequestList from './InventoryRequestList';
import InventoryReturns from './InventoryReturns';
import InventoryReturnQueue from './InventoryReturnQueue';
import CycleCount from '../CycleCount';
import ReprocessQueue from './InventoryQueue/ReprocessQueue';

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}

interface Filter {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}

interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  filterName?: string;
}

interface InventoryDataVariables {
  variables: {
    offset: number;
    limit: number;
    id: string | null;
    filters?: DynamicValue | null;
  };
}

interface DynamicValue {
  [key: string]: any;
}

interface Filters {
  status?: string[];
  salesReps?: string[];
  coveringReps?: string[];
  account?: string;
  branch?: string[];
  franchise?: string[];
  surgeon?: string;
  procedureDetail?: string[];
  startDate?: Date | string;
  endDate?: Date | string;
  usage?: string;
  eventType?: string[];
  search?: string;
}
interface Props {
  // subInventoryNames: SubInventory[];
  refetch: () => void;
  onrefresh: () => void;
  handlePress: () => void;
  offset: number;
  setOffset: (item: number) => void;
  selectedFilterApiValues: Filters | null;
  history: () => void;
  getInventoryData: (variables: InventoryDataVariables) => void;
  tabValue?: number;
}

const Inventory: FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  // Inventory Sub Tab useState
  const [showInventory, setShowInventory] = useState(true);
  const [showRequests, setShowRequests] = useState(false);
  const [showCycleCount, setShowCycleCount] = useState(false);
  const [showReturns, setShowReturns] = useState(false);
  const [showTransfer, setShowTransfer] = useState(true);
  // const [showCheckin, setShowCheckin] = useState(false);
  const [showReviewRequests, setReviewRequests] = useState(false);
  const [showFailedRequests, setFailedRequests] = useState(false);
  const [showAssignRequests, setAssignRequests] = useState(true);
  const [showReviewReturnRequest, setShowReviewReturnRequest] = useState(false);
  const [showAssignReturnRequest, setShowAssignReturnRequest] = useState(false);
  const [showRepTab, setRepTab] = useState(false);
  const [tabPanel, setTabPanel] = useState<ReactNode>();
  // const [getUserInfo, { data }] = useLazyQuery(GET_USER_INFO);
  useQuery(GET_WORKFLOW_PERMISSIONS);
  const id = useSelector(getUserId);
  const { data: getUserInfo } = useQuery(GET_USER_INFO, {
    fetchPolicy: 'no-cache',
    variables: { id },
  });
  const { data: isOpsUser } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'is_ops_user',
    },
  });
  const { data: enableReviewRequests } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'review_inventory_requests',
    },
  });
  const { data: enableAssignRequests } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'assign_inventory_requests',
    },
  });

  const { data: enableReviewReturns } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'review_inventory_returns',
    },
  });

  const { data: enableAssignReturns } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'assign_inventory_returns',
    },
  });

  const { data: enableRepOnHold } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'enable_rep_onhold',
    },
  });

  const { data: isCsrUser } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'is_csr_user',
    },
  });

  useEffect(() => {
    if (
      getUserInfo?.getUserInfo[0].personas === 'CSR' ||
      getUserInfo?.getUserInfo[0].personas === 'Agency CSR'
    ) {
      // Do not have access to Inventory: Redirect to Home
      history.push('/');
    }
  }, [history, getUserInfo]);

  useEffect(() => {
    try {
      dispatch(setUserInfo(getUserInfo ? getUserInfo.getUserInfo[0] : {}));
    } catch (err) {
      // do nothing
    }
  }, [dispatch, getUserInfo]);

  // const showMyInventory =
  //   getUserInfo?.getUserInfo[0].isSubInventoryOnhold &&
  //   (getUserInfo?.getUserInfo[0].personas === 'Sales Rep' ||
  //     getUserInfo?.getUserInfo[0].personas === 'Sales Associate')
  //     ? false
  //     : true;

  useEffect(() => {
    const { pathname } = location;
    setShowInventory(false);
    setShowRequests(false);
    setShowReturns(false);
    setShowTransfer(false);
    // setShowCheckin(false);
    setShowReviewReturnRequest(false);
    setShowAssignReturnRequest(false);
    setReviewRequests(false);
    setFailedRequests(false);
    setAssignRequests(false);
    setRepTab(false);
    setShowCycleCount(false);

    if (pathname.includes('/Inventory/InventoryList')) {
      setShowInventory(true);
      setTabPanel(<InventoryList />);
    } else if (pathname.includes('/Inventory/Requests')) {
      setShowRequests(true);
      setTabPanel(<InventoryRequestList />);
    } else if (pathname.includes('/Inventory/CycleCount')) {
      setShowCycleCount(true);
      setTabPanel(<CycleCount type="" />);
    } else if (
      [
        '/Inventory/Returns',
        '/inventory/Returns/failed',
        '/inventory/Returns/completed',
        '/inventory/Returns/return',
      ].indexOf(pathname) >= 0
    ) {
      setShowReturns(true);
      setTabPanel(<InventoryReturns />);
    } else if (
      [
        '/Inventory/Transfer',
        '/inventory/Transfer/completed',
        '/inventory/Transfer/sent',
        '/inventory/Transfer/received',
        '/inventory/Transfer/failed',
      ].indexOf(pathname) >= 0
    ) {
      setShowTransfer(true);
      setTabPanel(<Transfer />);
    } else if (pathname.includes('/Inventory/Transfer')) {
      setShowTransfer(true);
      setTabPanel(<Transfer />);
      // } else if (pathname.includes('/Inventory/CheckIn')) {
      //   setShowCheckin(true);
      //   setTabPanel(<div>Check In</div>);
    } else if (pathname.includes('/Inventory/review-return-request')) {
      setShowReviewReturnRequest(true);
      setTabPanel(<InventoryReturnQueue action={OPS_ACTION_TYPE.REVIEW} />);
    } else if (pathname.includes('/Inventory/assigned-return-request')) {
      setShowAssignReturnRequest(true);
      setTabPanel(<InventoryReturnQueue action={OPS_ACTION_TYPE.ASSIGN} />);
    } else if (pathname.includes('/Inventory/Review')) {
      setReviewRequests(true);
      setTabPanel(<InventoryQueue action={OPS_ACTION_TYPE.REVIEW} />);
    } else if (pathname.includes('/Inventory/Assign')) {
      setAssignRequests(true);
      setTabPanel(<InventoryQueue action={OPS_ACTION_TYPE.ASSIGN} />);
    } else if (pathname.includes('/Inventory/Rep')) {
      setRepTab(true);
      setTabPanel(<RepOnHold />);
    } else if (pathname.includes('/Inventory/FailedRequests')) {
      setFailedRequests(true);
      setTabPanel(<ReprocessQueue action={OPS_ACTION_TYPE.ASSIGN} />);
    } else {
      setShowInventory(true);
      setTabPanel(<InventoryList />);
    }
  }, [location]);

  const handleTabChange = (event: any): void => {
    const tabName = event.target.innerText;
    switch (tabName) {
      case 'Inventory':
        history.push('/Inventory/InventoryList');
        return;
      case 'Requests':
        history.push('/Inventory/Requests');
        return;
      case 'Returns':
        history.push('/Inventory/Returns');
        return;
      case 'Transfer':
        history.push('/Inventory/Transfer');
        return;
      case 'Cycle Count':
        history.push('/Inventory/CycleCount');
        return;
      case 'Check In':
        history.push('/Inventory/CheckIn');
        return;
      case 'Review Return Request':
        history.push('/Inventory/review-return-request');
        return;
      case 'Assign Return Request':
        history.push('/Inventory/assigned-return-request');
        return;
      case 'Review Requests':
        history.push('/Inventory/Review');
        return;
      case 'Assign Requests':
        history.push('/Inventory/Assign');
        return;
      case 'Reprocess Queue':
        history.push('/Inventory/FailedRequests');
        return;
      case 'User Holds':
        history.push('/Inventory/Rep');
        return;
      default:
        history.push('/Inventory/InventoryList');
    }
  };

  return (
    <div>
      <Paper className="paper" square>
        <Tabs
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          // value={value}
          onChange={handleTabChange}
          aria-label="events_view_selection"
        >
          <Tab
            style={{ minWidth: '120px' }}
            className={showInventory ? 'active' : 'inactive'}
            label="Inventory"
          />
          <Tab
            style={{ minWidth: '120px' }}
            className={showRequests ? 'active' : 'inactive'}
            label="Requests"
          />
          <Tab
            style={{ minWidth: '120px' }}
            className={showReturns ? 'active' : 'inactive'}
            label="Returns"
          />
          {getUserInfo && getUserInfo?.getUserInfo[0].personas !== '3PL Ops' && (
            <Tab
              style={{ minWidth: '120px' }}
              className={showTransfer ? 'active' : 'inactive'}
              label="Transfer"
            />
          )}
          {getUserInfo &&
            (getUserInfo?.getUserInfo[0]?.personas === 'Sales Associate' ||
              getUserInfo?.getUserInfo[0]?.personas === 'Sales Manager' ||
              getUserInfo?.getUserInfo[0]?.personas === 'Sales Rep') && (
              <Tab
                style={{ minWidth: '120px' }}
                className={showCycleCount ? 'active' : 'inactive'}
                label="Cycle Count"
              />
            )}
          {/* {!isOpsUser && <Tab className={showCheckin ? 'active' : 'inactive'} label="Check In" />} */}
          {/* {enableReviewReturns && (
            <Tab
              style={{ minWidth: '120px' }}
              className={showReviewReturnRequest ? 'active' : 'inactive'}
              label="Review Return Request"
            />
          )}
          {enableAssignReturns && (
            <Tab
              style={{ minWidth: '120px' }}
              className={showAssignReturnRequest ? 'active' : 'inactive'}
              label="Assign Return Request"
            />
          )} */}
          {enableReviewRequests && (
            <Tab
              style={{ minWidth: '120px' }}
              className={showReviewRequests ? 'active' : 'inactive'}
              label="Review Requests"
            />
          )}
          {enableReviewRequests && (
            <Tab
              style={{ minWidth: '120px' }}
              className={showFailedRequests ? 'active' : 'inactive'}
              label="Reprocess Queue"
            />
          )}
          {/* {enableAssignRequests && (
            <Tab
              style={{ minWidth: '120px' }}
              className={showAssignRequests ? 'active' : 'inactive'}
              label="Assign Requests"
            />
          )} */}
          {enableRepOnHold && (
            <Tab
              style={{ minWidth: '120px' }}
              className={showRepTab ? 'active' : 'inactive'}
              label="User Holds"
            />
          )}
        </Tabs>
      </Paper>
      <div
        className={`${
          !showCycleCount ? 'slds-p-horizontal_large slds-p-vertical_x-large events_list' : ''
        }`}
      >
        {tabPanel}
      </div>
    </div>
  );
};
export default withRouter(Inventory);
