/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
//* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line react/jsx-wrap-multilines
import React, { FC, useState } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import '../../index.scss';

interface Props {
  open: boolean;
  showPrice: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedCases: any;
  handleClosePdfModal: () => void;
  handleEmail: () => void;
}

const SurgerySheetMultiplePdf: FC<Props> = ({
  open,
  showPrice = true,
  selectedCases,
  handleClosePdfModal,
  handleEmail,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleTabSelect = (value: number): void => {
    setSelectedTabIndex(value);
  };
  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="surgery-sheet-pdf-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={open}
        onRequestClose={handleClosePdfModal}
        footer={[
          <Button label="Close" key="Close" onClick={handleClosePdfModal} />,
          <Button
            className="yellow-btn"
            label="Email"
            onClick={handleEmail}
            variant="brand"
            key="save"
          />,
        ]}
        heading={[
          <div>
            <div style={{ textAlign: 'center' }}>
              <p>Usage Sheet</p>
            </div>
            <p style={{ padding: '5px 15px', color: '#ff0000', fontSize: '0.7em' }}>
              The information on the PDF may be up to five minutes out of date. Please verify that
              the information on this PDF is current before sending it to customers.
            </p>
          </div>,
        ]}
      >
        <Tabs
          variant="scoped"
          id="event-tabs-scoped"
          onSelect={handleTabSelect}
          defaultSelectedIndex={0}
          selectedIndex={selectedTabIndex}
        >
          {selectedCases &&
            selectedCases?.allCases?.map((item: any) => {
              return (
                <TabsPanel label={item.caseId || item.accountId}>
                  <iframe
                    title="Usage Sheet"
                    id="serviceFrameSend"
                    src={item.attachmentid}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                  ></iframe>
                </TabsPanel>
              );
            })}
        </Tabs>
      </Modal>
    </IconSettings>
  );
};

export default SurgerySheetMultiplePdf;
