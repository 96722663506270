/*
 * Get Procedure Details GraphQL specification
 * @module src/graphql/getProcedureTypes
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_PRODUCT_LIST = gql`
  query getProductList(
    $searchText: String
    $caseExternalId: String
    $caseId: String
    $salesRepId: String
    $filters: ProductListFilters
  ) {
    getProductList(
      searchText: $searchText
      caseExternalId: $caseExternalId
      caseId: $caseId
      salesRepId: $salesRepId
      filters: $filters
    ) {
      isFrieghtMisc
      productId
      productNumber
      productdescription
      priceBookEntryId
      unitPrice
      productCost
      salesUnitOfMeasure
      packContent
      floorPrice
      listPrice
      targetprice
      baseorCapital
      productFamily
      productGroup
      productOnHold
      productOnHoldType
      actualQty
      dispositionQty
      availableQuantity
      resolvedPrice {
        contractName
        priceType
        price
        startDate
        endDate
      }
    }
  }
`;
