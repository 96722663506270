/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';

interface Quantity {
  quantity: string;
  availablequantity: string;
  costVariance: string;
  createdByDetails: any[];
  id: string;
  isOpen: boolean;
  needToDisplay: boolean;
}
interface Props {
  item?: Quantity;
}

const CostDeltaCell: FC<Props> = ({
  item = {
    quantity: '',
    availablequantity: '',
    costVariance: '',
    createdByDetails: [],
    id: '',
    isOpen: false,
    needToDisplay: false,
  },
}) => {
  const { costVariance, createdByDetails, isOpen, needToDisplay } = item;
  const updatedCost =
    // eslint-disable-next-line eqeqeq
    costVariance === null || costVariance == '0' || costVariance === undefined
      ? '$0.00'
      : costVariance;

  return needToDisplay ? (
    <DataTableCell title={Math.abs(parseFloat(costVariance)).toFixed(2)} className="right-align">
      {<span>{updatedCost}</span>}
      {isOpen &&
        createdByDetails?.map((lineItem: any) => (
          <div className="border-line">
            <DataTableCell>{lineItem.createdByName}</DataTableCell>
          </div>
        ))}
    </DataTableCell>
  ) : null;
};

/** Custom Rep/Covering cell */
export default CostDeltaCell;
