/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/**
 * Module for header on Events pgage
 * @module src/Events/EventsHeader
 */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode, useState, useCallback, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Popover from '@salesforce/design-system-react/components/popover';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import ClickOutside from './clickOutside';
import { eventFormsMapping, EVENTS_LIST, demoFormsMapping } from '../../../util/constants';
import CreateEditSurgeryEvent from '../../CreateEditSurgeryEvent';
import './index.scss';
import PrivacyRequest from '../../App/PrivacyRequest';
import RequestSubmitted from '../../App/PrivacyRequest/RequestSubmitted';
import UPDATE_PRIVACY_REQUEST from '../../../graphql/mutations/updatePrivacyRequestInfo';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { getUserId } from '../../../store/ducks/userId';
import InventoryRequest from '../../Inventory/InventoryRequest';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';
import { getDateDifferenceInDays, getHoldIcon } from '../../../util/utilityFunctions';

interface WorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}
interface SelectedData {
  id: string;
  label: string;
}
interface Filter {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}

interface ApiFilters {
  status?: string[];
  salesReps?: string[];
  coveringReps?: string[];
  account?: string;
  branch?: string[];
  franchise?: string[];
  surgeon?: string;
  procedureDetail?: string[];
  startDate?: Date | string;
  endDate?: Date | string;
  usage?: string;
  eventType?: string[];
  search?: string;
}

interface FilterPreset {
  filterValues: Filter;
  filterExternalId: string;
  filterName: string;
}

interface Props {
  eventCount: number;
  exposureCount: number;
  isShowCalendar: boolean;
  isShowEventExposure?: boolean;
  showSurgeonData: WorkflowPermission;
  refetchEventItems: () => void;
  handleToggleFilterEvent: () => void;
  filterPresets: FilterPreset[];
  onEditPress: (filter: FilterPreset, isEditPress: boolean) => void;
  handleFilterEvents: (filterValues: Filter, filterName: string) => void;
  resetFilters: () => void;
  selectedFilterApiValues: ApiFilters | null;
  handleRefreshEventList: () => void;
  handleRefreshExposureList: () => void;
  handleExport: () => void;
  handleSearch: (event: any, searchItem: any) => void;
  handleSelect: (data: any, name: any) => void;
  selectedTerritory: any;
  territoryOptions: any;
}

interface DropdownSelect {
  label: string;
  value: string;
  subType?: string;
}

interface UserInfo {
  contactId?: string;
  division: string | null;
  divisions: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  personas: string;
  userName: string | null;
  userType: string;
  businessunit: string;
  assignedScreens: any;
}

const EventsHeader: FC<Props> = ({
  eventCount = 0,
  exposureCount = 0,
  showSurgeonData = null,
  refetchEventItems,
  handleToggleFilterEvent,
  filterPresets,
  onEditPress,
  handleFilterEvents,
  resetFilters,
  selectedFilterApiValues,
  handleRefreshEventList,
  handleRefreshExposureList,
  handleSearch,
  isShowEventExposure,
  handleExport,
  handleSelect,
  selectedTerritory,
  territoryOptions,
}) => {
  const filtersLength = selectedFilterApiValues ? Object.keys(selectedFilterApiValues).length : 0;
  const userInfo: UserInfo | null = useSelector(getUserInfo);
  const userContactId = (userInfo && userInfo.contactId) || null;
  const businessUnit = userInfo?.businessunit || 'Sports Med';
  const userId = useSelector(getUserId);
  const [isOpen, setIsOpen] = useState(false);
  const [isDemoOpen, setIsDemoOpen] = useState(false);
  const [isInventoryOpen, seIisInventoryOpen] = useState(false);
  const [flowType, setFlowType] = useState(false);
  const [eventExternalId, setEventExternalId] = useState('');
  const [filterPresetOpen, setFilterPresetOpen] = useState(false);
  const [eventType, setSelectedEventType] = useState('');
  const [eventSubType, setSelectedEventSubType] = useState('');
  const [eventLabel, setSelectedEventLabel] = useState('');
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const [listState, setListState] = useState(false);
  const [eventDetails, setEventDetails] = useState('');
  const [selectedSalesRep, setSelectedSalesRep] = useState('');
  const [otherAddress, setOtherAddress] = useState('');
  const [locationDesc, setLocationDesc] = useState('');
  const [eventForms, setEventForms] = useState<any>([]);
  const [demoEvents, setDemoEventCount] = useState(0);
  const [eventCounts, setEventCount] = useState(0);

  const requiredForms = userInfo?.assignedScreens;
  const [updatePrivacyRequestInfo, { loading: updateRequestLoading }] = useMutation(
    UPDATE_PRIVACY_REQUEST
  );

  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS);

  const callEventDetail = (
    externalId: string,
    flow: any,
    eventDet: any,
    otherAdd: any,
    locDesc: any,
    selectedSalesRepData: any
  ): void => {
    setFlowType(true);
    setEventExternalId(externalId);
    setEventDetails(eventDet);
    setOtherAddress(otherAdd);
    setLocationDesc(locDesc);
    setSelectedSalesRep(selectedSalesRepData);
  };
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  const handleModalToggle = useCallback(
    (isSave?: boolean): void => {
      if (isSave === false) {
        seIisInventoryOpen(true);
      }
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  const handleEventInventoryModalToggle = (): void => {
    seIisInventoryOpen(false);
  };

  const [canceModalVisible, setCanceModalVisible] = useState(false);

  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
    setEventDetails('');
    setOtherAddress('');
    setLocationDesc('');
    setSelectedSalesRep('');
  };
  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    handleEventInventoryModalToggle();
  };
  const holdIconPath: any = getHoldIcon();
  const handleSelectDropdownItem = useCallback(
    (item: string, subType?: string, label?: string): void => {
      setSelectedEventType(item);
      handleModalToggle();
      setSelectedEventSubType(subType || '');
      setSelectedEventLabel(label || '');
    },
    [businessUnit, handleModalToggle]
  );

  const handleEditPress = (filter: FilterPreset) => (): void => {
    setFilterPresetOpen(false);
    onEditPress(filter, true);
  };

  const handleFilterEventItems = (filterValues: Filter, filterName: string) => (): void => {
    setFilterPresetOpen(false);
    handleFilterEvents(filterValues, filterName);
  };

  const handleFilterPresetPopover = useCallback((): void => {
    setFilterPresetOpen(!filterPresetOpen);
  }, [filterPresetOpen]);

  const handleFilterPresetPopoverClose = useCallback((): void => {
    setFilterPresetOpen(false);
  }, []);

  const handlePrivacyRequestModal = useCallback((): void => {
    setPrivacyModalVisible(!privacyModalVisible);
  }, [privacyModalVisible]);

  const handleRequestSubmittedModal = useCallback((): void => {
    setRequestModalVisible(!requestModalVisible);
  }, [requestModalVisible]);

  const handlePrivacyRequest = useCallback(
    (privacy: boolean, forgotten: boolean): void => {
      updatePrivacyRequestInfo({
        variables: {
          id: userContactId,
          rightToBeForgotten: forgotten,
          dataAccess: privacy,
        },
      }).then(response => {
        const privacyRequestResponse =
          response.data &&
          response.data.updatePrivacyRequestInfo &&
          response.data.updatePrivacyRequestInfo.message === 'success';
        if (privacyRequestResponse) {
          handlePrivacyRequestModal();
          handleRequestSubmittedModal();
        }
      });
    },
    [
      handlePrivacyRequestModal,
      handleRequestSubmittedModal,
      updatePrivacyRequestInfo,
      userContactId,
    ]
  );

  const handleList = (): void => {
    if (!listState) {
      setIsDemoOpen(false);
    }
    setListState(!listState);
  };

  const headerActionsEventExposure = (): ReactNode => (
    <>
      {
        <PageHeaderControl>
          <Combobox
            id="territory"
            events={{
              onSelect: (event: any, el: any): void => handleSelect(el, 'territory'),
              onRequestRemoveSelectedOption: (event: any, item: any): void =>
                handleSelect(item, 'territory'),
            }}
            multiple
            placeholder="Select a territory"
            options={territoryOptions || []}
            selection={selectedTerritory || []}
            disabled={territoryOptions && territoryOptions.length <= 1}
            variant="readonly"
          />
        </PageHeaderControl>
      }
      <PageHeaderControl>
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            // iconName="add"
            label="Excel Export"
            iconPosition="left"
            onClick={handleExport}
          />
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  useEffect(() => {
    // setting list of eligible events and demo events
    const labels =
      // eslint-disable-next-line consistent-return
      requiredForms?.map((rec: any) => {
        if (Object.keys(eventFormsMapping).includes(rec.screen) && rec.isRequired) {
          return eventFormsMapping[rec.screen];
        }
      }) || [];

    const demoForms =
      requiredForms?.map((rec: any) => {
        if (Object.keys(demoFormsMapping).includes(rec.screen) && rec.isRequired) {
          return demoFormsMapping[rec.screen];
        }
      }) || [];

    if (labels?.length > 0) {
      const labelList = labels?.filter((label: any) => {
        return label !== undefined;
      });
      if (labelList?.length > 0) {
        let BUconfiguredForm: { label: string; value: string }[] = [];
        BUconfiguredForm = EVENTS_LIST.filter((items: any) => {
          return !labels.includes(items.label);
        });
        setEventForms(BUconfiguredForm);
        setEventCount(BUconfiguredForm?.length);
      }
    }

    if (demoForms?.length > 0) {
      const demoList = demoForms?.filter((label: any) => {
        return label !== undefined;
      });
      if (demoList && demoList?.length > 0) {
        setDemoEventCount(demoList?.length);
      } else {
        setDemoEventCount(0);
      }
    }
  }, [requiredForms]);

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      {showSurgeonData && (
        <Button
          className="slds-text-color_default"
          label="Privacy Request"
          onClick={handlePrivacyRequestModal}
        />
      )}
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        {userInfo && eventForms?.length > 0 && eventCounts > 0 && (
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="add"
            label="New"
            iconPosition="left"
            onClick={handleList}
          />
        )}
        {listState ? (
          <ClickOutside onClick={handleList}>
            <div
              id="er_list"
              className="absolute-positioned slds-dropdown slds-dropdown_x-small slds-text-align_left ignore-react-onclickoutside slds-dropdown_top slds-dropdown_right"
              style={{
                outline: '0px',
                position: 'absolute',
                fontSize: '12px',
                marginRight: '40px',
                marginTop: '34px',
                padding: '10px',
                maxWidth: '100px',
              }}
            >
              <ul className="slds-dropdown_length-10">
                {EVENTS_LIST.map((event: any) => {
                  return (
                    requiredForms?.find(
                      (rec: any) =>
                        rec.screen === `${event?.label} - Create` &&
                        rec.isRequired &&
                        !Object.keys(demoFormsMapping).includes(rec.screen)
                    ) && (
                      <li
                        className="slds-dropdown__item"
                        style={{ cursor: 'pointer', fontSize: '12px', paddingBottom: '10px' }}
                        onClick={(): void =>
                          handleSelectDropdownItem(`${event?.value}`, '', `${event?.value}`)
                        }
                      >
                        {`${event?.label}`}
                      </li>
                    )
                  );
                })}
                {demoEvents === 1 &&
                  EVENTS_LIST.map((event: any) => {
                    return (
                      requiredForms?.find(
                        (rec: any) =>
                          rec.screen === `${event?.label} - Create` &&
                          rec.isRequired &&
                          Object.keys(demoFormsMapping).includes(rec.screen)
                      ) && (
                        <li
                          className="slds-dropdown__item"
                          style={{ cursor: 'pointer', fontSize: '12px', paddingBottom: '10px' }}
                          onClick={(): void =>
                            handleSelectDropdownItem(`${event?.value}`, '', `${event?.value}`)
                          }
                        >
                          {`${event?.label}`}
                        </li>
                      )
                    );
                  })}
                {demoEvents > 1 && (
                  <li
                    className="slds-dropdown__item"
                    style={{ cursor: 'pointer', fontSize: '12px', paddingBottom: '10px' }}
                  >
                    Demo
                  </li>
                )}
                {demoEvents > 1 &&
                  EVENTS_LIST.map((event: any) => {
                    return (
                      requiredForms?.find(
                        (rec: any) =>
                          rec.screen === `${event?.label} - Create` &&
                          rec.isRequired &&
                          Object.keys(demoFormsMapping).includes(rec.screen)
                      ) && (
                        <li
                          className="slds-dropdown__item"
                          style={{
                            cursor: 'pointer',
                            fontSize: '12px',
                            paddingBottom: '10px',
                            marginLeft: '12px',
                          }}
                          onClick={(): void =>
                            handleSelectDropdownItem(`${event?.value}`, '', `${event?.value}`)
                          }
                        >
                          {`${event?.label}`}
                        </li>
                      )
                    );
                  })}
              </ul>
            </div>
          </ClickOutside>
        ) : (
          <div>{}</div>
        )}
      </ButtonGroup>
    </PageHeaderControl>
  );

  const headerControlsEventExposure = (): ReactNode => (
    <>
      <PageHeaderControl>
        <div className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
          <div className="slds-col_padded">
            <Input
              iconRight={
                <InputIcon
                  assistiveText={{
                    icon: 'Search',
                  }}
                  name="search"
                  category="utility"
                />
              }
              id="search-case-id"
              placeholder="Search by Event ID"
              onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
            />
          </div>
        </div>
      </PageHeaderControl>
      <PageHeaderControl className="slds-m-left_large">
        <ButtonGroup variant="list" id="button-group-page-header-controls">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleToggleFilterEvent}
          />
          <div className="slds-dropdown-trigger slds-dropdown-trigger_click filter-preset-popover">
            <Popover
              align="bottom"
              isOpen={filterPresetOpen}
              onClose={handleFilterPresetPopoverClose}
              onRequestClose={handleFilterPresetPopoverClose}
              ariaLabelledby=""
              body={
                <div className="filter-preset_dropdown">
                  <ul className="slds-dropdown__list" role="menu" aria-label="Show More">
                    <li className="slds-dropdown__item" role="presentation" key="reset">
                      <Button
                        className="slds-button slds-button_neutral filter-preset-reset-button"
                        onClick={resetFilters}
                      >
                        Reset
                      </Button>
                    </li>
                    {filterPresets &&
                      filterPresets.map((filter: FilterPreset) => {
                        return (
                          <li
                            className="slds-dropdown__item"
                            role="presentation"
                            key={filter.filterExternalId}
                          >
                            <Button
                              className="slds-button slds-button_neutral filter-preset-heading-button"
                              onClick={handleFilterEventItems(
                                filter.filterValues,
                                filter.filterName
                              )}
                            >
                              {filter.filterName}
                            </Button>
                            <Button
                              className="slds-button slds-button_neutral filter-preset-edit-button"
                              onClick={handleEditPress(filter)}
                            >
                              Edit
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              }
              id="popover-walkthrough"
            >
              <Button
                className="filter-drop-trigger"
                iconCategory="utility"
                iconName="filter"
                iconPosition="left"
                label="Filter Presets"
                onClick={handleFilterPresetPopover}
              />
            </Popover>
          </div>
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  const headerControls = (): ReactNode => (
    <>
      <PageHeaderControl>
        <div className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
          <div className="slds-col_padded">
            <Input
              iconRight={
                <InputIcon
                  assistiveText={{
                    icon: 'Search',
                  }}
                  name="search"
                  category="utility"
                />
              }
              id="search-case-id"
              placeholder="Search by Event ID"
              onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
            />
          </div>
        </div>
      </PageHeaderControl>
      <PageHeaderControl className="slds-m-left_large">
        <ButtonGroup variant="list" id="button-group-page-header-controls">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleToggleFilterEvent}
          />
          <div className="slds-dropdown-trigger slds-dropdown-trigger_click filter-preset-popover">
            <Popover
              align="bottom"
              isOpen={filterPresetOpen}
              onClose={handleFilterPresetPopoverClose}
              onRequestClose={handleFilterPresetPopoverClose}
              ariaLabelledby=""
              body={
                <div className="filter-preset_dropdown">
                  <ul className="slds-dropdown__list" role="menu" aria-label="Show More">
                    <li className="slds-dropdown__item" role="presentation" key="reset">
                      <Button
                        className="slds-button slds-button_neutral filter-preset-reset-button"
                        onClick={resetFilters}
                      >
                        Reset
                      </Button>
                    </li>
                    {filterPresets &&
                      filterPresets.map((filter: FilterPreset) => {
                        return (
                          <li
                            className="slds-dropdown__item"
                            role="presentation"
                            key={filter.filterExternalId}
                          >
                            <Button
                              className="slds-button slds-button_neutral filter-preset-heading-button"
                              onClick={handleFilterEventItems(
                                filter.filterValues,
                                filter.filterName
                              )}
                            >
                              {filter.filterName}
                            </Button>
                            <Button
                              className="slds-button slds-button_neutral filter-preset-edit-button"
                              onClick={handleEditPress(filter)}
                            >
                              Edit
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              }
              id="popover-walkthrough"
            >
              <Button
                className="filter-drop-trigger"
                iconCategory="utility"
                iconName="filter"
                iconPosition="left"
                label="Filter Presets"
                onClick={handleFilterPresetPopover}
              />
            </Popover>
          </div>
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  return (
    <IconSettings iconPath="/icons">
      <PageHeader
        className="events_header"
        icon={
          <Icon
            assistiveText={{ label: 'Opportunity' }}
            category="standard"
            name="event"
            style={{ backgroundColor: '#FFB500' }}
          />
        }
        info={[
          <span key="text">
            {`${isShowEventExposure ? exposureCount : eventCount} items ${
              filtersLength > 0 ? `• ${filtersLength} Filters ` : ''
            }`}
            {filtersLength > 0 && (
              <Button key="clear" className="refresh_button" onClick={resetFilters}>
                Clear
              </Button>
            )}
            •
            <Button
              key="refresh"
              className="refresh_button"
              onClick={isShowEventExposure ? handleRefreshExposureList : handleRefreshEventList}
            >
              Refresh
            </Button>
          </span>,
        ]}
        label={isShowEventExposure ? 'Events' : ''}
        onRenderActions={isShowEventExposure ? headerActionsEventExposure : headerActions}
        onRenderControls={isShowEventExposure ? headerControlsEventExposure : headerControls}
        title={
          <div className="notification_header">
            <span className="slds-col event_id">
              {isShowEventExposure ? 'Event Inventory Exposure' : 'Events'}
            </span>
          </div>
        }
        truncate
        variant="object-home"
      />
      {isShowEventExposure && (
        <div style={{ padding: '10px', float: 'right' }}>
          {/* <div className="inventory-list" style={{ display: 'inline-block' }}>
          Expiration Date Legend: 
        </div> */}
          <div style={{ display: 'inline-block' }}>
            <div style={{ marginLeft: '30px', display: 'inline-block' }}>
              <span
                style={{
                  display: 'inline-block',
                  width: '18px',
                  height: '18px',
                  border: '1px solid #58b837',
                  borderRadius: '50%',
                  backgroundColor: '#58b837',
                  marginRight: '10px',
                  marginBottom: '-5px',
                }}
              />
              <span
                style={{
                  display: 'inline-block',
                }}
              >
                {'< 60 days'}
              </span>
            </div>
            <div style={{ marginLeft: '10px', display: 'inline-block' }}>
              <span
                style={{
                  display: 'inline-block',
                  width: '18px',
                  height: '18px',
                  borderRadius: '50%',
                  border: '1px solid #1b5687',
                  backgroundColor: '#1b5687',
                  marginRight: '10px',
                  marginBottom: '-5px',
                }}
              />
              <span
                style={{
                  display: 'inline-block',
                }}
              >
                {'>= 60 days'}
              </span>
            </div>
            <div style={{ marginLeft: '10px', display: 'inline-block' }}>
              <span
                style={{
                  display: 'inline-block',
                  width: '18px',
                  height: '18px',
                  borderRadius: '50%',
                  border: '1px solid #ffb503',
                  backgroundColor: '#ffb503',
                  marginRight: '10px',
                  marginBottom: '-5px',
                }}
              />
              <span
                style={{
                  display: 'inline-block',
                }}
              >
                {'>=75 days'}
              </span>
            </div>
            <div style={{ marginLeft: '10px', display: 'inline-block' }}>
              <span
                style={{
                  display: 'inline-block',
                  width: '18px',
                  height: '18px',
                  borderRadius: '50%',
                  border: '1px solid #b83c27',
                  backgroundColor: '#b83c27',
                  marginRight: '10px',
                  marginBottom: '-5px',
                }}
              />
              <span
                style={{
                  display: 'inline-block',
                }}
              >
                {'>=90 days'}
              </span>
              <span style={{ marginLeft: '10px' }}>
                <img
                  src={holdIconPath}
                  className="logo-image"
                  alt="company"
                  style={{ marginLeft: '8px', marginRight: '8px' }}
                />
                On Hold
              </span>
            </div>
          </div>
        </div>
      )}
      {isOpen && !isInventoryOpen && eventType && (
        <CreateEditSurgeryEvent
          toggleOpen={handleModalToggle}
          isOpen={isOpen}
          eventType={eventType}
          eventSubType={eventSubType}
          eventLabel={eventLabel}
          refetchEventItems={refetchEventItems}
          showSurgeonData={showSurgeonData}
          callEventDetail={callEventDetail}
        />
      )}
      {isInventoryOpen && !isOpen && selectedSalesRep && eventDetails && eventType && (
        <InventoryRequest
          flowType
          isOpen={isInventoryOpen}
          toggleOpen={handleEventInventoryModalToggle}
          formData={data || []}
          eventType="Request Inventory"
          eventExternalId={eventExternalId}
          eventDetailInfo={eventDetails}
          otherAddress={otherAddress}
          locationDesc={locationDesc}
          handleCancelButton={handleCancelModal}
          selectedSalesRepData={selectedSalesRep}
        />
      )}
      {canceModalVisible && (
        <CancelEventModal
          isInventory
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
        />
      )}
      {privacyModalVisible && (
        <PrivacyRequest
          privacyModalVisible={privacyModalVisible}
          handlePrivacyRequestModal={handlePrivacyRequestModal}
          handlePrivacyRequest={handlePrivacyRequest}
          updateRequestLoading={updateRequestLoading}
        />
      )}

      <RequestSubmitted
        handleRequestSubmittedModal={handleRequestSubmittedModal}
        requestModalVisible={requestModalVisible}
      />
    </IconSettings>
  );
};

/** Custom Header on Events Page **/
export default EventsHeader;
