/*
 * GraphQL query to get Region and Territory
 * @module src/graphql/getUserRegionAndTerritories.ts
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_USER_REGION_AND_TERRITORIES = gql`
  query getUserRegionAndTerritories($userId: String, $filters: UserRegionAndTerritoriesFilters) {
    getUserRegionAndTerritories(userId: $userId, filters: $filters) {
      userId
      accountId
      territoryId
      territory
      region
      regionId
    }
  }
`;
