/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC, useEffect, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Spinner from '@salesforce/design-system-react/components/spinner';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import CycleCountDetailHeader from './CycleCountDetailHeader';
import NewCycleCount from '../NewCycleCount';
import { GET_CYCLE_COUNT_DETAILS } from '../../../graphql/getCycleCountDetails';
import CANCEL_CYCLE_COUNT from '../../../graphql/mutations/cancleCycleCount';
import CREATE_CYCLE_COUNT from '../../../graphql/mutations/createCycleCount';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import { NOTIFY_TYPE } from '../../../util/constants';
import SnackBar from '../../Shared/SnackBar';
import { GET_CYCLE_COUNT_LINE_ITEM_DETAILS } from '../../../graphql/getCycleCountLineItemDetails';
import CycleCountDetailTable from './CycleCountDetailTable';
import { excelExport, getUSDString } from '../../../util/utilityFunctions';
import { getUserInfo } from '../../../store/ducks/userInfo';

interface Props {
  type: string;
}

// eslint-disable-next-line no-empty-pattern
const CycleCountDetail: FC<RouteComponentProps> = ({ location }) => {
  const { state } = location;
  const { cycleCountExternalId, opsAction } = state;
  const [isEdit, setIsEdit] = useState(false);
  const [isCycleCountStarted, startCycleCount] = useState(false);
  const { open, notification, openSnackBar } = useSnackBar();
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [cycleCountDetails, setcycleCountDetails] = useState<any>(null);
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [createCycleCount, { data: createCycleCountData }] = useMutation(CREATE_CYCLE_COUNT);
  const [cycleCountLineItemsDetails, setcycleCountLineItemsDetails] = useState<any>(null);
  const [cycleCountLineItemsDetailsForExcel, setCycleCountLineItemsDetailsForExcel] = useState<any>(
    null
  );
  let AllDataJson: any;
  let UploadData: any;
  const filtercheck = localStorage?.getItem('CCFilters');
  const userInfo = useSelector(getUserInfo);
  const isOps = userInfo?.personas?.includes('Branch Op') || false;
  const inProgress = cycleCountDetails && cycleCountDetails.status.toLowerCase() === 'in progress';

  const [getCycleCountDetails, { data: cycleCountDetailsData, refetch, loading }] = useLazyQuery(
    GET_CYCLE_COUNT_DETAILS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [
    setCancelCycleCount,
    { data: cancelCycleCount, loading: cancelCycleCountLoading },
  ] = useMutation(CANCEL_CYCLE_COUNT);
  const [
    getCycleCountLineItemsDetails,
    {
      data: cycleCountDetailsLineItemsData,
      refetch: refetchCycleCountDetailsLineItemsData,
      loading: lineItemLoading,
    },
  ] = useLazyQuery(GET_CYCLE_COUNT_LINE_ITEM_DETAILS, {
    fetchPolicy: 'no-cache',
  });
  const [
    getCycleCountLineItemsDetailsForExcel,
    {
      data: cycleCountDetailsLineItemsDataForExcel,
      refetch: refetchCycleCountDetailsLineItemsDataForExcel,
      loading: lineItemLoadingForExcel,
    },
  ] = useLazyQuery(GET_CYCLE_COUNT_LINE_ITEM_DETAILS, {
    fetchPolicy: 'no-cache',
  });

  const validateLotNumber = () => {
    let lotNumberMissing: any = false;

    if (cycleCountDetails && cycleCountDetails.lineItems) {
      cycleCountDetails.lineItems.forEach((item: any) => {
        if (!item.lotNumber) {
          lotNumberMissing = true;
        }
      });
    }
    return lotNumberMissing;
  };

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModalEdit = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModalConfirmPressEdit = (): void => {
    setCanceModalVisible(!canceModalVisible);
    setIsEdit(!isEdit);
  };

  const handleCancelModal = (): void => {
    setShowCancelPopup(!showCancelPopup);
  };

  const updateCycleCountDetails = (eventType: any): void => {
    if (!isOps && inProgress && eventType === 'RepSubmit' && validateLotNumber()) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'Lot number is missing for one or more items');
      return;
    }
    if (eventType === 'RepSubmit') {
      if (cycleCountDetails && cycleCountDetails?.lineItems?.length === 0) {
        return;
      }
    }

    let mutation: any = {};
    mutation = {
      caseId: null,
      caseExternalId: cycleCountDetails.caseExternalId,
      cycleCountExternalId: cycleCountDetails.cycleCountExternalId,
      cycleCountId: null,
      formType: 'Update',
      submitType: eventType,
      inventoryConsignmentHold: true,
      reasonCodeForUnscannedItems: false,
      startDate: cycleCountDetails.startDate,
      endDate: cycleCountDetails.endDate,
    };

    createCycleCount({
      variables: mutation,
    })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data.createCycleCount &&
          response.data.createCycleCount.message === 'success'
        ) {
          setShowCancelPopup(false);

          if (eventType !== 'RepStart') {
            openSnackBar(NOTIFY_TYPE.SUCCESS, 'Cycle count updated successfully');
          }

          if (refetch) {
            refetch();
          }
          refetchCycleCountDetailsLineItemsData();
        }
      })
      .catch(error => {
        const errors = error.graphQLErrors.map((er: any) => er.message);
      });
  };

  useEffect(() => {
    if (cycleCountExternalId) {
      getCycleCountDetails({
        variables: {
          externalId: cycleCountExternalId,
        },
      });
    } else {
      window.history.back();
    }
  }, [getCycleCountDetails, cycleCountExternalId]);

  useEffect(() => {
    if (cycleCountDetailsData && cycleCountDetailsData.getCycleCountDetails) {
      setcycleCountDetails(cycleCountDetailsData.getCycleCountDetails);
    }
  }, [cycleCountDetailsData]);

  const handleModalToggle = useCallback((): void => {
    setIsEdit(!isEdit);
  }, [isEdit]);

  const handleCancelModalConfirmPress = (): void => {
    updateCycleCountDetails('Cancel');
  };
  useEffect(() => {
    if (cycleCountExternalId) {
      getCycleCountLineItemsDetailsForExcel({
        variables: {
          externalId: cycleCountExternalId,
        },
      });
    }
  }, [getCycleCountLineItemsDetailsForExcel, cycleCountExternalId]);

  useEffect(() => {
    if (cycleCountExternalId) {
      getCycleCountLineItemsDetails({
        variables: {
          externalId: cycleCountExternalId,
        },
      });
    }
  }, [getCycleCountLineItemsDetails, cycleCountExternalId]);

  useEffect(() => {
    if (
      cycleCountDetailsLineItemsData &&
      cycleCountDetailsLineItemsData.getCycleCountLineItemDetails
    ) {
      setcycleCountLineItemsDetails(cycleCountDetailsLineItemsData.getCycleCountLineItemDetails);
    }
  }, [cycleCountDetailsLineItemsData, refetchCycleCountDetailsLineItemsData]);

  useEffect(() => {
    if (
      cycleCountDetailsLineItemsDataForExcel &&
      cycleCountDetailsLineItemsDataForExcel.getCycleCountLineItemDetails
    ) {
      setCycleCountLineItemsDetailsForExcel(
        cycleCountDetailsLineItemsDataForExcel.getCycleCountLineItemDetails
      );
    }
  }, [cycleCountDetailsLineItemsDataForExcel, refetchCycleCountDetailsLineItemsDataForExcel]);

  const updatedTotalCost = (cost: any): string => {
    // eslint-disable-next-line eqeqeq
    return cost === null || cost == '0' || cost === undefined ? '$0.00' : cost;
  };
  const lineData =
    cycleCountDetailsLineItemsDataForExcel?.getCycleCountLineItemDetails?.[0] ||
    cycleCountDetailsLineItemsDataForExcel?.getCycleCountLineItemDetails;
  const lineDataForExcel =
    cycleCountDetailsLineItemsDataForExcel?.getCycleCountLineItemDetails?.[0]?.lineItems ||
    cycleCountDetailsLineItemsDataForExcel?.getCycleCountLineItemDetails;
  if (lineDataForExcel?.length > 0) {
    AllDataJson = lineDataForExcel.map((items: any) => {
      if (cycleCountDetails?.status.toLowerCase() === 'completed') {
        return {
          'SAP: Plant': items.plant,
          'SAP: Storage Location': items.storageLocation,
          Customer: items.soldTo,
          Org: cycleCountDetails?.fieldOrg ? cycleCountDetails?.fieldOrg : '',
          Sub: cycleCountDetails?.inventoryLocation ? cycleCountDetails.inventoryLocation : '',
          Sku: items.productNumber,
          Batch:
            items.isLotNumber && items.lotNumber
              ? items.lotNumber
              : items.lotNumber && !items.serialNumber
              ? items.lotNumber
              : null,
          'SAP Unit of Entry': items.unitOfMeasure,
          'Serial Number': items.serialNumber && !items.isLotNumber ? items.serialNumber : null,
          'Movement Type': items.movementType,
          'Stock Type': items.stockType,
          'Special Stock Indicator': items.specialStockIndicator,
          'Expected Qty': items?.availablequantity,
          'Counted Qty': items?.quantity,
          'Delta Qty': parseFloat(items.quantitydifference).toFixed(2),
          'Part Line Comments': JSON.stringify(
            items?.createdByDetails?.map((itemscomments: any) => {
              return itemscomments.comments !== null
                ? itemscomments.comments.map((comment: any) => {
                    return comment.comments === null ? '' : comment.comments;
                  })
                : '';
            })
          ),
          'Header Comments': cycleCountDetails?.comments[0]?.value,
          'Cost Delta': updatedTotalCost(items?.costVariance),
          '': '',
          [`Overage ${updatedTotalCost(lineData?.overagePrice)}`]: '',
          [`Shortage ${updatedTotalCost(lineData?.shortagePrice)}`]: '',
          [`Total Cost Delta ${updatedTotalCost(lineData?.totalPrice)}`]: '',
        };
      }
      return {
        'SAP: Plant': items.plant,
        'SAP: Storage Location': items.storageLocation,
        Customer: items.soldTo,
        Org: cycleCountDetails?.fieldOrg ? cycleCountDetails?.fieldOrg : '',
        Sub: cycleCountDetails?.inventoryLocation ? cycleCountDetails.inventoryLocation : '',
        Sku: items.productNumber,
        Batch:
          items.isLotNumber && items.lotNumber
            ? items.lotNumber
            : items.lotNumber && !items.serialNumber
            ? items.lotNumber
            : null,
        'SAP Unit of Entry': items.unitOfMeasure,
        'Serial Number': items.serialNumber && !items.isLotNumber ? items.serialNumber : null,
        'Movement Type': items.movementType,
        'Stock Type': items.stockType,
        'Special Stock Indicator': items.specialStockIndicator,
        'Expected Qty': items?.availablequantity,
        'Counted Qty': items?.quantity,
        'Delta Qty': parseFloat(items.quantitydifference).toFixed(2),
        'Part Line Comments': JSON.stringify(
          items?.createdByDetails?.map((itemscomments: any) => {
            return itemscomments.comments !== null
              ? itemscomments.comments.map((comment: any) => {
                  return comment.comments === null ? '' : comment.comments;
                })
              : '';
          })
        ),
        'Header Comments': cycleCountDetails?.comments[0]?.value,
      };
    });
  } else {
    AllDataJson = [
      {
        'SAP: Plant': null,
        'SAP: Storage Location': null,
        Customer: null,
        Org: null,
        Sub: null,
        Sku: null,
        Batch: null,
        'SAP Unit of Entry': null,
        'Serial Number': null,
        'Movement Type': null,
        'Stock Type': null,
        'Special Stock Indicator': null,
        'Expected Qty': null,
        'Counted Qty': null,
        'Delta Qty': null,
        'Part Line Comments': [],
        'Header Comments': [],
      },
    ];
  }

  if (cycleCountDetailsLineItemsData?.getCycleCountLineItemDetails?.length > 0) {
    UploadData = cycleCountDetailsLineItemsData?.getCycleCountLineItemDetails.map((items: any) => {
      if (filtercheck === 'true') {
        return {
          'Part Number': items.productNumber,
          'Part Description': items.productdescription,
          'Lot Number': items.lotNumber,
          'EIM Reserved': items.pendingeimtransaction,
          'Counted Qty': items.quantity,
          'Expected Qty': items.availablequantity,
          'Qty Delta': parseFloat(items.quantitydifference).toFixed(2),
          'Cost Delta': getUSDString(items.costVariance.toString()),
        };
      }
      return {
        Org: cycleCountDetails?.fieldOrg ? cycleCountDetails?.fieldOrg : '',
        Sub: cycleCountDetails?.inventoryLocation ? cycleCountDetails.inventoryLocation : '',
        Sku: items.productNumber,
        LotNumber: items.lotNumber,
        Qty: items.quantitydifference,
      };
    });
  } else {
    UploadData = [
      {
        Org: null,
        Sub: null,
        Sku: null,
        LotNumber: null,
        Qty: null,
      },
    ];
  }

  const handleActionDropdownSelect = (option: any): void => {
    if (option.value === 'edit' && !option.disabled) {
      setIsEdit(true);
    } else if (option.value === 'cancel' && !option.disabled) {
      setShowCancelPopup(true);
    } else if (option.value === 'submit' && !option.disabled) {
      updateCycleCountDetails('RepSubmit');
    } else if (option.value === 'audit_complete' && !option.disabled) {
      updateCycleCountDetails('OPSAuditComplete');
    } else if (option.value === 'complete' && !option.disabled) {
      updateCycleCountDetails('OPSComplete');
    } else if (option.value === 'export' && !option.disabled) {
      excelExport(
        [{ title: 'All Data', data: AllDataJson }],
        `Cycle Count ID ${cycleCountDetails?.caseNumber}.xlsx`
      );
    }
  };

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {loading || lineItemLoading || lineItemLoadingForExcel ? (
        <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
      ) : (
        cycleCountDetails && (
          <CycleCountDetailHeader
            cycleCountDetails={cycleCountDetails}
            refetch={() =>
              getCycleCountDetails({
                variables: {
                  externalId: cycleCountExternalId,
                },
              })
            }
            handleActionDropdownSelect={handleActionDropdownSelect}
            opsActionType={opsAction}
          />
        )
      )}
      <CycleCountDetailTable
        refetchCycleCountDetails={() =>
          getCycleCountDetails({
            variables: {
              externalId: cycleCountExternalId,
            },
          })
        }
        refetch={refetchCycleCountDetailsLineItemsData}
        getCycleCountLineItemsDetails={getCycleCountLineItemsDetails}
        getCycleCountLineItemsDetailsForExcel={getCycleCountLineItemsDetailsForExcel}
        cycleCountDetails={cycleCountDetails}
        cycleCountDetailTable={cycleCountLineItemsDetails}
        opsActionType={opsAction}
      />
      {isEdit && (
        <NewCycleCount
          isOpen={isEdit}
          toggleOpen={handleModalToggle}
          refetch={refetch}
          cycleCountDetail={cycleCountDetails}
          eventType="Edit"
          handleCancelButton={handleCancelButton}
        />
      )}
      {showCancelPopup && (
        <CancelEventModal
          isCycleCount
          canceModalVisible={showCancelPopup}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
        />
      )}
      {canceModalVisible && (
        <CancelEventModal
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModalEdit}
          handleCancelModalConfirmPress={handleCancelModalConfirmPressEdit}
          edit
        />
      )}
    </IconSettings>
  );
};

export default withRouter(CycleCountDetail);
