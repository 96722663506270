/**
 * Redux file for getting/setting auth token
 * @module src/store/ducks/RegionTerritoryInfoData
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_USER_REGION_AND_TERRITORY = 'SET_USER_REGION_AND_TERRITORY';
const CLEAR_USER_REGION_AND_TERRITORY = 'CLEAR_USER_REGION_AND_TERRITORY';

interface RegionTerritoryInfo {
  accountId?: string;
  label?: string;
  region?: string;
  regionId?: string;
  territoryId?: string;
  territory?: string;
  userId?: string;
  value?: string;
  __typename?: string;
}

interface SetRegionTerritoryInfoActionType {
  payload: RegionTerritoryInfo | null;
  type: typeof SET_USER_REGION_AND_TERRITORY;
}

interface ClearRegionTerritoryInfoActionType {
  type: typeof CLEAR_USER_REGION_AND_TERRITORY;
}

/** RegionTerritoryInfoData action type */
export type RegionTerritoryActionType =
  | SetRegionTerritoryInfoActionType
  | ClearRegionTerritoryInfoActionType;

/** set RegionTerritoryInfoData action creator */
export const setRegionTerritoryInfo = (
  state: RegionTerritoryInfo | null
): RegionTerritoryActionType => {
  return {
    payload: state,
    type: SET_USER_REGION_AND_TERRITORY,
  };
};

/** clear RegionTerritoryInfoData action creator */
export const clearRegionTerritoryInfo = (): ClearRegionTerritoryInfoActionType => {
  return {
    type: CLEAR_USER_REGION_AND_TERRITORY,
  };
};

/** RegionTerritoryInfoData state type */
export type RegionTerritoryInfoData = RegionTerritoryInfo | null;

/** RegionTerritoryInfoData reducer */
export default (
  state: RegionTerritoryInfoData = null,
  action: ActionType
): RegionTerritoryInfoData => {
  switch (action.type) {
    case SET_USER_REGION_AND_TERRITORY: {
      return action.payload;
    }
    case CLEAR_USER_REGION_AND_TERRITORY: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get RegionTerritoryInfoData */
export const getRegionTerritoryInfo = (state: State): RegionTerritoryInfoData => {
  return state.userRegionTerritoryState;
};
