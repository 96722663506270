/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
// import Icon from '@salesforce/design-system-react/components/icon';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
// import moment from 'moment';
import { sortBy } from 'lodash';
// import EventStatusBadge from '../../Shared/EventStatusBadge';
import InventoryRequestDataTable from '../InventoryRequestDataTable';
import { GET_INVENTORY_REQUEST_LIST } from '../../../graphql/getInventoryRequestList';
import { GET_INVENTORY_REQUEST_FAVORITES } from '../../../graphql/getInventoryRequestFavorites';
import { GET_ORDER_LIST } from '../../../graphql/getOrderList';
import { LIMIT, NEXT_DATA, PREV_DATA, holdTypes } from '../../../util/constants';
import './index.scss';
import { getUserInfo } from '../../../store/ducks/userInfo';
import InventoryReturnFilter from '../InventoryReturns/InventoryReturnFilter';
import debounced from '../../../util/debounced';
import { getUserId } from '../../../store/ducks/userId';
import FilterTags from '../../Shared/FilterTags';
import RequestHeader from './RequestHeader';
import {
  clearInventoryRequestFilters,
  getInventoryRequestFilters,
  setInventoryRequestFilters,
} from '../../../store/ducks/inventoryRequestFilters';
import { setHoldBannerStatus } from '../../../store/ducks/holdBanner';
import { GET_USER_HOLD } from '../../../graphql/getUserHold';
import { GET_FILTER_PRESETS } from '../../../graphql/getFilterPresets';

export interface Item {
  accountId: string;
  accountName: string;
  accountNumber: string;
  branch: string;
  branchId: string;
  caseExternalId: string;
  caseId: string;
  createdDate: string;
  division: string;
  eventId: string;
  eventStatus: string;
  eventType: string;
  needByDate: string;
  parentEventId: string;
  parentEventStatus: string;
  parentEventType: string;
  parentCaseExternalId: string;
  parentCaseId: string;
  salesRep: string;
  salesRepId: string;
  serviceLevel: string;
  shippingAddessLine1: string;
  shippingAddessLine2: string;
  shippingCity: string;
  shippingId: string;
  submittedDate: string;
  shippingPostalcode: string;
  shippingState: string;
  visualIndicator: string;
  showRedWarningIcon: any;
  showBackorderdWarning: any;
}
interface DynamicValue {
  [key: string]: any;
}
interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}
interface Filter {
  search?: string;
  status: SelectedData[];
  threePL: string[];
  submitToDate: Date | string;
  submitFromDate: Date | string;
}
interface Filters {
  search?: string;
  status?: string[];
  threePL?: string[];
  submitToDate?: Date | string;
  submitFromDate?: Date | string;
}
interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  filterName?: string;
}
interface Props extends RouteComponentProps {
  history: any;
}

const InventoryRequestList: FC<Props> = ({ location, history }) => {
  const { pathname } = location;
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();
  const [offsetRequested, setOffsetRequested] = useState(0);
  const [offsetFailed, setOffsetFailed] = useState(0);
  const [offsetProcessed, setOffsetProcessed] = useState(0);
  const [offsetFav, setOffsetFav] = useState(0);
  const [sortColumn, setSortColumn] = useState<any>();
  const [openRequests, setOpenRequests] = useState<any[]>([]);
  const [failedRequests, setFailedRequests] = useState<any[]>([]);
  const [processedRequests, setProcessedRequests] = useState<any[]>([]);
  // const [selectedIndex, setSelectedIndex] = useState();
  const [status, setStatus] = useState('REQUESTED');
  const [openRequestFilter, setOpenRequestFilter] = useState(false);
  const [updatedSelectedFilter, setUpdatedSelectedFilter] = useState<
    FilterPreset | DynamicValue | null
  >(null);
  const [selectedFilter, setSelectedFilter] = useState<FilterPreset | DynamicValue | null>(null);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<Filters | null>(null);
  const [filterEventModalVisible, setFilterEventModalVisible] = useState(false);
  const [isEditFilter, setIsEditFilter] = useState(false);
  const [
    getInventoryRequestListItems,
    { data: inventoryRequestItems, loading },
  ] = useLazyQuery(GET_INVENTORY_REQUEST_LIST, { fetchPolicy: 'network-only' });
  const userInfo = useSelector(getUserInfo);
  const [
    getInventoryRequestListFailedItems,
    { data: inventoryRequestFailedItems, loading: loadingInventoryRequestFailedItems },
  ] = useLazyQuery(GET_ORDER_LIST, { fetchPolicy: 'network-only' });
  const [
    getInventoryRequestListProgressItems,
    { data: inventoryRequestProgressItems, loading: loadingInventoryRequestProgressItems },
  ] = useLazyQuery(GET_ORDER_LIST, { fetchPolicy: 'network-only' });
  const { data: holddata } = useQuery(GET_USER_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: userInfo?.id,
    },
  });
  const { data: filterPresets, refetch: refetchFilterPresets } = useQuery(GET_FILTER_PRESETS, {
    variables: {
      userId: userInfo?.id,
      type: 'Request',
    },
  });
  const filterData = filterPresets?.getFilterPresets;
  useEffect(() => {
    if (holddata?.getUserHoldInfo && holddata?.getUserHoldInfo?.length > 0) {
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Regulatory Noncompliance'] ||
            e === holdTypes[0]['Sunshine Act Hold'] ||
            e === holdTypes[0]['Custom Hold'] ||
            e === holdTypes[0]['Trial Hold']
        )?.length > 0
      ) {
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
      } else {
        dispatch(setHoldBannerStatus(false));
      }
    }
  }, [dispatch, holddata]);
  // const [
  //   getInventoryRequestListCompletedItems,
  //   { data: inventoryRequestCompletedItems, loading: loadingInventoryRequestCompletedItems },
  // ] = useLazyQuery(GET_INVENTORY_REQUEST_LIST, { fetchPolicy: 'network-only' });
  const [
    getInventoryRequestListFavoritesItems,
    { data: inventoryRequestFavoritesItems, loading: loadingInventoryRequestFavoritesItems },
  ] = useLazyQuery(GET_INVENTORY_REQUEST_FAVORITES, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (inventoryRequestItems?.getInventoryRequestList && status === 'REQUESTED') {
      const list = inventoryRequestItems?.getInventoryRequestList;
      setOpenRequests(list);
    } else if (inventoryRequestFailedItems?.getOrderList && status === 'FAILED') {
      const list = inventoryRequestFailedItems?.getOrderList;
      if (list && list.length > 0) {
        list.forEach((element: any) => {
          if (element.status === 'Failed' && element.visualIndicator === 'Failed') {
            // eslint-disable-next-line no-param-reassign
            element.status = 'Approved';
            // eslint-disable-next-line no-param-reassign
            element.visualIndicator = 'Approved';
          }
        });
      }
      setFailedRequests(list);
    } else if (inventoryRequestProgressItems?.getOrderList && status === 'PROCESSED') {
      const list = inventoryRequestProgressItems?.getOrderList;
      setProcessedRequests(list);
    }
  }, [
    inventoryRequestItems,
    inventoryRequestFailedItems,
    inventoryRequestProgressItems,
    getInventoryRequestListItems,
    getInventoryRequestListFailedItems,
    getInventoryRequestListProgressItems,
  ]);

  // const inventoryRequestListData = inventoryRequestItems?.getInventoryRequestList;
  // const inventoryRequestFailedData = inventoryRequestFailedItems?.getOrderList;

  // if (inventoryRequestFailedData && inventoryRequestFailedData.length > 0) {
  //   inventoryRequestFailedData.forEach((element: any) => {
  //     if (element.status === 'Failed' && element.visualIndicator === 'Failed') {
  //       // eslint-disable-next-line no-param-reassign
  //       element.status = 'Approved';
  //       // eslint-disable-next-line no-param-reassign
  //       element.visualIndicator = 'Approved';
  //     }
  //   });
  // }

  // const inventoryRequestProgressData = inventoryRequestProgressItems?.getOrderList;
  // // const inventoryRequestCompletedListData = inventoryRequestCompletedItems?.getInventoryRequestList;
  const inventoryRequestFavoritesListData = sortBy(
    inventoryRequestFavoritesItems?.getInventoryFavorites,
    [
      function sort(o): string {
        return o.nameOfFavorite;
      },
    ]
  );
  const favoritesListData =
    inventoryRequestFavoritesListData &&
    inventoryRequestFavoritesListData.map((element: any) => {
      const obj = { ...element };
      obj.eventId = element.nameOfFavorite;
      obj.accountName = element.account.name;
      obj.salesRepName = element.salesRep.name;
      obj.favComments =
        (obj.comments &&
          obj.comments.length > 0 &&
          obj.comments.map((i: any) => i.comment).join(';')) ||
        '--';
      return obj;
    });

  useEffect(() => {
    let tab = 'REQUESTED';
    if (pathname.toLowerCase().includes('/inventory/requests/failed')) {
      tab = 'FAILED';
    } else if (pathname.toLowerCase().includes('/inventory/requests/processed')) {
      tab = 'PROCESSED';
    } else if (pathname.toLowerCase().includes('/inventory/requests/favorites')) {
      tab = 'FAVORITES';
    }
    setStatus(tab);
    // setOffset(0);
  }, [location]);

  useEffect(() => {
    if (status === 'FAILED') {
      getInventoryRequestListFailedItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          orderType: 'INVENTORY_REQUEST',
          screenType: status,
          ...(selectedFilter && { filters: selectedFilter }),
        },
      });
    } else if (status === 'PROCESSED') {
      getInventoryRequestListProgressItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          orderType: 'INVENTORY_REQUEST',
          screenType: status,
          ...(selectedFilter && { filters: selectedFilter }),
        },
      });
    } else if (status === 'FAVORITES') {
      getInventoryRequestListFavoritesItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          type: status,
        },
      });
    } else {
      getInventoryRequestListItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          type: status,
        },
      });
    }
  }, [
    getInventoryRequestListItems,
    getInventoryRequestListFavoritesItems,
    getInventoryRequestListFailedItems,
    getInventoryRequestListProgressItems,
    status,
    userId,
  ]);

  // const routeToInventoryDetail = (externalId: string): void => {
  //   history.push('/inventoryRequestDetails', {
  //     isEventInventoryFlow: false,
  //     externalId,
  //   });
  // };

  // const routeToInventoryFavDetail = (item: any): void => {
  //   history.push('/inventoryFavoriteDetails', {
  //     inventoryRequestFav: item,
  //   });
  // };
  const handleCompletedTab = (e: any): void => {
    setSelectedFilter(null);
    setUpdatedSelectedFilter(null);
    // setSelectedIndex(e);
    if (e === 0) {
      setStatus('REQUESTED');
      history.push('/Inventory/Requests');
    } else if (e === 1) {
      setStatus('FAILED');
      history.push('/Inventory/Requests/Failed');
    } else if (e === 2) {
      setStatus('PROCESSED');
      history.push('/Inventory/Requests/Processed');
    } else if (e === 3) {
      setStatus('FAVORITES');
      history.push('/Inventory/Requests/Favorites');
    }
  };

  const handleRequestFilter = (): void => {
    setSelectedFilter(updatedSelectedFilter);
    setOpenRequestFilter(!openRequestFilter);
  };

  const handleRequestFilterModal = (): void => {
    setOpenRequestFilter(!openRequestFilter);
  };

  const setRequestSearch = (searchedItem: string): void => {
    if (status === 'FAILED') {
      getInventoryRequestListFailedItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          orderType: 'INVENTORY_REQUEST',
          screenType: status,
          filters: {
            searchText: searchedItem,
          },
        },
      });
    }
    if (status === 'PROCESSED') {
      getInventoryRequestListProgressItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          orderType: 'INVENTORY_REQUEST',
          screenType: status,
          filters: {
            searchText: searchedItem,
          },
        },
      });
    }
    if (status === 'REQUESTED') {
      if (searchedItem.length >= 3) {
        getInventoryRequestListItems({
          variables: {
            offset: 0,
            limit: LIMIT,
            type: status,
            filters: {
              productSearch: searchedItem,
            },
          },
        });
      } else {
        getInventoryRequestListItems({
          variables: {
            offset: 0,
            limit: LIMIT,
            type: status,
          },
        });
      }
    }
  };

  const debouncedFetchRequestedItems = useCallback(debounced(500, setRequestSearch), [
    debounced,
    setRequestSearch,
  ]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchRequestedItems(value);
      } else {
        debouncedFetchRequestedItems(value);
      }
    },
    [debouncedFetchRequestedItems]
  );

  const handleRefetchItems = (filters: Filters | null): void => {
    setOffsetFav(0);
    setOffsetProcessed(0);
    setOffsetRequested(0);
    setOffsetFailed(0);
    if (status === 'REQUESTED') {
      getInventoryRequestListItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          type: status,
          filters,
        },
      });
    }
    if (status === 'FAILED') {
      const failedFilters = JSON.parse(JSON.stringify(filters));
      // eslint-disable-next-line no-param-reassign
      delete filters?.threePL;
      getInventoryRequestListFailedItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          orderType: 'INVENTORY_REQUEST',
          screenType: status,
          filters: {
            ...filters,
            threePL: failedFilters?.threePL?.length ? failedFilters?.threePL[0] : '',
          },
        },
      });
    }
    if (status === 'PROCESSED') {
      const processFilters = JSON.parse(JSON.stringify(filters));
      // eslint-disable-next-line no-param-reassign
      delete filters?.threePL;
      getInventoryRequestListProgressItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          orderType: 'INVENTORY_REQUEST',
          screenType: status,
          filters: {
            ...filters,
            threePL: processFilters?.threePL?.length ? processFilters?.threePL[0] : '',
          },
        },
      });
    }
  };

  const getFilterApiValue = (filterValues: DynamicValue): Filters => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: SelectedData) => item.id);
          if (key === 'productGroup') {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key].value };
          } else if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (Array.isArray(filterValues[key]) && filterValues[key]) {
          if (filterValues[key].length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterDataa = { ...reducedFilterValues };
      return filterDataa;
    }
    return {};
  };

  const sortedcolumn = (property: any): void => {
    setSortColumn(property);
  };

  const filterStore = useSelector(getInventoryRequestFilters);
  useEffect(() => {
    const setFilters = JSON.parse(filterStore || '{}');
    if (setFilters) {
      setUpdatedSelectedFilter(setFilters);
      setSelectedFilter(setFilters);
      setSelectedFilterApiValues(setFilters);
      const filters = getFilterApiValue(setFilters.filterValues);
      delete filters.search;
      handleRefetchItems(filters);
    }
  }, [status]);

  const handleFilterRequest = (
    filterValues: Filter | DynamicValue | null,
    filterName?: string
  ): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setUpdatedSelectedFilter(filter);
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
      delete filters.search;
      handleRefetchItems(filters);
      dispatch(setInventoryRequestFilters(JSON.stringify(filter)));
    } else {
      setSelectedFilterApiValues(null);
      handleRefetchItems(null);
    }
  };

  // Inventory Request sub tabs pagination
  const handlePaginationButtonClicked = (type: string): void => {
    let finalOffset;
    if (status === 'REQUESTED') {
      if (type === NEXT_DATA) {
        finalOffset = offsetRequested + LIMIT;
      } else {
        finalOffset = offsetRequested - LIMIT;
      }
      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getInventoryRequestListItems({
          variables: {
            limit: LIMIT,
            orderBy: sortProperty,
            orderSortType: sortDirection,
            offset: finalOffset,
            type: status,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getInventoryRequestListItems({
          variables: {
            limit: LIMIT,
            offset: finalOffset,
            type: status,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetRequested(finalOffset);
    } else if (status === 'PROCESSED') {
      if (type === NEXT_DATA) {
        finalOffset = offsetProcessed + LIMIT;
      } else {
        finalOffset = offsetProcessed - LIMIT;
      }

      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getInventoryRequestListProgressItems({
          variables: {
            limit: LIMIT,
            orderBy: sortProperty,
            orderSortType: sortDirection,
            irCasedataOffset:
              processedRequests && processedRequests[0]?.offsetData
                ? parseInt(processedRequests[0]?.offsetData, 10)
                : 0,
            offset: finalOffset,
            orderType: 'INVENTORY_REQUEST',
            screenType: status,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getInventoryRequestListProgressItems({
          variables: {
            limit: LIMIT,
            offset: finalOffset,
            irCasedataOffset:
              processedRequests && processedRequests[0]?.offsetData
                ? parseInt(processedRequests[0]?.offsetData, 10)
                : 0,
            orderType: 'INVENTORY_REQUEST',
            screenType: status,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetProcessed(finalOffset);
    } else if (status === 'FAILED') {
      if (type === NEXT_DATA) {
        finalOffset = offsetFailed + LIMIT;
      } else {
        finalOffset = offsetFailed - LIMIT;
      }
      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getInventoryRequestListFailedItems({
          variables: {
            limit: LIMIT,
            orderBy: sortProperty,
            orderSortType: sortDirection,
            offset: finalOffset,
            orderType: 'INVENTORY_REQUEST',
            screenType: status,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getInventoryRequestListFailedItems({
          variables: {
            limit: LIMIT,
            offset: finalOffset,
            orderType: 'INVENTORY_REQUEST',
            screenType: status,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetFailed(finalOffset);
    } else if (status === 'FAVORITES') {
      if (type === NEXT_DATA) {
        finalOffset = offsetFav + LIMIT;
      } else {
        finalOffset = offsetFav - LIMIT;
      }
      getInventoryRequestListFavoritesItems({
        variables: {
          limit: LIMIT,
          offset: finalOffset,
          screenType: status,
          ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
        },
      });
      setOffsetFav(finalOffset);
    }
  };
  let offsetData = offsetRequested;
  if (status === 'PROCESSED') {
    offsetData = offsetProcessed;
  } else if (status === 'FAILED') {
    offsetData = offsetFailed;
  } else if (status === 'FAVORITES') {
    offsetData = offsetFav;
  }

  const handleEditFilter = (editFilter: boolean): void => {
    setIsEditFilter(editFilter);
  };

  const handleEditPress = (filter: FilterPreset, isEditPress: boolean): void => {
    setSelectedFilter(filter);
    setFilterEventModalVisible(!filterEventModalVisible);
    setOpenRequestFilter(!openRequestFilter);
    if (isEditPress) {
      handleEditFilter(true);
    }
  };

  const handleFilterEvents = (
    filterValues: Filter | DynamicValue | null,
    filterName?: string
  ): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setUpdatedSelectedFilter(filter);
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
      dispatch(setInventoryRequestFilters(JSON.stringify(filter)));
      handleRefetchItems(filters);
    } else {
      resetFilters();
    }
  };

  const resetFilters = useCallback((): void => {
    setSelectedFilter(null);
    setUpdatedSelectedFilter(null);
    setIsEditFilter(false);
    setSelectedFilterApiValues(null);
    getInventoryRequestListItems({
      variables: {
        offset: 0,
        limit: LIMIT,
        type: status,
      },
    });
    dispatch(clearInventoryRequestFilters());
  }, []);

  const handleDeleteFilterPreset = (externalId: string): void => {
    const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
    if (filterExternalId && filterExternalId === externalId) {
      resetFilters();
    }
  };

  const handleToggleFilterEvent = (): void => {
    handleEditFilter(false);
    setSelectedFilter(updatedSelectedFilter);
    setFilterEventModalVisible(!filterEventModalVisible);
    setOpenRequestFilter(!openRequestFilter);
  };

  return (
    <IconSettings iconPath="/icons">
      <RequestHeader
        handleSearch={handleSearch}
        handleFilter={handleRequestFilter}
        filterPresets={filterData}
        onEditPress={handleEditPress}
        handleFilterEvents={handleFilterEvents}
        resetFilters={resetFilters}
        type={status}
        selectedFilterApiValues={selectedFilterApiValues}
      />
      {selectedFilter && selectedFilter.filterValues && !isEditFilter && (
        <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterRequest} />
      )}
      <Tabs
        variant="scoped"
        id="inventory-tabs-scoped"
        onSelect={(e: any): void => handleCompletedTab(e)}
        selectedIndex={
          status === 'FAVORITES' ? 3 : status === 'PROCESSED' ? 2 : status === 'FAILED' ? 1 : 0
        }
      >
        <TabsPanel label="Open">
          {loading && (
            <Spinner
              size="large"
              variant="base"
              assistiveText={{ label: 'Loading...' }}
              key="spinner"
            />
          )}
          {openRequests?.length > 0 ? (
            <InventoryRequestDataTable
              orders={openRequests || []}
              offsetData={offsetData}
              type="Open"
              sortedcolumn={sortedcolumn}
              getRequestsList={getInventoryRequestListItems}
              filters={getFilterApiValue(selectedFilter?.filterValues)}
            />
          ) : (
            <div className="no-data-found-container">
              <h1 className="no-data-found-text">No Records Found</h1>
            </div>
          )}
        </TabsPanel>
        <TabsPanel label="Failed">
          {loadingInventoryRequestFailedItems && (
            <Spinner
              size="large"
              variant="base"
              assistiveText={{ label: 'Loading...' }}
              key="spinner"
            />
          )}

          {failedRequests?.length > 0 ? (
            <InventoryRequestDataTable
              orders={failedRequests || []}
              offsetData={offsetData}
              type="Failed"
              sortedcolumn={sortedcolumn}
              getRequestsList={getInventoryRequestListFailedItems}
              filters={getFilterApiValue(selectedFilter?.filterValues)}
            />
          ) : (
            <div className="no-data-found-container">
              <h1 className="no-data-found-text">No Records Found</h1>
            </div>
          )}
        </TabsPanel>
        <TabsPanel label="Processed">
          {loadingInventoryRequestProgressItems && (
            <Spinner
              size="large"
              variant="base"
              assistiveText={{ label: 'Loading...' }}
              key="spinner"
            />
          )}
          {processedRequests?.length > 0 ? (
            <InventoryRequestDataTable
              orders={processedRequests || []}
              type="Processed"
              sortedcolumn={sortedcolumn}
              getRequestsList={getInventoryRequestListProgressItems}
              filters={getFilterApiValue(selectedFilter?.filterValues)}
            />
          ) : (
            <div className="no-data-found-container">
              <h1 className="no-data-found-text">No Records Found</h1>
            </div>
          )}
        </TabsPanel>
        <TabsPanel label="Favorites">
          {loadingInventoryRequestFavoritesItems && (
            <Spinner
              size="large"
              variant="base"
              assistiveText={{ label: 'Loading...' }}
              key="spinner"
            />
          )}
          {favoritesListData?.length ? (
            <InventoryRequestDataTable
              orders={favoritesListData || []}
              type="Favorites"
              sortedcolumn={sortedcolumn}
              getRequestsList={getInventoryRequestListFavoritesItems}
              filters={getFilterApiValue(selectedFilter?.filterValues)}
            />
          ) : (
            <div className="no-data-found-container">
              <h1 className="no-data-found-text">No Records Found</h1>
            </div>
          )}
        </TabsPanel>
      </Tabs>
      <div>
        {status === 'REQUESTED' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetRequested > 0)}
                iconVariant="bare"
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetRequested + 1} - ${offsetRequested + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={openRequests && openRequests.length < 50}
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
        {status === 'PROCESSED' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetProcessed > 0)}
                iconVariant="bare"
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetProcessed + 1} - ${offsetProcessed + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={processedRequests && processedRequests.length < 50}
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
        {status === 'FAILED' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetFailed > 0)}
                iconVariant="bare"
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetFailed + 1} - ${offsetFailed + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={failedRequests && failedRequests.length < 50}
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
        {status === 'FAVORITES' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetFav > 0)}
                iconVariant="bare"
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetFav + 1} - ${offsetFav + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={
                  inventoryRequestFavoritesListData && inventoryRequestFavoritesListData.length < 50
                }
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
        {openRequestFilter && (
          <InventoryReturnFilter
            pathname={pathname}
            isOpen={openRequestFilter}
            toggleOpen={handleRequestFilterModal}
            type={status}
            selectedFilter={selectedFilter}
            handleFilterRequest={handleFilterRequest}
            isRequest
            handleToggleFilterEvent={handleToggleFilterEvent}
            refetchFilterPresets={refetchFilterPresets}
            handleFilterEvents={handleFilterEvents}
            handleEditFilter={handleEditFilter}
            isEditFilter={isEditFilter}
            handleClearFilter={handleDeleteFilterPreset}
          />
        )}
      </div>
    </IconSettings>
  );
};

export default withRouter(InventoryRequestList);
