/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable-next-line react/jsx-wrap-multilines */
/* eslint-disable-next-line react/jsx-one-expression-per-line */
/* eslint-disable-next-line react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import Spinner from '@salesforce/design-system-react/components/spinner';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import Input from '@salesforce/design-system-react/components/input';
import { useSelector } from 'react-redux';
import ContactModal from './ContactModal';
import { getUserInfo } from '../../../../../store/ducks/userInfo';
import '../../index.scss';
import { GET_USER_CONTACTS } from '../../../../../graphql/getUserContacts';
import { DEFAULT_EMAIL_BODY } from '../../../../../util/constants';
import { GET_FAVOURITE_CONTACTS } from '../../../../../graphql/getFavouriteContacts';
import CREATE_UPDATE_FAVOURITE_CONTACT from '../../../../../graphql/mutations/createOrDeleteFavourites';

interface Option {
  id: string;
  name: string;
  email: string;
  label?: string;
  isfavourite?: boolean;
  __typename: string;
  contactRole: string;
}
interface Props {
  open: boolean;
  pdfTitle: string;
  sendingEmail: boolean;
  usageDetails?: any;
  adminFormConfigurationsData?: any;
  formConfigurationsData?: any;
  handleToggleModal: () => void;
  handleSendEmail: (emails: string[], body: string, subject: string, emailAttachments: any) => void;
}

const EmailMultiplePdfModal: FC<Props> = ({
  open,
  pdfTitle,
  sendingEmail,
  handleToggleModal,
  handleSendEmail,
  usageDetails,
  adminFormConfigurationsData,
  formConfigurationsData,
}) => {
  const userInfo = useSelector(getUserInfo);
  const businessUnit = userInfo?.businessunit || 'Sports Med';
  const [inputAddress, setInputAddress] = useState('');
  const [inputSubject, setInputSubject] = useState('');
  const [emailAddresses, setEmailAddresses] = useState<any[]>([]);
  const [manuallyEnteredEmail, setManuallyEnteredEmail] = useState<any[]>([]);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userContacts, setUserContacts] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const { data: getUserContacts } = useQuery(GET_USER_CONTACTS, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: usageDetails?.salesrepId || userInfo?.id,
      accountId: usageDetails?.accountId,
    },
  });

  const { data: getFavouriteContacts } = useQuery(GET_FAVOURITE_CONTACTS, {
    fetchPolicy: 'no-cache',
    variables: {
      salesRepId: usageDetails?.salesrepId || userInfo?.id,
      accountId: usageDetails?.accountId,
    },
  });

  const address: any = {
    'Street Address 1': '',
    'Street Address 2': '',
    City: '',
    Country: '',
    State: '',
    'Zip Code': '',
  };

  let salesRepConfigEmail = '';
  const finalRules = adminFormConfigurationsData[0]?.businessRules;
  finalRules.forEach((br: any): void => {
    if (br.rules in address) {
      address[br.rules] = br.values;
    }

    if (br.rules === 'Email' && br.values && br.isRequired === true) {
      salesRepConfigEmail = br.values;
    }
  });

  const emailBodyData = usageDetails || '';
  const billOnlyConfig = formConfigurationsData?.billOnly[2]?.fields;
  const shipAndBillConfig = formConfigurationsData?.shipAndBill[2]?.fields;

  if (usageDetails?.diplayOrderType === 'Bill Only' && billOnlyConfig) {
    billOnlyConfig.forEach((billOnlyData: any) => {
      if (billOnlyData.isShow === false) {
        if (billOnlyData.name === 'procedure') {
          emailBodyData.procedureName = null;
        } else if (billOnlyData.name === 'surgeon') {
          emailBodyData.surgeonFirstName = null;
          emailBodyData.surgeonLastName = null;
        } else if (billOnlyData.name === 'po') {
          emailBodyData.poNumber = null;
        } else if (billOnlyData.name === 'surgeryDate') {
          emailBodyData.eventDateTime = null;
        } else if (billOnlyData.name === 'patientID') {
          emailBodyData.patientId = null;
        } else {
          emailBodyData[billOnlyData.name] = null;
        }
      }
    });
  }

  if (usageDetails?.diplayOrderType === 'Ship & Bill' && shipAndBillConfig) {
    shipAndBillConfig.forEach((SnBData: any) => {
      if (SnBData.isShow === false) {
        if (SnBData.name === 'procedure') {
          emailBodyData.procedureName = null;
        } else if (SnBData.name === 'surgeon') {
          emailBodyData.surgeonFirstName = null;
          emailBodyData.surgeonLastName = null;
        } else if (SnBData.name === 'po') {
          emailBodyData.poNumber = null;
        } else if (SnBData.name === 'surgeryDate') {
          emailBodyData.eventDateTime = null;
        } else if (SnBData.name === 'patientID') {
          emailBodyData.patientId = null;
        } else {
          emailBodyData[SnBData.name] = null;
        }
      }
    });
  }

  const emailBodyS = DEFAULT_EMAIL_BODY(emailBodyData, businessUnit, address, salesRepConfigEmail);
  const [emailBody, setEmailBody] = useState(emailBodyS);

  const getEmailsFromInput = (inputString: string): string[] => {
    const emailArray = inputString.split(/[\s, ;]+/);
    const emails = [...emailArray];
    if (emailArray.pop() === '') {
      return emailArray;
    }
    return emails;
  };

  const addEmailAddress = useCallback(
    (e: any): void => {
      if (
        e.target.name === 'ss_email' &&
        e.target.value &&
        (e.key === 'Enter' || e.keyCode === 13)
      ) {
        const tempManuallyEnteredEmail = getEmailsFromInput(e.target.value);
        const tempEmails = [...emailAddresses, ...tempManuallyEnteredEmail];
        setEmailAddresses(tempEmails);
        const tempManualEmail = [...manuallyEnteredEmail, ...tempManuallyEnteredEmail];
        setManuallyEnteredEmail(tempManualEmail);
        setInputAddress('');
      }
    },
    [emailAddresses, manuallyEnteredEmail]
  );

  const handleChangeText = (result: string): void => {
    setEmailBody(result);
  };

  const sendEmail = (): void => {
    let emailIds = [];
    emailIds = [...emailAddresses];
    if (inputAddress) {
      const values = getEmailsFromInput(inputAddress);
      emailIds = [...emailAddresses, ...values];
    }
    handleSendEmail(emailIds, emailBody, inputSubject, attachments);
  };
  useEffect(() => {
    window.addEventListener('keyup', addEmailAddress);
    return (): void => {
      window.removeEventListener('keyup', addEmailAddress);
    };
  }, [addEmailAddress]);

  const handleEmailAddress = (event: any): void => {
    setInputAddress(event.target.value);
  };

  const handleContactModalVisible = (): void => {
    setUserContacts(getUserContacts?.getUserContacts);
    setContactModalVisible(!contactModalVisible);
  };

  const handleAdd = (contacts: Option[]): void => {
    if (contacts && contacts?.length > 0) {
      const filteredEmails = contacts.map((item: Option) => item?.email);
      const arr = [...manuallyEnteredEmail, ...filteredEmails];
      setEmailAddresses(arr.filter((item, index) => arr.indexOf(item) === index));
    }
    setLoading(false);
    setContactModalVisible(!contactModalVisible);
  };

  const handleAddPress = (): void => {
    setContactModalVisible(!contactModalVisible);
  };

  const handleDelete = (email: string, index: number): void => {
    const tempAddress = [...emailAddresses];
    tempAddress.splice(index, 1);
    setEmailAddresses(tempAddress);
    setManuallyEnteredEmail(manuallyEnteredEmail.filter(element => element !== email));
  };

  const { allCases, accountName } = usageDetails && usageDetails;
  const setCaseIds = (cases: any) => {
    const caseN = cases?.map((item: any) => {
      return item.caseId;
    });
    return caseN.toString().replace(/,/g, '/');
  };

  const setEmailSubject = (withPdfData: any) => {
    setInputSubject(`Stryker Order Request for ${accountName} / ${setCaseIds(withPdfData)}`);
  };

  const setEmailBodyText = (withPdfData: any) => {
    let userAddress = `${userInfo?.firstName} ${userInfo?.lastName} \n ${userInfo?.email}  \n`;
    /* eslint-disable-next-line no-unused-expressions */
    userInfo?.businessRules.forEach((br: any) => {
      if (br.rules === 'Street Address 1' && br.values) {
        userAddress += `${br.values} \n`;
      } else if (br.rules === 'Street Address 2' && br.values) {
        userAddress += `${br.values} \n`;
      } else if (br.rules === 'City' && br.values) {
        userAddress += `${br.values} \n`;
      } else if (br.rules === 'State' && br.values) {
        userAddress += `${br.values} \n`;
      } else if (br.rules === 'Zip Code') {
        userAddress += `${br.values}`;
      }
    });

    setEmailBody(`Hello,\n\n Attached is the Stryker Order for ${setCaseIds(withPdfData)}
    \n\n This is not an invoice.\n\nPlease direct any questions to the contact listed below.\n\n--------------------------------------------------\nContact Information\n--------------------------------------------------\n${userAddress}\n\n--------------------------------------------------\nOrder Details\n--------------------------------------------------\nAccount Number # ${
      usageDetails.accountName ? usageDetails.accountName?.split('-')[0] : ''
    }\nAccount Name: ${usageDetails.accountName ? usageDetails.accountName?.split('-')[1] : ''}
    \n`);
  };

  const handleSubject = (event: any): void => {
    setInputSubject(event.target.value);
  };

  useEffect(() => {
    const withPdfData = allCases?.filter((c: any) => c.attachmentid);
    setAttachments(withPdfData);
    setEmailSubject(withPdfData);
    setEmailBodyText(withPdfData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountName, allCases]);

  const removeAttachments = (caseId: any) => {
    const attach = attachments?.filter((item: any) => caseId !== item.caseId);
    setAttachments(attach);
  };

  // const surgeryDate = (eventDateTime && moment(eventDateTime).format('MM/DD/YYYY')) || '';

  // Contact Modal properties and methods
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [favourites, setFavourites] = useState<Option[]>([]);
  const [setFavouriteData] = useMutation(CREATE_UPDATE_FAVOURITE_CONTACT);

  const findData = (options: Option[], data: Option): Option[] => {
    return options.filter(
      (element: Option) => element.name === data.name && element.email === data.email
    );
  };

  const toggleOption = (option: Option): void => {
    if (findData(selectedOptions, option).length) {
      setSelectedOptions(
        selectedOptions.filter(
          element => element.name !== findData(selectedOptions, option)[0].name
        )
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const toggleFavorite = (option: Option): void => {
    if (findData(favourites, option).length) {
      setFavourites(
        favourites.filter(element => element.name !== findData(favourites, option)[0]?.name)
      );
    } else {
      setFavourites([...favourites, option]);
      if (!findData(selectedOptions, option).length) {
        setSelectedOptions([...selectedOptions, option]);
      }
    }
  };

  const removeSelectedOption = (option: Option): void => {
    setSelectedOptions(
      selectedOptions.filter(
        element => element.email !== findData(selectedOptions, option)[0]?.email
      )
    );
  };

  const handleAddContacts = (contacts: Option[]): void => {
    setLoading(true);
    const favouritesForPayload: any[] = [];
    favourites.forEach(data => {
      favouritesForPayload.push({
        email: data.email,
        name: data.name,
      });
    });
    const mutation = {
      salesRepId: usageDetails?.salesRepId || userInfo?.id,
      favourites: favouritesForPayload,
      accountId: usageDetails?.accountId,
    };
    setFavouriteData({
      variables: mutation,
    })
      .then(() => {
        setFavourites(favourites);
        handleAdd(contacts);
      })
      .catch();
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const searchTerm = event.target.value;
    const results = getUserContacts?.getUserContacts.filter(
      (item: Option) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email.includes(searchTerm)
    );
    setUserContacts(results);
  };

  useEffect(() => {
    setEmailAddresses(getFavouriteContacts?.getFavourites?.map((data: Option) => data.email));
    setFavourites(
      getUserContacts?.getUserContacts?.filter((data: Option) => data.isfavourite === true)
    );
    setSelectedOptions(getFavouriteContacts?.getFavourites);
    setUserContacts(getUserContacts?.getUserContacts);
  }, [getFavouriteContacts, getUserContacts]);

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal surgery-sheet-email-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={open}
        onRequestClose={handleToggleModal}
        footer={[
          <div className="usage-modal-footer">
            {sendingEmail && <Spinner size="small" />}
            <Button label="Close" key="Close" onClick={handleToggleModal} />
            <Button
              className="yellow-btn"
              label="Send"
              disabled={
                (!inputAddress && emailAddresses?.length === 0) || attachments?.length === 0
              }
              onClick={sendEmail}
              variant="brand"
              key="save"
            />
          </div>,
        ]}
        heading={[<div style={{ textAlign: 'start' }}>Email</div>]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          <section style={{ background: 'white' }}>
            {emailAddresses && emailAddresses?.length > 0 && (
              <div className="slds-p-around_large-1" style={{ display: 'flex', flexWrap: 'wrap' }}>
                {emailAddresses.map((val: any, index: number): any => (
                  <div key={Math.random()} style={{ padding: '5px' }}>
                    <Chip
                      label={val}
                      size="medium"
                      onDelete={(): void => handleDelete(val, index)}
                    />
                  </div>
                ))}
              </div>
            )}
            <div className="slds-p-around_large-1">
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '24fr 1fr ',
                }}
              >
                <Input
                  name="ss_email"
                  label="Add Recipients:"
                  id="ss_email"
                  onChange={handleEmailAddress}
                  value={inputAddress}
                  onPressEnter
                />
                <div
                  style={{
                    display: 'flex',
                    marginTop: '25px',
                    marginLeft: '10px',
                    alignItems: 'center',
                  }}
                  onClick={handleAddPress}
                >
                  <Icon category="utility" name="new" size="small" />
                </div>
              </div>
              <span>Note: Paste comma/semicolon separated email addresses and press Enter</span>
            </div>
            <div
              style={{ display: 'grid', gridTemplateColumns: '2fr 27fr ' }}
              className="slds-p-around_large-1 "
            >
              <p style={{ fontWeight: 'bold', padding: '2px' }}>Subject:</p>
              <div style={{ marginTop: '-11px' }}>
                <Input
                  name="ss_subject"
                  label=""
                  id="ss_subject"
                  onChange={handleSubject}
                  value={inputSubject}
                  onPressEnter
                />
              </div>
            </div>
            <div className="slds-p-around_large-1">
              <p style={{ fontSize: '15px', padding: '0px 5px' }}>Message:</p>
              <textarea
                id="unique-id-1"
                rows={8}
                className="slds-textarea"
                value={emailBody || ''}
                onChange={(event: any): void => handleChangeText(event.target.value)}
              />
            </div>
            <div>
              <div className="slds-p-around_large-1 ">
                <p style={{ fontWeight: 'bold' }}>Attachments</p>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {attachments?.map((val: any, index: number): any => (
                    <div key={index} style={{ padding: '5px' }}>
                      <Chip
                        icon={<Icon category="utility" name="attach" size="x-small" />}
                        label={`${val.caseId}.pdf`}
                        size="medium"
                        onDelete={(): void => removeAttachments(val.caseId)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </section>
        {contactModalVisible && (
          <ContactModal
            loading={loading}
            allContacts={getUserContacts?.getUserContacts}
            handleContactModalVisible={handleContactModalVisible}
            contactModalVisible={contactModalVisible}
            handleAddContacts={(result: Option[]): void => {
              handleAddContacts(result);
            }}
            selectedContact={emailAddresses}
            contactsDataOption={userContacts}
            favourites={favourites}
            toggleFavorite={(result: Option): void => {
              toggleFavorite(result);
            }}
            selectedOptions={selectedOptions}
            toggleOption={(result: Option): void => {
              toggleOption(result);
            }}
            removeSelectedOption={(result: Option): void => {
              removeSelectedOption(result);
            }}
            handleSearch={(result: ChangeEvent<HTMLInputElement>): void => {
              handleSearch(result);
            }}
          />
        )}
      </Modal>
    </IconSettings>
  );
};

export default EmailMultiplePdfModal;
