/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable  @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars*/
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// import moment from 'moment';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
// import { useDispatch, useSelector } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import CycleCountHeader from '../CycleCountHeader';
import CycleCountDataTable from '../CycleCountDataTable';
import { GET_CYCLE_COUNT_REQUEST_LISTS } from '../../../graphql/getCycleCountRequestList';
import { LIMIT, NEXT_DATA, PREV_DATA } from '../../../util/constants';
import debounced from '../../../util/debounced';
import '../index.scss';
import FilterTags from '../../Shared/FilterTags';
import FilterCycleCount from './FilterCycleCount';
import { GET_FILTER_PRESETS } from '../../../graphql/getFilterPresets';
import { getUserInfo } from '../../../store/ducks/userInfo';
import {
  clearReviewCycleCountFilters,
  getReviewCycleCountFilters,
  setReviewCycleCountFilters,
} from '../../../store/ducks/cycleCountFilter';

interface Props {
  type: string;
}

interface SelectedData {
  id: string;
  label: string;
  number: string;
  __typename?: string;
}
interface Filters {
  status: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}

interface DynamicValue {
  [key: string]: any;
}

const ReviewCycleCount: FC<Props> = ({ type }) => {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const userInfo = useSelector(getUserInfo);
  const [cycleCounts, setCycleCounts] = useState<any[]>([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [sortColumn, setSortColumn] = useState<any>();
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [isEditFilter, setIsEditFilter] = useState(false);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const [updatedSelectedFilter, setUpdatedSelectedFilter] = useState<DynamicValue | null>(null);
  const [getCycleCounts, { data: cycleCountsList, refetch: refetchCycleCounts }] = useLazyQuery(
    GET_CYCLE_COUNT_REQUEST_LISTS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  //   const dispatch = useDispatch();

  const { data: filterPresets, refetch: refetchFilterPresets } = useQuery(GET_FILTER_PRESETS, {
    variables: {
      userId: userInfo?.id,
      type: 'CycleCount',
      filterValues: {},
    },
  });

  useEffect(() => {
    getCycleCounts({
      variables: {
        limit: 50,
        offset: 0,
        type,
        filters: selectedFilterApiValues,
      },
    });
  }, [getCycleCounts, selectedFilterApiValues, type]);

  const refetchCycleCountsList = (): void => {
    getCycleCounts({
      variables: {
        limit: 50,
        offset: 0,
        type,
        filters: selectedFilterApiValues,
      },
    });
  };

  const filterData = filterPresets?.getFilterPresets;

  const getCycleCountList = useCallback((items: any[]): any[] => {
    if (items && items.length > 0) {
      const list = items.map((item: any) => {
        return {
          ...item,
          submittedDate: item.submittedDate ? moment.utc(item.submittedDate).format('L') : '--',
          startDate: item.startDate ? moment.utc(item.startDate).format('L') : '--',
          endDate: item.endDate ? moment.utc(item.endDate).format('L') : '--',
          inventoryHold: item.isInventoryHold === true ? 'Yes' : 'No',
          salesRep: item.salesRep ? item.salesRep.join(', ') : null,
        };
      });
      return list;
    }
    return [];
  }, []);
  useEffect(() => {
    if (cycleCountsList?.getCycleCountRequestList) {
      const list = getCycleCountList(cycleCountsList.getCycleCountRequestList);
      setCycleCounts(list);
    }
  }, [cycleCountsList, getCycleCountList]);

  const handleRefetchItems = useCallback(
    (filterData: Filters | DynamicValue | null): void => {
      setOffset(0);
      getCycleCounts({
        variables: {
          offset: 0,
          limit: LIMIT,
          type,
          filters: filterData,
        },
      });
    },
    [getCycleCounts, type]
  );

  const filterStore = useSelector(getReviewCycleCountFilters);

  useEffect(() => {
    const setFilters = JSON.parse(filterStore || '{}');

    if (setFilters && setFilters.filterValues) {
      setSelectedFilter(setFilters);
      setUpdatedSelectedFilter(setFilters);
      const filters = getFilterApiValue(setFilters.filterValues);
      setSelectedFilterApiValues(filters);
      handleRefetchItems(filters);
    }
  }, [filterStore, handleRefetchItems]);

  const handlePaginationButtonClicked = (nav: string): void => {
    let finalOffset;
    if (nav === NEXT_DATA) {
      finalOffset = offset + LIMIT;
    } else {
      finalOffset = offset - LIMIT;
    }
    if (sortColumn) {
      const sortProperty = sortColumn.property;
      const { sortDirection } = sortColumn;
      getCycleCounts({
        variables: {
          offset: finalOffset,
          limit: LIMIT,
          type,
          orderBy: sortProperty,
          orderSortType: sortDirection,
          ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
        },
      });
    } else {
      getCycleCounts({
        variables: {
          offset: finalOffset,
          limit: LIMIT,
          type,
          ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
        },
      });
    }

    setOffset(finalOffset);
  };

  const sortCycleCountList = (data: any[]): void => {
    setCycleCounts(data);
  };
  const handleToggleFilterCycleCount = (): void => {
    handleEditFilter(false);
    setSelectedFilter(updatedSelectedFilter);
    setFilterModalVisible(!filterModalVisible);
  };

  const resetFilters = useCallback((): void => {
    dispatch(clearReviewCycleCountFilters());
    setSelectedFilter(null);
    setIsEditFilter(false);
    setUpdatedSelectedFilter(null);
    setSelectedFilterApiValues(null);
    setOffset(0);
    getCycleCounts({
      variables: {
        offset: 0,
        limit: LIMIT,
        type,
      },
    });
  }, [dispatch, getCycleCounts, type]);

  const sortedcolumn = (property: any): void => {
    setSortColumn(property);
  };

  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          if (key === 'inventoryLocation') {
            values = filterValues[key].map((item: SelectedData) =>
              item.label.split('-')[0]?.trim()
            );
          } else if (key === 'countType') {
            values = filterValues[key].map((item: SelectedData) => item?.label || item);
          } else {
            values = filterValues[key].map((item: SelectedData) => item?.id || item);
          }
          if (values && values.length > 0) {
            if (key === 'salesReps') {
              reducedFilterValues = { ...reducedFilterValues, salesRep: values[0]?.label };
            } else {
              reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
            }
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };
  const handleFilterCycleCount = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
      setUpdatedSelectedFilter(filters);
      dispatch(setReviewCycleCountFilters(JSON.stringify(filter)));
      handleRefetchItems(filters);
    } else {
      resetFilters();
    }
  };

  const setRequestSearch = (searchedItem: string): void => {
    getCycleCounts({
      variables: {
        offset: 0,
        limit: LIMIT,
        type,
        filters: {
          searchText: searchedItem,
        },
      },
    });
  };

  const debouncedFetchRequestedItems = useCallback(debounced(500, setRequestSearch), [
    debounced,
    setRequestSearch,
  ]);

  const handleCycleCountSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchRequestedItems(value);
      } else {
        debouncedFetchRequestedItems(value);
      }
    },
    [debouncedFetchRequestedItems]
  );

  const handleEditFilter = (editFilter: boolean): void => {
    setIsEditFilter(editFilter);
  };

  const handleEditPress = (filter: any, isEditPress: boolean): void => {
    setSelectedFilter(filter);
    setFilterModalVisible(!filterModalVisible);
    if (isEditPress) {
      handleEditFilter(true);
    }
  };

  const handleDeleteFilterPreset = (externalId: string): void => {
    const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
    if (filterExternalId && filterExternalId === externalId) {
      resetFilters();
    }
  };

  return (
    <div className="slds-p-horizontal_large slds-p-vertical_x-large events_list">
      <IconSettings iconPath="/icons">
        {filterModalVisible && (
          <FilterCycleCount
            handleToggleFilterCycleCount={handleToggleFilterCycleCount}
            filterModalVisible={filterModalVisible}
            selectedFilter={selectedFilter}
            handleFilterCycleCount={handleFilterCycleCount}
            resetFilters={resetFilters}
            handleEditFilter={handleEditFilter}
            handleClearFilter={handleDeleteFilterPreset}
            refetchFilterPresets={refetchFilterPresets}
            isEditFilter={isEditFilter}
          />
        )}
        <CycleCountHeader
          reviewCount={cycleCounts?.length}
          selectedFilterApiValues={selectedFilterApiValues}
          label=""
          handleToggleFilterCycleCount={handleToggleFilterCycleCount}
          handleCycleCountSearch={handleCycleCountSearch}
          refetchCycleCounts={refetchCycleCountsList}
          type="REVIEW"
          filterPresets={filterData}
          onEditPress={handleEditPress}
          handleFilterEvents={handleFilterCycleCount}
          resetFilters={resetFilters}
        />
        {selectedFilter && selectedFilter.filterValues && !isEditFilter && (
          <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterCycleCount} />
        )}
        <div className="order-list-container">
          {!cycleCountsList && (
            <Spinner
              size="large"
              variant="base"
              assistiveText={{ label: 'Loading...' }}
              key="spinner"
            />
          )}
          <CycleCountDataTable
            cycleCounts={cycleCountsList?.getCycleCountRequestList === null ? [] : cycleCounts}
            sortCycleCountList={sortCycleCountList}
            sortedcolumn={sortedcolumn}
            getCycleCounts={getCycleCounts}
            refetch={refetchCycleCounts}
            selectedFilterApiValues={selectedFilterApiValues}
            type="REVIEW"
          />
        </div>
        <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronleft"
              iconSize="small"
              disabled={!(offset > 0)}
              iconVariant="bare"
              onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
              variant="icon"
            />
          </div>
          <div className="slds-col slds-p-left_medium slds-p-right_medium">
            <span>{`${offset + 1} - ${offset + LIMIT}`}</span>
          </div>
          <div className="slds-col">
            {cycleCountsList?.getCycleCountRequestList === null ? (
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled
                variant="icon"
              />
            ) : (
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={
                  cycleCountsList &&
                  cycleCountsList?.getCycleCountRequestList.length &&
                  cycleCountsList?.getCycleCountRequestList.length < 50
                }
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            )}
          </div>
        </div>
      </IconSettings>
    </div>
  );
};

export default ReviewCycleCount;
