/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
// import Popover from '@salesforce/design-system-react/components/popover';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import Popover from '@salesforce/design-system-react/components/popover';
import { useSelector } from 'react-redux';
import { getEventIcon } from '../../../util/utilityFunctions';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { labelMappingTransfer, TRANSFER_FORM_LIST } from '../../../util/constants';

interface Props {
  handleTransfer: () => void;
  handleSearch: (event: any, val: string) => void;
  handleFilter: () => void;
  isSportMed: boolean;
  handleTransferActionDropdownSelect: (val: any) => void;
  resetFilters: () => void;
  filterPresets: FilterPreset[];
  onEditPress: (filter: FilterPreset, isEditPress: boolean) => void;
  handleFilterEvents: (filterValues: Filter, filterName: string) => void;
  selectedFilterApiValues: any;
}

interface SelectedData {
  id: string;
  label: string;
}
interface Filter {
  search?: string;
  status: SelectedData[];
  threePL: string[];
  submitToDate: Date | string;
  submitFromDate: Date | string;
}
interface FilterPreset {
  filterValues: Filter;
  filterExternalId: string;
  filterName: string;
}

const TransferHeader: FC<Props> = ({
  handleSearch,
  handleFilter,
  // handleTransfer,
  // isSportMed,
  handleTransferActionDropdownSelect,
  resetFilters,
  onEditPress,
  handleFilterEvents,
  filterPresets,
  selectedFilterApiValues,
}) => {
  const filtersLength = selectedFilterApiValues ? Object.keys(selectedFilterApiValues).length : 0;
  const userInfo = useSelector(getUserInfo);
  const requiredForms = userInfo?.assignedScreens;
  const [transferForms, setTransferForms] = useState<any>(TRANSFER_FORM_LIST);
  let BUconfiguredForm: { label: string; value: string }[] = [];

  const [filterPresetOpen, setFilterPresetOpen] = useState(false);

  const handleFilterPresetPopover = useCallback((): void => {
    setFilterPresetOpen(!filterPresetOpen);
  }, [filterPresetOpen]);

  const handleFilterPresetPopoverClose = useCallback((): void => {
    setFilterPresetOpen(false);
  }, []);

  const handleFilterEventItems = (filterValues: Filter, filterName: string) => (): void => {
    setFilterPresetOpen(false);
    handleFilterEvents(filterValues, filterName);
  };

  const handleEditPress = (filter: FilterPreset) => (): void => {
    setFilterPresetOpen(false);
    onEditPress(filter, true);
  };

  useEffect(() => {
    const labels =
      // eslint-disable-next-line consistent-return
      requiredForms?.map((rec: any) => {
        if (Object.keys(labelMappingTransfer).includes(rec.screen) && !rec.isRequired) {
          return labelMappingTransfer[rec.screen];
        }
      }) || [];

    if (labels?.length > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      BUconfiguredForm = TRANSFER_FORM_LIST.filter((items: any) => {
        return !labels.includes(items.label);
      });
      setTransferForms(BUconfiguredForm);
    }
  }, [requiredForms]);
  // W.R.T US 294987 AC Sports med transfer new button form will open rep transfer and fucntionality will as we had for sports med
  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      {transferForms?.length > 0 && (
        <ButtonGroup id="button-group-page-header-actions">
          <Dropdown
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="add"
            iconPosition="left"
            label="New"
            options={transferForms}
            onSelect={(value: string): void => handleTransferActionDropdownSelect(value)}
          />
        </ButtonGroup>
      )}
      {/* {transferForms?.length > 0 &&
        transferForms?.find((rec: any) => rec?.label === 'Rep Transfer') &&
        isSportMed && (
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="add"
            label="New"
            iconPosition="left"
            onClick={handleTransfer}
          />
        )} */}
    </PageHeaderControl>
  );
  const headerControls = (): ReactNode => (
    <>
      <PageHeaderControl className="slds-m-left_large">
        <Input
          iconRight={
            <InputIcon
              assistiveText={{
                icon: 'Search',
              }}
              name="search"
              category="utility"
            />
          }
          id="search-by-product"
          onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
          placeholder="Search by ID"
        />
      </PageHeaderControl>
      <PageHeaderControl className="slds-m-left_large">
        <ButtonGroup variant="list" id="button-group-page-header-controls">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleFilter}
          />
          <div className="slds-dropdown-trigger slds-dropdown-trigger_click filter-preset-popover">
            <Popover
              align="bottom"
              isOpen={filterPresetOpen}
              onClose={handleFilterPresetPopoverClose}
              onRequestClose={handleFilterPresetPopoverClose}
              ariaLabelledby=""
              body={
                <div className="filter-preset_dropdown">
                  <ul className="slds-dropdown__list" role="menu" aria-label="Show More">
                    <li className="slds-dropdown__item" role="presentation" key="reset">
                      <Button
                        className="slds-button slds-button_neutral filter-preset-reset-button"
                        onClick={resetFilters}
                      >
                        Reset
                      </Button>
                    </li>
                    {filterPresets &&
                      filterPresets.map((filter: FilterPreset) => {
                        return (
                          <li
                            className="slds-dropdown__item"
                            role="presentation"
                            key={filter.filterExternalId}
                          >
                            <Button
                              className="slds-button slds-button_neutral filter-preset-heading-button"
                              onClick={handleFilterEventItems(
                                filter.filterValues,
                                filter.filterName
                              )}
                            >
                              {filter.filterName}
                            </Button>
                            <Button
                              className="slds-button slds-button_neutral filter-preset-edit-button"
                              onClick={handleEditPress(filter)}
                            >
                              Edit
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              }
              id="popover-walkthrough"
            >
              <Button
                className="filter-drop-trigger"
                iconCategory="utility"
                iconName="filter"
                iconPosition="left"
                label="Filter Presets"
                onClick={handleFilterPresetPopover}
              />
            </Popover>
          </div>
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  const iconPath = getEventIcon('Inventory');

  return (
    <div>
      <IconSettings iconPath="/icons">
        <PageHeader
          className="events_header"
          icon={<img src={iconPath} className="logo-image" alt="orders" />}
          label="Inventory"
          onRenderActions={headerActions}
          onRenderControls={headerControls}
          title="Transfer"
          truncate
          variant="object-home"
          info={
            <span key="text">
              {`${0} items ${filtersLength ? `• ${filtersLength} Filters ` : ''}`}
              {filtersLength && (
                <Button key="clear" className="refresh_button" onClick={resetFilters}>
                  Clear
                </Button>
              )}
            </span>
          }
        />
      </IconSettings>
    </div>
  );
};

export default TransferHeader;
