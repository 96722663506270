/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline*/
/**
 * Module for header on Orders page
 * @module src/OrdersHeader
 */
import React, { FC, ReactNode } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import { getEventIcon } from '../../../util/utilityFunctions';
import { ORDERS } from '../../../util/constants';

interface Props {
  label: string;
  handleOrderSearch: (event: any, val: string) => void;
}

const GeneratePdfHeader: FC<Props> = ({ handleOrderSearch }) => {
  const headerControls = (): ReactNode => (
    <>
      <PageHeaderControl className="slds-m-left_large">
        <div className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
          <div className="slds-col_padded">
            <Input
              iconRight={
                <InputIcon
                  assistiveText={{
                    icon: 'Search',
                  }}
                  name="search"
                  category="utility"
                />
              }
              id="search-case-id"
              placeholder="Search"
              onChange={(_event: any, searchItem: string): void =>
                handleOrderSearch(_event, searchItem)
              }
            />
          </div>
        </div>
      </PageHeaderControl>
    </>
  );

  const iconPath = getEventIcon(ORDERS);

  return (
    <div>
      <IconSettings iconPath="/icons">
        <PageHeader
          className="events_header"
          icon={<img src={iconPath} className="logo-image" alt="Generated PDF" />}
          label="Orders"
          title="Generated PDF"
          onRenderControls={headerControls}
          truncate
          variant="object-home"
        />
      </IconSettings>
    </div>
  );
};

export default GeneratePdfHeader;
