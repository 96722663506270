/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable dot-notation */
/* eslint-disable no-console */
/* eslint-disable react/no-danger */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */

import React, { FC, ReactNode, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Icon from '@salesforce/design-system-react/components/icon';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import Button from '@salesforce/design-system-react/components/button';
import CancelModal from '../deleteNotificationsPopup';
import { NOTIFY_TYPE } from '../../../util/constants';
import { GET_USER_NOTIFICATION_DETAILS } from '../../../graphql/getUserNotificationDetail';
import { UPDATE_USER_NOTIFICATION } from '../../../graphql/mutations/updateUserNotification';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import SnackBar from '../../Shared/SnackBar';
import { getSvgIcon } from '../../../util/utilityFunctions';
import { getUserId } from '../../../store/ducks/userId';
import { getUserInfo } from '../../../store/ducks/userInfo';
import './index.scss';

interface Props {
  type: string;
}

// eslint-disable-next-line no-empty-pattern
const NotificationDetails: FC<RouteComponentProps> = ({ location, history }) => {
  const { state } = location;
  const { id } = state;
  const { open, notification, openSnackBar } = useSnackBar();
  const userInfo = useSelector(getUserInfo);
  const [notificationDetails, setNotificationDetails] = useState<any>();
  const [updateNotification, { loading: isLoading }] = useMutation(UPDATE_USER_NOTIFICATION);
  const [
    getUserNotificationDetail,
    {
      data: getUserNotificationDetailData,
      refetch: refetchNotificationDetails,
      loading: getNotificationDetailsLoader,
    },
  ] = useLazyQuery(GET_USER_NOTIFICATION_DETAILS, {
    fetchPolicy: 'no-cache',
  });
  const starMarkedIcon = getSvgIcon('starMarked');
  const starNotMarkedIcon = getSvgIcon('starNotMarked');
  const [sortedFlag, setSortedFlag] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);

  useEffect(() => {
    getUserNotificationDetail({ variables: { id } });
  }, [getUserNotificationDetail]);

  useEffect(() => {
    if (getUserNotificationDetailData && getUserNotificationDetailData.getUserNotificationDetail) {
      const data = getUserNotificationDetailData.getUserNotificationDetail;
      setNotificationDetails(data);
    }
  }, [getUserNotificationDetailData, refetchNotificationDetails]);

  const handleNavigateToEventList = (): void => {
    window.history.back();
  };

  const handleUpdateNotifications = (updateDetails: any, reloadFlag: boolean): void => {
    updateNotification({
      variables: updateDetails,
    }).then(response => {
      if (
        response &&
        response?.data &&
        response?.data?.updateUserNotification &&
        response?.data?.updateUserNotification?.message &&
        response?.data?.updateUserNotification?.message === 'success'
      ) {
        if (reloadFlag) {
          refetchNotificationDetails();
        } else {
          history.replace('/notificationCenter');
        }
      } else {
        console.log('error while update');
      }
    });
  };

  const openInNewTab = (url: any) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const toggleFlag = (): void => {
    const updateDetails = {
      id: [notificationDetails.id],
      type: 'Update',
      isStar: !notificationDetails.isStar,
    };
    handleUpdateNotifications(updateDetails, true);
  };

  const handleCancelModalConfirmPress = (): void => {
    const updateDetails = {
      id: [notificationDetails.id],
      type: 'Delete',
    };
    handleUpdateNotifications(updateDetails, false);
    setShowCancelPopup(!showCancelPopup);
  };

  const handleCancelModal = (): void => {
    setShowCancelPopup(!showCancelPopup);
  };

  const handleDelete = (): void => {
    setShowCancelPopup(true);
  };

  const handleMarkAsUnread = (): void => {
    const updateDetails = {
      id: [notificationDetails.id],
      type: 'Update',
      isRead: false,
    };
    handleUpdateNotifications(updateDetails, false);
  };

  const handleLink = () => {
    if (notificationDetails.screenName === 'Inventory Request') {
      const { transactionExternalId, transactionType } = notificationDetails;
      history.push('/inventoryRequestDetails', {
        isEventInventoryFlow: false,
        externalId: transactionExternalId,
        type: 'Open',
      });
    }

    if (notificationDetails.screenName === 'Return Label') {
      const { transactionExternalId, transactionType } = notificationDetails;
      history.push('/inventoryReturnDetails', {
        externalId: transactionExternalId,
      });
    }

    if (notificationDetails.screenName === 'Orders') {
      const { transactionExternalId, transactionType } = notificationDetails;
      history.push('/OrdersUsageDetails', { externalId: transactionExternalId });
    }
    if (notificationDetails.screenName === 'Inventory') {
      const { transactionExternalId, transactionType } = notificationDetails;
      history.push('/surgicalDetails', {
        externalId: transactionExternalId,
        id,
      });
    }
    if (notificationDetails.screenName === 'Sub Inventory List') {
      const { transactionExternalId, transactionType } = notificationDetails;
      history.push('/inventory', {
        externalId: transactionExternalId,
        id,
      });
    }
    if (notificationDetails.screenName === 'Inventory Request-Order') {
      const { transactionSfid } = notificationDetails;
      history.push('/inventoryRequestDetails', {
        isEventInventoryFlow: false,
        externalId: transactionSfid,
        type: 'Failed',
      });
    }
    if (notificationDetails.screenName === 'Bill Only-Order') {
      const { transactionSfid, transactionType } = notificationDetails;
      history.push('/OrdersUsageDetails', { orderId: transactionSfid, type: 'Failed' });
    }
    if (
      notificationDetails.screenName === 'SAP Rejection' ||
      notificationDetails.screenName === 'IR Shippment' ||
      notificationDetails.screenName === 'IR Submission'
    ) {
      const { transactionSfid } = notificationDetails;
      history.push('/inventoryRequestDetails', {
        isEventInventoryFlow: false,
        externalId: transactionSfid,
        type: 'Processed',
      });
    }
    if (notificationDetails.screenName === 'S&B Shippment') {
      const { transactionSfid } = notificationDetails;
      history.push('/OrdersUsageDetails', {
        orderId: transactionSfid,
        type: 'Processed',
      });
    }
    if (notificationDetails?.screenName === 'Cycle Count') {
      const { transactionSfid } = notificationDetails;

      const cycleCountId = transactionSfid
        ?.replace(/\\/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/"/g, '');

      history.push('/cycleCountDetails', {
        cycleCountExternalId: cycleCountId,
      });
    }
  };

  const actions = (): ReactNode => (
    <PageHeaderControl>
      <div style={{ display: 'flex' }}>
        <Button
          style={{ color: 'black', marginRight: '10px', fontWeight: 600, paddingVertical: '10px' }}
          align="right"
          label="Mark as Unread"
          onClick={handleMarkAsUnread}
        />
        <div style={{ float: 'right' }} onClick={handleDelete}>
          <Icon
            style={{ margin: '10px', float: 'right' }}
            category="utility"
            name="delete"
            size="small"
          />
        </div>
      </div>
    </PageHeaderControl>
  );
  let detailsString = notificationDetails
    ? notificationDetails?.details?.replaceAll('<br />', `\n`)
    : '';
  const detailsText = notificationDetails
    ? notificationDetails?.statmentValue?.replaceAll('<br />', `\n`)
    : '';
  const splitted =
    notificationDetails && notificationDetails?.description
      ? notificationDetails?.description?.split('____', 2)
      : '';
  if (splitted) {
    detailsString = detailsString
      ? detailsString.replaceAll(
          'CCStartDateValue',
          splitted[0]
          // moment(splitted[0])
          //   .utc()
          //   .format('MM/DD/YYYY')
        )
      : '';
    detailsString = detailsString
      ? detailsString.replaceAll(
          'CCEndDateValue',
          splitted[1]
          // moment(splitted[1])
          //   .utc()
          //   .format('MM/DD/YYYY')
        )
      : '';
  }

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {(getNotificationDetailsLoader || isLoading) && (
        <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
      )}
      <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px' }}>
        <Button
          style={{ color: 'black' }}
          iconCategory="utility"
          iconName="back"
          iconPosition="left"
          label="Back"
          onClick={handleNavigateToEventList}
        />
      </div>
      <PageHeader
        className="slds-m-around_small slds-m-bottom_none"
        icon={
          <img
            src="icons/notification.svg"
            className="logo-image"
            alt="company"
            style={{ width: '2.5rem', height: '2.5rem' }}
          />
        }
        onRenderActions={actions}
        title={
          <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
            <span className="slds-col event_id">Notification</span>
          </div>
        }
        variant="record-home"
      />
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        <li className="slds-page-header__detail-block slds-col slds-size_0.5-of-12">
          <div
            className="slds-text-title"
            title="Flag"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Flag
          </div>
          <div className="slds-tooltip-trigger">
            <p onClick={toggleFlag} style={{ padding: '5px' }}>
              {notificationDetails && notificationDetails.isStar ? (
                <img src={starMarkedIcon} className="logo-image" alt="ascending" />
              ) : (
                <img src={starNotMarkedIcon} className="logo-image" alt="descending" />
              )}
            </p>
          </div>
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_1.5-of-12">
          <div
            className="slds-text-title"
            title="Notification ID"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Notification ID
          </div>
          <div className="slds-tooltip-trigger">
            <div title={notificationDetails?.id || ''}>{notificationDetails?.id || ''}</div>
          </div>
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_1.5-of-12">
          <div
            className="slds-text-title"
            title="User"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            User
          </div>
          <div className="slds-tooltip-trigger">
            <div title={notificationDetails?.salesRepName || ''}>
              {(notificationDetails?.subject === 'You have now been removed from Trial Hold' &&
                notificationDetails?.transactionSfid === '') ||
              (notificationDetails?.subject === 'You have now been placed on Trial Hold' &&
                notificationDetails?.transactionSfid === '')
                ? notificationDetails?.createdBy || ''
                : notificationDetails?.salesRepName || ''}
            </div>
          </div>
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_1.5-of-12">
          <div
            className="slds-text-title"
            title="Type"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Type
          </div>
          <div className="slds-tooltip-trigger">
            <div title={notificationDetails?.transactionType || ''}>
              {notificationDetails?.transactionType || ''}
            </div>
          </div>
        </li>
        <li className="slds-page-header__detail-block slds-col slds-size_1.5-of-12">
          <div
            className="slds-text-title"
            title="Date"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Date
          </div>
          <p className="slds-line-clamp">
            {notificationDetails?.notificationCreatedDate
              ? moment(notificationDetails?.notificationCreatedDate)
                  .utc()
                  .format('MM/DD/YYYY')
              : '--'}
          </p>
        </li>
        <li className="slds-page-header__detail">
          <div
            className="slds-text-title"
            title="Subject"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Subject
          </div>
          <div className="slds-tooltip-trigger">
            <div style={{ fontSize: '16px' }} title={notificationDetails?.subject || ''}>
              {notificationDetails?.subject || ''}
            </div>
          </div>
        </li>
      </ul>
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        <li className="slds-page-header__detail-block slds-col slds-size_12-of-12">
          <div
            className="slds-text-title"
            title="Link"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Link
          </div>
          <div className="slds-tooltip-trigger">
            {notificationDetails?.screenName !== 'Sub Inventory List' &&
            notificationDetails?.subject !== 'You have now been placed on Trial Hold' &&
            notificationDetails?.subject !== 'You have now been removed from Trial Hold' ? (
              <a onClick={handleLink}>{notificationDetails?.transactionNumber || '--'}</a>
            ) : (
              '--'
            )}
          </div>
        </li>
      </ul>
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none"
        // style={{ borderBottom: 'none' }}
      >
        <li className="slds-page-header__detail">
          <div
            className="slds-text-title"
            title="Details"
            style={{ fontSize: '8px', color: '#F8F8F8', fontWeight: 600 }}
          >
            Details
          </div>
          <div
            style={{ fontSize: '16px', whiteSpace: 'pre-wrap' }}
            className="slds-tooltip-trigger"
          >
            {notificationDetails?.subject === 'You have now been removed from Trial Hold' ||
            notificationDetails?.subject === 'You have now been placed on Trial Hold' ? (
              <span>{notificationDetails?.details}</span>
            ) : (
              ''
            )}
            {notificationDetails?.screenName === 'Hold Inventory' ? (
              <span>{notificationDetails?.details}</span>
            ) : (
              ''
            )}
            {(notificationDetails?.screenName === 'IR Shippment' ||
              notificationDetails?.screenName === 'S&B Shippment') && (
              <span>
                {detailsText?.split(notificationDetails?.transactionNumber)[0]}
                <a onClick={handleLink}>{notificationDetails?.transactionNumber || ''}</a>
                <span
                  dangerouslySetInnerHTML={{
                    __html: detailsText?.split(notificationDetails?.transactionNumber)[1],
                  }}
                />
              </span>
            )}
            {notificationDetails?.screenName !== 'Sub Inventory List' &&
              notificationDetails?.screenName !== 'Hold Inventory' &&
              notificationDetails?.screenName !== 'IR Shippment' &&
              notificationDetails?.subject !== 'You have now been removed from Trial Hold' &&
              notificationDetails?.subject !== 'You have now been placed on Trial Hold' &&
              notificationDetails?.screenName !== 'S&B Shippment' && (
                <>
                  <span>
                    {detailsString.split(notificationDetails?.transactionNumber)[0]}
                    <a onClick={handleLink}>{notificationDetails?.transactionNumber || ''}</a>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: detailsString.split(notificationDetails?.transactionNumber)[1],
                      }}
                    />
                  </span>
                  <br />
                  {notificationDetails?.transactionType === 'Cycle Count' && (
                    <a onClick={() => openInNewTab(notificationDetails?.ccLink || '')}>
                      {notificationDetails?.ccLink || ''}
                    </a>
                  )}
                </>
              )}
            {notificationDetails?.screenName === 'Sub Inventory List' && (
              <div className="App">
                <p style={{ paddingTop: '8px' }}>Hello,</p>
                <p style={{ paddingTop: '20px' }}>{notificationDetails?.statmentValue || ''}</p>
                <h1 style={{ padding: '8px', marginTop: '20px' }} className="table_header">
                  {notificationDetails?.tableHeader || ''}
                </h1>
                <table style={{ border: '1px solid black' }}>
                  <thead>
                    <tr>
                      <th
                        style={{ padding: '8px', border: '1px solid #dddddd', textAlign: 'center' }}
                      >
                        Part
                      </th>
                      <th
                        style={{ padding: '8px', border: '1px solid #dddddd', textAlign: 'center' }}
                      >
                        Lot/Serial Number
                      </th>
                      <th
                        style={{ padding: '8px', border: '1px solid #dddddd', textAlign: 'center' }}
                      >
                        Expiration Date
                      </th>
                      <th
                        style={{ padding: '8px', border: '1px solid #dddddd', textAlign: 'center' }}
                      >
                        Account
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {notificationDetails &&
                      notificationDetails.tableValue.length > 0 &&
                      notificationDetails.tableValue.map((value: any, key: any) => {
                        return (
                          <tr>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {value.productNumber}
                            </td>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {value.lotNumber}
                            </td>
                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {moment(new Date(value.expirationDate))
                                .utc()
                                .local()
                                .format('MM/DD/YYYY')}
                            </td>

                            <td
                              style={{
                                padding: '8px',
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                              }}
                            >
                              {value.Account}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </li>
      </ul>
      {showCancelPopup && (
        <CancelModal
          canceModalVisible={showCancelPopup}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
        />
      )}
    </IconSettings>
  );
};

export default withRouter(NotificationDetails);
