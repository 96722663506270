/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import '../index.scss';
import ReturnStatusCell from './ReturnStatusCell';
import InventoryReturnTypeCell from './InventoryReturnTypeCell';
import InventoryAccountCell from './InventoryAccountCell';
import ReturnIdCell from './ReturnIdCell';
import EventIdCell from './EventIdCell';

interface Props {
  type: string;
  data: any[];
  sortOrderList: (list: any) => void;
  sortedcolumn: (property: any) => void;
  filters: any;
  offsetData?: any;
}

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}

// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
ReturnIdCell.displayName = DataTableCell.displayName;
EventIdCell.displayName = DataTableCell.displayName;
ReturnStatusCell.displayName = DataTableCell.displayName;
InventoryReturnTypeCell.displayName = DataTableCell.displayName;
InventoryAccountCell.displayName = DataTableCell.displayName;

const ReturnDataTable: FC<Props> = ({
  data,
  type,
  filters,
  sortOrderList,
  sortedcolumn,
  offsetData,
}) => {
  const [sortObj, setSortObj] = useState<Sort>();
  const handleSort = (sortColumn: any): void => {
    const sortProperty = sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    // needs to work in both directions
    if (type === 'Returns') {
      sortOrderList({
        variables: {
          limit: 50,
          orderBy: sortProperty,
          orderSortType: sortDirection,
          offset: offsetData,
          type: type.toUpperCase(),
          ...(filters && { filters }),
        },
      });
    } else if (type === 'Failed' || type === 'Completed') {
      sortOrderList({
        variables: {
          limit: 50,
          orderBy: sortProperty,
          orderSortType: sortDirection,
          offset: offsetData,
          orderType: 'INVENTORY_RETURN',
          screenType: type === 'Failed' ? type.toUpperCase() : 'PROCESSED',
          ...(filters && { filters }),
        },
      });
    }
    setSortObj(sort);
    sortedcolumn(sortColumn);
  };
  return (
    <DataTable
      assistiveText={{
        columnSort: 'sort this column',
        columnSortedAscending: 'asc',
        columnSortedDescending: 'desc',
      }}
      items={data || []}
      id="DataTableExample-2"
      className="font-12"
      fixedLayout
      keyboardNavigation
      joined
      onSort={handleSort}
    >
      {[
        <DataTableColumn
          key="orderNumber"
          label="Return"
          property="orderNumber"
          width="13%"
          isSorted={sortObj?.sortColumn === 'orderNumber'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.orderNumber}
        >
          <ReturnIdCell type={type} />
        </DataTableColumn>,
        <DataTableColumn
          key="parentEventId"
          label="Event"
          property="parentEventId"
          width="13%"
          isSorted={sortObj?.sortColumn === 'parentEventId'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.parentEventId}
        >
          <EventIdCell type={type} />
        </DataTableColumn>,
        <DataTableColumn
          key="type"
          label="Type"
          property="type"
          isSorted={sortObj?.sortColumn === 'type'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.type}
          width="180px"
        >
          <InventoryReturnTypeCell state={type} />
        </DataTableColumn>,
        <DataTableColumn
          key="visualIndicator"
          label="Status"
          property="visualIndicator"
          width="180px"
          isSorted={sortObj?.sortColumn === 'visualIndicator'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.status}
        >
          <ReturnStatusCell />
        </DataTableColumn>,
        type === 'Failed' && (
          <DataTableColumn
            key="failureReason"
            label="Failure Reason"
            property="failureReason"
            isSorted={sortObj?.sortColumn === 'failureReason'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.failureReason}
          />
        ),
        <DataTableColumn
          key="accountNumber"
          label="Account"
          property="accountNumber"
          isSorted={sortObj?.sortColumn === 'accountNumber'}
          sortable
          sortDirection={sortObj?.sortColumnDirection?.accountNumber}
        >
          <InventoryAccountCell />
        </DataTableColumn>,
        <DataTableColumn
          key="salesRepName"
          label="Sales Rep"
          property="salesRepName"
          isSorted={sortObj?.sortColumn === 'salesRepName'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.salesRepName}
        />,
        <DataTableColumn
          key="territoryNumber"
          label="Territory"
          property="territoryNumber"
          isSorted={sortObj?.sortColumn === 'territoryNumber'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.territoryNumber}
        />,
        type === 'Completed' && (
          <DataTableColumn
            key="erpOrderNumber"
            label="ERP Order"
            property="erpOrderNumber"
            isSorted={sortObj?.sortColumn === 'erpOrderNumber'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.erpOrderNumber}
          />
        ),
        <DataTableColumn
          key="createdDate"
          label="Created Date"
          property="createdDate"
          isSorted={sortObj?.sortColumn === 'createdDate'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.createdDate}
        />,
        <DataTableColumn
          key="returnExpiryDate"
          label="Return Expiration "
          property="returnExpiryDate"
          isSorted={sortObj?.sortColumn === 'returnExpiryDate'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.returnExpiryDate}
        />,
      ]}
    </DataTable>
  );
};

export default ReturnDataTable;
