/* eslint-disable */
/**
 * Module for inventory return filter.
 * @module src/components/inventory/invntoryReturns/inventoryReturnFilter
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import Button from '@salesforce/design-system-react/components/button';
import Combobox from '@salesforce/design-system-react/components/combobox';
import { FILTER_TRANSFER_STATUS, FILTER_TRANSFER_TYPE } from '../../../../util/constants';
import DatePicker from '../../../Shared/DatePicker';
import './index.scss';
import { GET_PRODUCT_GROUP } from '../../../../graphql/getProductGroups';
import { GET_ALL_SALES_REPS } from '../../../../graphql/getAllSalesReps';
import { GET_FILTERED_ACCOUNTS } from '../../../../graphql/getFilteredAccounts';
import { clearInventoryTransferFilters } from '../../../../store/ducks/inventoryTransferFilters';
import { getUserId } from '../../../../store/ducks/userId';
import AutocompleteSingleSelectPickerWithSearch from '../../../Shared/AutocompleteSingleSelectPickerWithSearch';
import { GET_PRIMARY_ACCOUNT } from '../../../../graphql/getPrimaryAccount';
import AutocompleteSingleSelectPicker from '../../../Shared/AutocompleteSingleSelectPicker';
import { NonSalesRep } from '../../../../util/constants';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { GET_SALES_REPS } from '../../../../graphql/getSalesReps';
import { GET_USER_REGION_AND_TERRITORIES } from '../../../../graphql/getUserRegionAndTerritories';
import SaveFilter from './SaveFilter';
import DeleteFilterPreset from './DeletePresetFilter';
import UPDATE_FILTER_PRESET from '../../../../graphql/mutations/updateFilterPreset';
import DELETE_FILTER_PRESET from '../../../../graphql/mutations/deleteFilterPreset';
import CREATE_FILTER_PRESET from '../../../../graphql/mutations/createFilterPreset';
import {
  getRegionTerritoryInfo,
  setRegionTerritoryInfo,
} from '../../../../store/ducks/userRegionAndTerritory';
interface SelectedData {
  id: string;
  label: string;
}
interface DynamicType {
  [key: string]: any;
}
interface FilterPreset {
  filterValues: SelectedFilters;
  filterExternalId?: string;
  filterName?: string;
}

interface SelectedFilters {
  status: SelectedData[];
  submitToDate: Date | string;
  submitFromDate: Date | string;
  requestedToDate: Date | string;
  requestedFromDate: Date | string;
  search: string;
  productGroup: any;
  salesRep: any;
}

type UserRegionAndTerritory = {
  id?: string;
  accountId: string;
  region: string;
  regionId: string;
  territory: string;
  territoryId: string;
  userId: string;
  __typename: string;
};

interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
  userId?: string;
}
interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  isRequest: boolean;
  handleFilterRequest?: (filter: DynamicType | null) => void;
  selectedFilter?: FilterPreset | DynamicType | null;
  currentTab?: number;
  handleToggleFilterEvent: () => void;
  handleClearFilter: (externalId: string) => void;
  handleFilterEvents: (filter: DynamicType | null) => void;
  handleEditFilter: (editEvent: boolean) => void;
  refetchFilterPresets: () => void;
  isEditFilter: boolean;
}
enum DetailTypes {
  salesRep = 'SalesRep',
}

const InventoryReturnFilter: FC<Props> = ({
  isOpen,
  toggleOpen,
  currentTab,
  handleFilterRequest,
  selectedFilter,
  handleToggleFilterEvent,
  handleClearFilter,
  handleFilterEvents,
  handleEditFilter,
  refetchFilterPresets,
  isEditFilter,
}) => {
  const userRegionTerritoryInfo: any = useSelector(getRegionTerritoryInfo);
  const filterValues = selectedFilter?.filterValues;
  const filterName = selectedFilter ? selectedFilter.filterName : '';
  const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;

  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [openReceive, setOpenReceive] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {}); // For Selected Value
  const [productGroup, { data: productGroupItems }] = useLazyQuery(GET_PRODUCT_GROUP);
  const productGroupList = productGroupItems?.getProductGroups;
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const userInfo = useSelector(getUserInfo);
  const [accountOptions, setAccountOptions] = useState<any>();
  const [territoryOptions, setTerritoryOptions] = useState<UserRegionAndTerritory[]>();
  const [regionOptions, setRegionOptions] = useState<UserRegionAndTerritory[]>();
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [createFilterPreset, { data: filterPresets }] = useMutation(CREATE_FILTER_PRESET);
  const [updateFilterPreset, { data: updatedfilterPresets }] = useMutation(UPDATE_FILTER_PRESET);
  const [deleteFilterPreset] = useMutation(DELETE_FILTER_PRESET);

  const getDate = useCallback((date: any): string => {
    const formattedSelectedDate = new Date(date);
    const finalDate = format(new Date(formattedSelectedDate), 'yyyy-MM-dd');
    return finalDate;
  }, []);
  const { data: filteredAccounts, loading: loadingAccounts } = useQuery(GET_FILTERED_ACCOUNTS, {
    variables: {
      userId,
    },
  });
  const [
    getPrimaryAccountItems,
    { data: primaryAccountItems, loading: loadingPrimaryAccounts },
  ] = useLazyQuery(GET_PRIMARY_ACCOUNT, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY
  const accountItemsData = primaryAccountItems?.getPrimaryAccount;

  //  const [getSalesRepsItems, { data: getAllSalesReps, loading: loadingSalesRep }] = useLazyQuery(
  //   GET_ALL_SALES_REPS
  // );
  const [getRegionAndTerritory, { data: regionAndTerritoryData }] = useLazyQuery(
    GET_USER_REGION_AND_TERRITORIES
  );
  const [getSalesRepsItems, { data: salesRepsItems, loading: loadingSalesRep }] = useLazyQuery(
    GET_SALES_REPS
  );
  const fetchItems = useCallback(() => {
    getSalesRepsItems();
  }, [getSalesRepsItems]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    productGroup();
    getPrimaryAccountItems({
      variables: {
        getAll: true,
        limit:500,
      },
    });
  }, [productGroup, getPrimaryAccountItems]);

  useEffect(() => {
    if (productGroupList && selectedFilters?.productGroup) {
      const record =
        productGroupList &&
        productGroupList.filter((rec: any) => rec.id === selectedFilters?.productGroup);
      const optionsWithLabel1 =
        record &&
        record.map((elem: any) => {
          // eslint-disable-next-line no-underscore-dangle
          return { ...elem, label: `${elem.id}` };
        });
      setFilterValue('productGroup', optionsWithLabel1);
    }
  }, [productGroupList]);

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate) {
      const utcDate = getDate(selectedDate);
      setFilterValue(name, utcDate);
    }
  };

  useEffect(() => {
    if (userInfo) {
      getRegionAndTerritory({
        variables: {
          userId: userInfo?.id,
        },
      });
    }
  }, [getRegionAndTerritory, userInfo]);

  const getUniqueRegionTerritory = (array: UserRegionAndTerritory[]): UserRegionAndTerritory[] => {
    const cleanedArray = array.filter((item: any) => item !== undefined && item !== null);
    return cleanedArray.filter(
      (item: UserRegionAndTerritory, index: number, self: UserRegionAndTerritory[]) =>
        index === self.findIndex((element: UserRegionAndTerritory) => item.id === element.id)
    );
  };

  const getRegionTerritoryOptions = () => {
    let tempRegions: any[] = [];
    let tempTerritories: any[] = [];
    if (selectedFilters && selectedFilters?.salesRep?.length && !selectedFilters?.region?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item.userId === selectedFilters.salesRep[0].id)
          tempRegions.push({ id: item.regionId, value: item.region });
      });
      userRegionTerritoryInfo?.map((item: any) => {
        if (item.userId === selectedFilters.salesRep[0].id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && selectedFilters?.region?.length && selectedFilters?.salesRep?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item?.userId === selectedFilters?.salesRep?.[0]?.id && item?.regionId === selectedFilters?.region?.[0]?.id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && selectedFilters?.region?.length && !selectedFilters?.salesRep?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item?.regionId === selectedFilters?.region?.[0]?.id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && !selectedFilters?.region?.length && !selectedFilters?.salesRep?.length) {
      tempRegions = userRegionTerritoryInfo?.map((item: any, index: number) => {
        return { id: item.regionId, value: item.region };
      });
      tempTerritories = userRegionTerritoryInfo?.map((item: any, index: number) => {
        return { id: item.territoryId, value: item.territory };
      });
    }
    setRegionOptions(getUniqueRegionTerritory(tempRegions));
    setTerritoryOptions(getUniqueRegionTerritory(tempTerritories));
  };

  useEffect(() => {
    if (userRegionTerritoryInfo) getRegionTerritoryOptions()
  }, [selectedFilters,userRegionTerritoryInfo]);

  useEffect(() => {
    if (regionAndTerritoryData) 
      dispatch(setRegionTerritoryInfo(regionAndTerritoryData?.getUserRegionAndTerritories));
  }, [regionAndTerritoryData]);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };
      setSelectedFilters(selectedFilterValues);
      if (key === 'salesRep'){
        const selectedFilterValues = 
        { ...selectedFilters,
          [key]: filterValuesObj,
          region: [],
          territory: [],
        };
        setSelectedFilters(selectedFilterValues);
      }
      if (key === 'region'){
        const selectedFilterValues = 
        { ...selectedFilters,
          [key]: filterValuesObj,
          territory: [],
        };
        setSelectedFilters(selectedFilterValues);
      }
      if (key === 'salesRep' && filterValuesObj?.length === 0) {
        const newObject = {
          ...selectedFilters,
          salesRep: [],
          region: [],
          territory: [],
        };
        setSelectedFilters(newObject);
      } else if (key === 'region' && filterValuesObj?.length === 0) {
        const newObject = {
          ...selectedFilters,
          region: [],
          territory: [],
        };
        setSelectedFilters(newObject);
      }
    },
    [selectedFilters]
  );

  const handleSelect = (data: any, name: string) => {
    const { selection } = data;
    if (selection) {
      setFilterValue(name, selection);
    } else {
      setFilterValue(name, data);
    }
  };

  const handleApplyFilter = (): void => {
    handleToggleFilterEvent();
    const filters = {
      ...selectedFilters,
      // recevingAccount: (selectedFilters as any)?.recevingAccount?.[0].label,
      productGroup: (selectedFilters as any)?.productGroup?.[0].id,
    };
    if (handleFilterRequest) {
      handleFilterRequest(filters);
    }
  };

  const resetFilters = (): void => {
    handleToggleFilterEvent();
    setSelectedFilters(null);
    dispatch(clearInventoryTransferFilters());
    if (handleFilterRequest) {
      handleFilterRequest(null);
    }
  };

  const optionsWithLabel =
    productGroupList &&
    productGroupList.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      return { ...elem, label: `${elem.value}` };
    });
  // eslint-disable-next-line no-underscore-dangle
  const optionsAccountsWithLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      if (['OPSList'].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.value}`, id: `${elem.value}` };
      }
    });
  const isUserOps = NonSalesRep?.find((rec: any) => rec?.value === userInfo?.personas)
    ? true
    : false;

  const optionsWithLabelAccount = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return {
        ...elem,
        label:
          elem?.isRepStockAccount === true && !isUserOps
            ? ` * ${elem.number} ${elem.value}`
            : ` ${elem.number} ${elem.value}`,
      };
    });

  useEffect(() => {
    if (primaryAccountItems?.getPrimaryAccount) {
      setAccountOptions(optionsWithLabelAccount(primaryAccountItems?.getPrimaryAccount));
    }
  }, [primaryAccountItems]);

  const getFiltersObject = (item: any) => {
    if (!Array.isArray(item)) {
      return [{ id: item, label: item }];
    }
    return item;
  };
  const FILTERDATA = FILTER_TRANSFER_STATUS[currentTab ? currentTab : 0];
  // const accounts = optionsAccountsWithLabel(filteredAccounts?.getAccounts);
  const optionsWithCustomLabel = (value: any): any[] =>
    value &&
    // eslint-disable-next-line consistent-return
    value.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.salesRep) {
        return { ...elem, label: `${elem.value}` };
      } else {
        return { ...elem, label: `${elem.value}` };
      }
    });
  const salesRepsDataOptions = optionsWithCustomLabel(salesRepsItems?.getSalesReps);

  const handleSaveModalVisible = (): void => {
    setSaveModalVisible(!saveModalVisible);
  };

  const handleDeleteModalVisible = useCallback((): void => {
    setDeleteModalVisible(!deleteModalVisible);
  }, [deleteModalVisible]);

  const handleCreateFilterPreset = (name: string): void => {
    let reducedFilterValues = {};
    if (selectedFilters) {
      Object.keys(selectedFilters).forEach((key: string) => {
        if (Array.isArray(selectedFilters[key])) {
          const values = selectedFilters[key].map((item: SelectedData) => {
            const itemCopy = { id: item.id, label: item.label };
            return { ...itemCopy };
          });
          reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
        } else if (key !== '__typename' && selectedFilters[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: selectedFilters[key] };
        }
      });
    }

    const filterData = { ...reducedFilterValues };

    if (!filterExternalId || filterExternalId === '') {
      createFilterPreset({
        variables: {
          userId: userInfo?.id,
          filterValues: filterData,
          name,
          type: 'Transfers',
        },
      });
    } else if (filterExternalId) {
      updateFilterPreset({
        variables: {
          externalId: filterExternalId,
          filterValues: filterData,
          name,
          transferType: 'Transfers',
        },
      });
    }
  };

  const handleDeleteFilterPreset = (): void => {
    deleteFilterPreset({
      variables: {
        externalId: filterExternalId,
      },
    }).then(response => {
      // if delete filter preset is successful
      const deleteUsageResponse =
        response.data &&
        response.data.deleteFilterPreset &&
        response.data.deleteFilterPreset.message === 'success';
      if (deleteUsageResponse) {
        handleDeleteModalVisible();
        handleToggleFilterEvent();
        handleClearFilter(filterExternalId);
        if (refetchFilterPresets) {
          handleEditFilter(false);
          refetchFilterPresets();
        }
      }
    });
  };

  useEffect(() => {
    if (
      updatedfilterPresets &&
      updatedfilterPresets.updateFilterPreset &&
      updatedfilterPresets.updateFilterPreset.message === 'success'
    ) {
      handleToggleFilterEvent();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
        resetFilters();
      }
    }
  }, [
    handleEditFilter,
    handleFilterEvents,
    handleToggleFilterEvent,
    refetchFilterPresets,
    selectedFilters,
    updatedfilterPresets,
  ]);

  useEffect(() => {
    if (
      filterPresets &&
      filterPresets.createFilterPreset &&
      filterPresets.createFilterPreset.message === 'success'
    ) {
      handleToggleFilterEvent();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
      }
    }
  }, [
    filterPresets,
    handleEditFilter,
    handleFilterEvents,
    handleToggleFilterEvent,
    refetchFilterPresets,
    selectedFilters,
  ]);

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={handleToggleFilterEvent}
        dismissOnClickOutside={false}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Save Filter"
            key="Save"
            onClick={handleSaveModalVisible}
            disabled={
              (selectedFilters?.submitToDate &&
                selectedFilters?.submitFromDate &&
                selectedFilters.submitToDate < selectedFilters.submitFromDate) ||
              (selectedFilters?.requestedToDate &&
                selectedFilters?.requestedFromDate &&
                selectedFilters.requestedToDate < selectedFilters.requestedFromDate)
            }
          />,
          <Button label="Reset Filters" key="Reset" onClick={resetFilters} />,
          <Button
            className="yellow-btn"
            label="Apply Filter"
            onClick={handleApplyFilter}
            disabled={
              (selectedFilters?.submitToDate &&
                selectedFilters?.submitFromDate &&
                selectedFilters.submitToDate < selectedFilters.submitFromDate) ||
              (selectedFilters?.requestedToDate &&
                selectedFilters?.requestedFromDate &&
                selectedFilters.requestedToDate < selectedFilters.requestedFromDate)
            }
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            {`${filterName && filterName !== ''
              ? `${isEditFilter ? 'Edit ' : ''}Filter Preset ${filterName}`
              : 'Filters'
              }`}
          </div>,

          isEditFilter && (
            <div className="slds-float_right" key="delete">
              <Button
                className="delete_button"
                label="Delete Preset"
                key="delete"
                onClick={handleDeleteModalVisible}
              />
            </div>
          ),
        ]}
      >
        {loadingPrimaryAccounts && <Spinner />}

        <section className="slds-p-around_medium slds-theme_shade">
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">Attributes</h1>
            <p className="selectbox_label">Status</p>
            <Combobox
              id="status"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpen(true);
                  setOpenType(false);
                },
                onRequestClose: (event: any): void => {
                  setOpen(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'status'),
              }}
              labels={{
                //   label: `Status`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={open}
              multiple
              placeholder="Select"
              options={FILTERDATA || []}
              selection={selectedFilters ? selectedFilters.status || [] : []}
              variant="readonly"
            />
            <p className="selectbox_label">Transfer Type</p>
            <Combobox
              id="transferType"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpenType(true);
                },
                onRequestClose: (event: any): void => {
                  setOpenType(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'transferType'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'transferType'),
              }}
              labels={{
                //   label: `Status`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openType}
              placeholder="Select"
              options={FILTER_TRANSFER_TYPE || []}
              selection={selectedFilters ? selectedFilters.transferType || [] : []}
              variant="readonly"
            />
            <p className="selectbox_label">Receiving Account</p>
            {/* <Combobox
              id="recevingAccount"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpenReceive(true);
                  setOpenType(false);
                },
                onRequestClose: (event: any): void => {
                  setOpenReceive(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'recevingAccount'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'recevingAccount'),
              }}
              labels={{
                //   label: `Receiving Account`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openReceive}
              placeholder="Select"
              options={salesRepsDataOptions || []}
              selection={selectedFilters ? selectedFilters.recevingAccount || [] : []}
              variant="readonly"
            /> */}

            <AutocompleteSingleSelectPicker
              items={accountOptions}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'recevingAccount');
              }}
              disabled={false}
              selectedItem={
                selectedFilters && selectedFilters.recevingAccount
                  ? getFiltersObject(selectedFilters?.recevingAccount) || []
                  : []
              }
              placeholder="Select an Option"
              label="Receiving Account"
              withAddMenuButton={false}
              fetchItems={(searchText: string): any => {
                getPrimaryAccountItems({
                  variables: {
                    searchText: searchText,
                    getAll: true,
                    limit:500,
                  },
                });
              }}
              filter
              name="recevingAccount"
              autoSelectValue={false}
            />
            {/* <p className="selectbox_label">Product Group</p> */}
            {/* <Combobox
              id="productGroup"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpenProduct(true);
                  setOpenReceive(false);
                  setOpenType(false);
                },
                onRequestClose: (event: any): void => {
                  setOpenProduct(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'productGroup'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'productGroup'),
              }}
              labels={{
                //   label: `Product Group`,

                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openProduct}
              placeholder="Select"
              options={optionsWithLabel || []}
              selection={selectedFilters ? selectedFilters.productGroup || [] : []}
              variant="readonly"
            /> */}
            <AutocompleteSingleSelectPicker
              items={optionsWithLabel}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'productGroup');
              }}
              disabled={false}
              selectedItem={
                selectedFilters && selectedFilters?.productGroup
                  ? getFiltersObject(selectedFilters?.productGroup?.[0]?.id) || []
                  : []
              }
              placeholder="Select Product Group"
              label="Product Group"
              labelpage="Product Group transfer"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="productGroup"
              autoSelectValue={false}
            />
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">DATE</h1>
            <div className="date-error">
              {(selectedFilters?.submitToDate &&
                selectedFilters?.submitFromDate &&
                selectedFilters.submitToDate < selectedFilters.submitFromDate) ||
                (selectedFilters?.requestedToDate &&
                  selectedFilters?.requestedFromDate &&
                  selectedFilters.requestedToDate < selectedFilters.requestedFromDate && (
                    <Pill
                      labels={{
                        label: 'Error: To Date must be greater than or equal to From Date',
                      }}
                      hasError
                      icon={
                        <Icon
                          title="Error"
                          category="utility"
                          name="warning"
                          className="slds-icon-text-error"
                        />
                      }
                    />
                  ))}
            </div>
            {
              <>
                <div className="filter_date_container">
                  <p className="selectbox_label">Requested Date</p>
                  <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="From"
                        handleChangeDate={(data: any): void =>
                          handleChangeDate(data, 'requestedFromDate')
                        }
                        disabled={false}
                        isFilterDate
                        value={selectedFilters ? selectedFilters.requestedFromDate : null}
                      />
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <span>-</span>
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="To"
                        handleChangeDate={(data: any): void =>
                          handleChangeDate(data, 'requestedToDate')
                        }
                        disabled={false}
                        isFilterDate
                        value={selectedFilters ? selectedFilters.requestedToDate : null}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">OTHER</h1>
            <AutocompleteSingleSelectPicker
              items={salesRepsDataOptions}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'salesRep');
              }}
              disabled={false}
              selectedItem={selectedFilters ? selectedFilters.salesRep || [] : []}
              placeholder="Sales Rep"
              label="Sales Rep"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="salesRep"
              autoSelectValue={false}
            />
            <AutocompleteSingleSelectPicker
              items={regionOptions || []}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'region');
              }}
              disabled={false}
              selectedItem={selectedFilters ? selectedFilters.region || [] : []}
              placeholder="Select Region"
              label="Region"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="region"
              autoSelectValue={false}
            />
            <AutocompleteSingleSelectPicker
              items={territoryOptions || []}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'territory');
              }}
              disabled={false}
              selectedItem={selectedFilters ? selectedFilters.territory || [] : []}
              placeholder="Select Territory"
              label="Territory"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="territory"
              autoSelectValue={false}
            />
          </div>
          {saveModalVisible && (
            <SaveFilter
              handleSaveModalVisible={handleSaveModalVisible}
              saveModalVisible={saveModalVisible}
              handleCreateFilterPreset={handleCreateFilterPreset}
              selectedFilterName={filterName}
            />
          )}

          {deleteModalVisible && (
            <DeleteFilterPreset
              deleteModalVisible={deleteModalVisible}
              handleDeleteModalVisible={handleDeleteModalVisible}
              handleDeleteFilterPreset={handleDeleteFilterPreset}
            />
          )}
        </section>
      </Modal>
    </IconSettings>
  );
};

/** Custom component for Inventory Return Filter */
export default InventoryReturnFilter;
