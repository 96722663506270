/*
 * Filtered Presets GraphQL specification
 * @module src/graphql/getFilterPresets
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Filter presets GraphQL API query */
export const GET_FILTER_PRESETS = gql`
  query getFilterPresets($userId: String, $type: String) {
    getFilterPresets(userId: $userId, type: $type) {
      filterName
      filterExternalId
      filterValues {
        status {
          id
          label
          __typename
        }
        salesReps {
          id
          label
          __typename
        }
        salesRep {
          id
          label
          __typename
        }
        type {
          id
          label
          __typename
        }
        coveringReps {
          id
          label
          __typename
        }
        account {
          id
          label
          __typename
        }
        branch {
          id
          label
          __typename
        }
        franchise {
          id
          label
          __typename
        }
        surgeon {
          id
          label
          __typename
        }
        procedureDetail {
          id
          label
          __typename
        }
        eventType {
          id
          label
          __typename
        }
        PO {
          id
          label
          __typename
        }
        startDate
        endDate
        usage
        assignedEvents
        myEvents
        teamEvents
        accountType
        countType {
          id
          label
          __typename
        }
        productGroup {
          id
          label
          __typename
        }
        assignedTo {
          id
          label
          __typename
        }
        orderType {
          id
          label
          __typename
        }
        region {
          id
          label
          __typename
        }
        territory {
          id
          label
          __typename
        }
        recordType {
          id
          label
          __typename
        }
        threePL {
          id
          label
          __typename
        }

        surgeryStartDate
        surgeryEndDate
        createdFromDate
        createdEndDate
        submittedDate
        requestedFromDate
        requestedToDate
        returnExpiryFromDate
        returnExpiryToDate
        shipFromDate
        shipToDate
        c_status
        c_salesReps
        c_accounts
        __typename
      }
      __typename
    }
  }
`;
