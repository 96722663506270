/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Module for header on InventoryRequestDetailCatalog Page
 * @module src/SurgicalDetail/InventoryRequestDetailCatalog
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC, ReactNode, useCallback, useState, useEffect } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import CartComponent from './cartComponent';
import './index.scss';
import InventoryRequestAddItems from '../InventoryRequestAddItems';
import InventoryApproveRejectDataCell from '../../InventoryQueue/DataTableCells/InventoryApproveRejectDataCell';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import {
  CYCLE_COUNT,
  MAX_QUANTITY,
  NOTIFY_TYPE,
  WORKFLOW_ACTION_OPTIONS,
  WORKFLOW_ACTION,
  OPSUSERS,
} from '../../../../util/constants';
import {
  getEventIcon,
  getHoldIcon,
  getSvgIcon,
  calculateActualQuantitity,
} from '../../../../util/utilityFunctions';
import RESUBMIT_ORDER_DETAILS from '../../../../graphql/mutations/resubmitOrderDetails';
import CREATE_UPDATE_USAGE from '../../../../graphql/mutations/createdUpdateUsage';
import SnackBar from '../../../Shared/SnackBar';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import debounced from '../../../../util/debounced';
import { GET_INVENTORY_REQUEST_DETAILS } from '../../../../graphql/getInventoryDetails';
import { GET_ORDER_DETAILS } from '../../../../graphql/getOrderDetails';

const costVisibilityStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  position: 'absolute',
  top: '84px',
  right: '8px',
} as React.CSSProperties;

const costVisibilityTableStyle = {
  paddingTop: '32px',
} as React.CSSProperties;

interface Props {
  inventoryDetail?: any;
  isEventInventoryFlow?: boolean;
  viewOnly?: boolean;
  refetch: () => void;
  refetchOnAdd?: () => void;
  visualIndicator: string;
  type: string;
  hasEventInventory?: any;
  eventInventoryExternalId?: any;
  externalId?: any;
  tabConfig?: any;
  handleLineItemActions?: any;
}

interface DynamicValue {
  [key: string]: any;
}

interface SelectedData {
  id: string;
  value: string;
  __typename?: string;
}

const InventoryRequestTable: FC<Props> = ({
  refetch,
  inventoryDetail,
  isEventInventoryFlow,
  visualIndicator,
  type,
  viewOnly,
  hasEventInventory,
  eventInventoryExternalId,
  externalId,
  tabConfig,
  handleLineItemActions,
  refetchOnAdd,
}) => {
  const userInfo = useSelector(getUserInfo);
  const { open, notification, openSnackBar } = useSnackBar();
  const [searchValue, setSearchText] = useState('');
  const [isLoading, setLoader] = useState(false);
  const [showSearchCatalogModal, setShowSearchCatalogModal] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const [reSubmitInventory] = useMutation(RESUBMIT_ORDER_DETAILS);
  const [createUpdateUsage, { data: eventUsageMutation }] = useMutation(CREATE_UPDATE_USAGE);
  const isManualIR =
    type && inventoryDetail?.eventStatus && inventoryDetail?.caseExternalId
      ? type === 'Open' &&
        inventoryDetail?.eventStatus === 'Approved' &&
        inventoryDetail?.caseExternalId
      : false;
  const { data: getInventoryRequestDetails, refetch: refetchEventDetails, loading } = useQuery(
    GET_INVENTORY_REQUEST_DETAILS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        externalId:
          isEventInventoryFlow || hasEventInventory ? eventInventoryExternalId : externalId,
        searchText: searchValue,
      },
    }
  );
  const {
    data: getOrderDetails,
    refetch: refetchProcessedEventDetails,
    loading: processedLoading,
  } = useQuery(GET_ORDER_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: externalId,
      orderType: 'INVENTORY_REQUEST',
      searchText: searchValue,
    },
  });
  const onHandleCloseSearchCatalogModal = (): void => {
    setShowSearchCatalogModal(false);
    if (refetchOnAdd && type === 'Failed') {
      refetchOnAdd();
    } else if (refetch) {
      refetch();
    }
  };
  const iconThreshold = getSvgIcon('threshold');
  const trialPartInd = getSvgIcon('trialPart');

  const costVisibilityForRSM = userInfo?.personas === 'Sales Manager';
  const is3PL = userInfo?.personas === '3PL Ops';

  let tableClass = 'ir-cols';

  if (costVisibilityForRSM) {
    tableClass = 'ir-cost-cols';
  } else if (tabConfig.includes('actualUom') && tabConfig.includes('actualQty') && !is3PL) {
    tableClass = 'ir--SM-cols';
  } else if (tabConfig.includes('actualUom') || tabConfig.includes('actualQty')) {
    tableClass = 'ir--l-cols';
  }

  const isUserOps = !!userInfo?.personas?.toLowerCase().includes('branch op');

  const isUserOpsType = !!OPSUSERS?.find((rec: any) => rec?.value === userInfo?.personas);
  const [quantityCount, setCartCount] = useState(
    inventoryDetail?.lineItems?.reduce(
      (accumulator: any, current: { quantity: any }) => accumulator + Number(current.quantity),
      0
    )
  );
  useEffect(() => {
    const total = items?.reduce(
      (accumulator: any, current: any) => accumulator + Number(current.counterValue),
      0
    );
    setCartCount(total);
  }, [items]);

  useEffect(() => {
    const lineItemsData = [...inventoryDetail.lineItems];
    const finalLineItems = lineItemsData.map((item: any) => {
      return {
        ...item,
        userworkflowAction: null,
      };
    });
    setItems(finalLineItems);
  }, [inventoryDetail?.lineItems]);

  useEffect(() => {
    if (type === 'Failed') {
      const updateList = inventoryDetail?.lineItems.map((record: any) => {
        return {
          ...record,
          userworkflowAction: null,
          counterValue: Number(record.quantity),
        };
      });
      setItems(updateList);
    } else {
      const updateList = inventoryDetail?.lineItems.map((record: any) => {
        return {
          ...record,
          changed: false,
          counterValue: Number(record.quantity),
        };
      });
      setItems(updateList);
    }
    // setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryDetail?.lineItems, type]);

  useEffect(() => {
    if (type === 'Failed' || type === 'Processed') {
      const updateList = getOrderDetails?.getOrderDetails?.lineItems.map((record: any) => {
        return {
          ...record,
          userworkflowAction: null,
          counterValue: Number(record.quantity),
        };
      });
      setItems(updateList);
    } else {
      const updateList = getInventoryRequestDetails?.getInventoryRequestDetails?.lineItems.map(
        (record: any) => {
          return {
            ...record,
            changed: false,
            counterValue: Number(record.quantity),
          };
        }
      );
      setItems(updateList);
    }
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInventoryRequestDetails, getOrderDetails, type]);

  const getUoM = (name: string, packContent: string): string => {
    let actualUOM = name;
    if (packContent && Number(packContent) > 1) {
      actualUOM += `/ ${packContent}`;
    }
    return actualUOM;
  };
  const holdIconPath = getHoldIcon();
  const formatLineItemToReSubmit = (lineItemsArray: any): any => {
    const finalLineItems: Array<any> = [];
    lineItemsArray.map(
      (item: {
        productId: any;
        productNumber: any;
        quantity: any;
        actualQuantity: any;
        externalId: any;
        lotNumber: any;
        sfid: any;
        unitPrice: any;
        caseUsageExternalId: any;
        priceBookEntryId: any;
        salesUnitOfMeasure: any;
        deliveredDate: any;
        caseUsageId: any;
        // eslint-disable-next-line array-callback-return
      }): any => {
        finalLineItems.push({
          caseUsageId: item.caseUsageId,
          caseUsageExternalId: item.caseUsageExternalId,
          priceBookEntryId: item.priceBookEntryId,
          salesUnitOfMeasure: item.salesUnitOfMeasure,
          deliveredDate: item.deliveredDate || null,
          productId: item.productId,
          productNumber: item.productNumber,
          quantity: item.quantity,
          actualQuantity: item.actualQuantity,
          lotNumber: item.lotNumber || '',
          externalId: item.externalId || null,
          sfid: item.sfid || '',
          unitPrice: item.unitPrice || '',
          status: type === 'Review' ? 'Requested' : null,
        });
      }
    );
    return finalLineItems;
  };

  const handleAproveAll = (): void => {
    const lineItemsData = [...items];
    const finalLineItems = lineItemsData.map((item: any) => {
      const approveItem = WORKFLOW_ACTION_OPTIONS.filter(
        (i: any) => i.value === WORKFLOW_ACTION.APPROVE
      );
      return {
        ...item,
        userworkflowAction:
          item.workflowAction || item.workflowAction === null ? approveItem : null,
      };
    });
    setItems(finalLineItems);
    handleLineItemActions(finalLineItems);
  };

  const handleRejectAll = (): void => {
    const lineItemsData = [...items];
    const finalLineItems = lineItemsData.map((item: any) => {
      const rejectItem = WORKFLOW_ACTION_OPTIONS.filter(
        (i: any) => i.value === WORKFLOW_ACTION.REJECT
      );
      return {
        ...item,
        userworkflowAction: item.workflowAction || item.workflowAction === null ? rejectItem : null,
      };
    });
    setItems(finalLineItems);
    handleLineItemActions(finalLineItems);
  };

  const handleUserAction = (changeItem: any): void => {
    const lineItemsData = [...items];
    const finalLineItems = lineItemsData.map((item: any) => {
      const { usageExternalId, userworkflowAction } = item;
      return {
        ...item,
        userworkflowAction:
          changeItem.usageExternalId === usageExternalId
            ? changeItem.userworkflowAction
            : userworkflowAction,
      };
    });
    setItems(finalLineItems);
    handleLineItemActions(finalLineItems);
  };

  const handleUpdates = (): void => {
    // setLoader(true);
    if (type === 'Failed') {
      // resubmitInventory();
      const payload: Array<any> = [];
      items.forEach((product: any): void => {
        if (product.changed) {
          const actualQuantity = calculateActualQuantitity(product, product.counterValue);
          payload.push({
            sfid: product.sfid,
            productId: product.productId,
            quantity: product.counterValue.toString(),
            actualQuantity: actualQuantity.toString(),
            productNumber: product.productNumber,
            priceBookEntryId: product.priceBookEntryId,
            salesUnitOfMeasure: product.salesUnitOfMeasure,
            deliveredDate: null,
            ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
            ...(product.caseUsageId && { caseUsageId: product.caseUsageId }),
            ...(product.caseUsageExternalId && {
              caseUsageExternalId: product.caseUsageExternalId,
            }),
          });
        }
      });
      const mutation = {
        id: inventoryDetail.id,
        caseId: inventoryDetail.caseId,
        caseExternalId: inventoryDetail.caseExternalId,
        externalId: inventoryDetail.externalId,
        shippingId: inventoryDetail.shippingId,
        accountId: inventoryDetail.accountId,
        billingId: inventoryDetail.billingId,
        shipMethod: inventoryDetail.shipMethod,
        serviceLevel: inventoryDetail.serviceLevel,
        salesRepId: inventoryDetail.salesRepId,
        receivingRepId: inventoryDetail.receivingRepId,
        fromSubInventoryCode: inventoryDetail.fromSubInventoryCode,
        toSubInventoryCode: inventoryDetail.toSubInventoryCode,
        salesOrganization: inventoryDetail.salesOrganization,
        needByDate: inventoryDetail.needByDate,
        requestedFrom: 'InventoryRequest',
        submitType: 'Update',
        // comments: userComment,
        lineItems: formatLineItemToReSubmit(payload),
      };
      setLoader(true);
      reSubmitInventory({
        variables: mutation,
      }).then((res: any) => {
        // refetch();
        if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
          setTimeout(() => {
            openSnackBar(
              NOTIFY_TYPE.SUCCESS,
              'line items for Inventory Request resubmit updated successfully'
            );
          }, 2000);
          setLoader(false);
          // if (refetch) {
          //   refetch();
          // }
        }
      });
    } else {
      const payload: Array<any> = [];
      // eslint-disable-next-line no-unused-expressions
      items?.forEach((product: any): void => {
        if (product.changed) {
          const actualQuantity = calculateActualQuantitity(product, product.counterValue);
          payload.push({
            productId: product.productId,
            quantity: product.counterValue.toString(),
            productNumber: product.productNumber,
            actualQuantity: actualQuantity.toString(),
            workflowAction: product?.workflowAction || null,
            productCost: product?.productCost || null,
            ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
          });
        }
      });

      const mutation = {
        caseId: inventoryDetail.caseId,
        caseExternalId: inventoryDetail.caseExternalId,
        accountId: inventoryDetail.accountId,
        branchId: inventoryDetail.branchId,
        salesRepId: inventoryDetail.salesRepId,
        shipToId: inventoryDetail.shippingId,
        serviceLevel: inventoryDetail.serviceLevel,
        needByDate: inventoryDetail.needByDate,
        deliveryContractEmail: inventoryDetail.deliveryContractEmail,
        deliveryContractInstructions: inventoryDetail.deliveryContractInstructions,
        deliveryContractName: inventoryDetail.deliveryContractName,
        deliveryContractPhone: inventoryDetail.deliveryContractPhone,
        dockAvailable: inventoryDetail.dockAvailable,
        isShippingDetails: inventoryDetail.isShippingDetails,
        liftGateNeeded: inventoryDetail.liftGateNeeded,
        shortTruckOnly: inventoryDetail.shortTruckOnly,
        requestedFrom: 'Inventory',
        usageParts: payload,
      };
      setLoader(true);
      createUpdateUsage({
        variables: mutation,
      })
        .then((res: any) => {
          if (res && res.data && res.data.createUpdateUsage.message === 'success') {
            setTimeout(() => {
              openSnackBar(
                NOTIFY_TYPE.SUCCESS,
                'line items for Inventory Request updated successfully'
              );
              refetch();
              setLoader(false);
            }, 2000);
            refetch();
          }
        })
        .catch(() => {
          setLoader(false);
          setTimeout(() => {
            openSnackBar(
              NOTIFY_TYPE.ERROR,
              'Error:  We are having issue updating the line items for Inventory Request'
            );
          }, 2000);
        });
    }
  };

  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: SelectedData) => item.value);
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleFilter = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
    }
  };

  const handleAddParts = (): void => {
    refetch();
    setShowSearchCatalogModal(true);
  };

  const handleDragOnHand = (result: any): void => {
    if (!result.destination) {
      return;
    }
    const listItems = [...inventoryDetail.lineItems];
    const reorderedItem = listItems.splice(result.source.index, 1);
    listItems.splice(result.destination.index, 0, ...reorderedItem);
  };
  const updateProducts: Array<any> = items;
  const handleCount = (event: any, data: { value: string; number: number }, product: any): void => {
    const updatedList = [...items];
    updatedList.forEach((element: any) => {
      if (
        product.productId === element.productId &&
        product.usageExternalId === element.usageExternalId
      ) {
        const packContent =
          element?.salesUnitOfMeasure === 'PK' || element?.salesUnitOfMeasure === 'BX';

        const packOfContentCount = product?.packContent > 0 ? product?.packContent || 1 : 1;

        if (data.value !== '' && data.number > 0) {
          element.counterValue = data.number < MAX_QUANTITY ? data.number : MAX_QUANTITY;
          element.actualQuantity = calculateActualQuantitity(data, element?.salesUnitOfMeasure);
          element.changed = true;
        } else {
          element.counterValue = '';
          element.changed = false;
        }
      }
    });
    setItems(updatedList);
    handleUpdates();
  };
  const handleCountalert = (
    event: any,
    data: { value: string; number: number },
    product: any
  ): void => {
    if (product?.productOnHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCount(event, data, product);
  };
  const handleDelete = (product: any): void => {
    // empty/clear quantity, it will delete ib Backend
    let counter = 0;
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (i.productId === product.productId && i.usageExternalId === product.usageExternalId) {
        i.counterValue = 0;
        i.changed = true;
        i.deleted = true;
      }
      counter += i.counterValue;
    });
    setItems(listItems || []);
    handleUpdates();
  };

  const handleUserWorkflowAction = (changeItem: any): void => {
    const listItemsF = [...items];
    listItemsF.forEach((e: any) => {
      if (
        e.productId === changeItem.productId &&
        e.caseUsageExternalId === changeItem.caseUsageExternalId
      ) {
        e.quantity = 0;
        e.actualQuantity = 0;
        e.counterValue = 0;
        e.changed = true;
        e.deleted = true;
      }
    });
    setItems(listItemsF);
    handleUpdates();
  };

  const iconPath = getEventIcon(CYCLE_COUNT);

  const setSearch = (value: string): void => {
    setLoader(true);
    setSearchText(value);
    if (type === 'Processed' || type === 'Failed') {
      refetchProcessedEventDetails();
    } else refetchEventDetails();
  };

  const debouncedFetchRequestedItems = useCallback(debounced(500, setSearch), [
    debounced,
    setSearch,
  ]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      debouncedFetchRequestedItems(value);
    },
    [debouncedFetchRequestedItems]
  );

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <div className="slds-col_padded" style={{ width: '300px' }}>
          <Input
            iconRight={
              <InputIcon
                assistiveText={{
                  icon: 'Search',
                }}
                name="search"
                category="utility"
              />
            }
            id="search-case-id"
            placeholder="Search by Part"
            onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
          />
        </div>

        <div style={{ marginRight: '10px' }}>
          <CartComponent
            count={quantityCount}
            color="grey"
            textColor="white"
            cartTitle="Requested"
          />
        </div>

        <Button
          iconCategory="utility"
          iconSize="small"
          className="slds-text-color_default"
          label="Add"
          iconName="add"
          iconPosition="left"
          disabled={
            (type !== 'Review' && visualIndicator.toLowerCase() === 'requested') ||
            (type !== 'Review' && visualIndicator.toLowerCase() === 'approved') ||
            visualIndicator.toLowerCase() === 'cancelled' ||
            type === 'Processed' ||
            !!viewOnly ||
            isManualIR ||
            (type === 'Failed' && !isUserOps)
          }
          style={{ fontWeight: 'bold' }}
          onClick={handleAddParts}
        />
        {(costVisibilityForRSM || type === 'Assign' || type === 'Review') && !is3PL ? (
          <div className="slds-text-title_bold" style={costVisibilityStyle}>
            <p>{`Total Cost: ${inventoryDetail?.totalCost ? inventoryDetail?.totalCost : '-'}`}</p>
          </div>
        ) : (
          <></>
        )}
      </ButtonGroup>
    </PageHeaderControl>
  );
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {(isLoading || !items) && <Spinner />}
      <PageHeader
        className="events_header"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        onRenderActions={headerActions}
        variant="record-home"
        title="Inventory"
      />
      {showSearchCatalogModal && (
        <InventoryRequestAddItems
          isOpen={showSearchCatalogModal}
          refetch={refetch}
          handleClose={onHandleCloseSearchCatalogModal}
          viewOnly={!!viewOnly}
          inventoryDetail={inventoryDetail}
          isEventInventoryFlow={isEventInventoryFlow}
          selectedFilter={selectedFilter}
          handleFilter={handleFilter}
          type={type}
          quantityCount={quantityCount}
        />
      )}
      {type !== 'Review' && type !== 'Assign' ? (
        <div className="order-list-container" style={costVisibilityTableStyle}>
          <DragDropContext onDragEnd={handleDragOnHand}>
            <Droppable droppableId="items">
              {(provided: any): any => (
                <div className="inventory-table-container">
                  <div
                    className={`parts-table-header ${tableClass} `}
                    style={{
                      fontFamily: '"SFProText-Bold", "SF pro text Bold", "SF pro text", sans-serif',
                      color: '#000000',
                    }}
                  >
                    {tabConfig.includes('part') && <p>Part</p>}
                    {tabConfig.includes('status') && (
                      <p style={{ textAlign: 'left', marginLeft: '25px' }}>Status</p>
                    )}
                    {tabConfig.includes('requestedQuantity') && (
                      <p style={{ textAlign: 'center' }}>Requested Qty</p>
                    )}
                    {tabConfig.includes('actualUom') && (
                      <p style={{ textAlign: 'center' }}>Actual UOM</p>
                    )}
                    {tabConfig.includes('actualQty') && (
                      <p style={{ textAlign: 'center' }}>Actual Qty</p>
                    )}
                    {costVisibilityForRSM && <p style={{ textAlign: 'center' }}>Cost</p>}
                    <p style={{ textAlign: 'center' }}>Action</p>
                  </div>
                  {items?.length > 0 &&
                    items.map((lineItem: any) => {
                      // eslint-disable-next-line no-nested-ternary
                      return !lineItem.deleted && inventoryDetail.eventStatus ? (
                        <div
                          className={`parts-table-header ${tableClass} `}
                          style={{
                            background: 'white',
                            fontWeight: 'unset',
                            lineHeight: 1,
                          }}
                        >
                          {tabConfig.includes('part') && (
                            <div style={{ paddingLeft: '5px', textAlign: 'left' }}>
                              {`${lineItem.productNo} - ${lineItem.productdescription}`}
                              {lineItem.isAlreadyRequested && (
                                <img
                                  className="slds-tooltip-trigger"
                                  title="Trial Part"
                                  src={trialPartInd}
                                  alt="Trial Part"
                                  style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                                />
                              )}
                            </div>
                          )}
                          <div
                            style={{
                              paddingLeft: '5px',
                              textAlign: 'center',
                              alignItems: 'center',
                              display: 'inline-flex',
                            }}
                          >
                            <div>
                              <EventStatusBadge
                                status={
                                  type === 'Processed' || type === 'Failed'
                                    ? lineItem.status
                                    : lineItem.lineVisualIndicator || ''
                                }
                              />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                              {lineItem.productOnHold ? (
                                <img
                                  src={holdIconPath}
                                  className="logo-image"
                                  alt="company"
                                  style={{
                                    marginRight: '-26%',
                                    width: '16px',
                                    height: '17px',
                                    position: 'relative',
                                    top: '-49%',
                                  }}
                                />
                              ) : (
                                ''
                              )}
                              {lineItem.lineThresholdQuantityIndicator && (
                                <div style={{ marginLeft: '5px' }}>
                                  <img
                                    className="slds-tooltip-trigger"
                                    title="Threshold Exceed"
                                    src={iconThreshold}
                                    alt="threshold"
                                    style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                            <div className="row-control">
                              <Input
                                id="counter-input-3"
                                minValue={1}
                                maxValue={MAX_QUANTITY}
                                disabled={
                                  (!(isUserOpsType && type === 'Failed') &&
                                    (lineItem?.lineVisualIndicator?.toLowerCase() === 'approved' ||
                                      (lineItem?.lineVisualIndicator?.toLowerCase() === 'manual' &&
                                        type === 'Open') ||
                                      ['requested', 'approved', 'cancelled'].includes(
                                        visualIndicator.toLowerCase()
                                      ) ||
                                      type === 'Processed' ||
                                      (type === 'Failed' && !isUserOps))) ||
                                  viewOnly
                                }
                                onChange={(
                                  event: any,
                                  data: { value: string; number: number }
                                ): void => {
                                  handleCountalert(event, data, lineItem);
                                }}
                                value={lineItem.counterValue}
                                variant="counter"
                              />
                            </div>
                          </div>
                          {tabConfig.includes('actualUom') ? (
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              {getUoM(lineItem.salesUnitOfMeasure, lineItem.packContent)}
                            </div>
                          ) : (
                            ''
                          )}
                          {tabConfig.includes('actualQty') && (
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              {Number(lineItem.actualQuantity) > 0
                                ? lineItem.actualQuantity
                                : calculateActualQuantitity(lineItem, lineItem?.counterValue) || ''}
                            </div>
                          )}
                          {costVisibilityForRSM && (
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              <p>{lineItem.cost ? lineItem.cost : '0.00'}</p>
                            </div>
                          )}
                          <div
                            className="vertical-align-middle"
                            style={{ paddingLeft: '5px', textAlign: 'center' }}
                          >
                            <Button
                              disabled={
                                (lineItem &&
                                  lineItem.lineVisualIndicator &&
                                  lineItem.lineVisualIndicator.toLowerCase() === 'approved' &&
                                  type === 'Open') ||
                                visualIndicator.toLowerCase() === 'requested' ||
                                visualIndicator.toLowerCase() === 'approved' ||
                                visualIndicator.toLowerCase() === 'cancelled' ||
                                lineItem?.lineVisualIndicator?.toLowerCase() === 'manual' ||
                                type === 'Processed' ||
                                (type === 'Failed' && !isUserOps)
                              }
                              iconCategory="utility"
                              iconName="delete"
                              iconSize="large"
                              iconVariant="bare"
                              variant="icon"
                              onClick={(): void => {
                                // eslint-disable-next-line no-unused-expressions
                                type === 'Failed'
                                  ? handleUserWorkflowAction(lineItem)
                                  : handleDelete(lineItem);
                              }}
                            />
                          </div>
                        </div>
                      ) : !lineItem.deleted && inventoryDetail.status ? (
                        // eslint-disable-next-line react/jsx-indent
                        <div
                          className={`parts-table-header ${tableClass} `}
                          style={{
                            background: 'white',
                            fontWeight: 'unset',
                            lineHeight: 1,
                          }}
                        >
                          {tabConfig.includes('part') && (
                            <div style={{ paddingLeft: '5px', textAlign: 'left' }}>
                              {`${lineItem.productNo} - ${lineItem.productdescription}`}
                              {lineItem.isAlreadyRequested && (
                                <img
                                  className="slds-tooltip-trigger"
                                  title="Trial Part"
                                  src={trialPartInd}
                                  alt="Trial Part"
                                  style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                                />
                              )}
                            </div>
                          )}
                          <div
                            style={{
                              paddingLeft: '5px',
                              textAlign: 'center',
                              alignItems: 'center',
                              display: 'inline-flex',
                            }}
                          >
                            <div>
                              <EventStatusBadge
                                status={
                                  type === 'Processed' || type === 'Failed'
                                    ? lineItem.status
                                    : lineItem.lineVisualIndicator || ''
                                }
                              />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                              {lineItem.productOnHold ? (
                                <img
                                  src={holdIconPath}
                                  className="logo-image"
                                  alt="company"
                                  style={{
                                    marginRight: '-26%',
                                    width: '16px',
                                    height: '17px',
                                    position: 'relative',
                                    top: '-49%',
                                  }}
                                />
                              ) : (
                                ''
                              )}
                              {lineItem.lineThresholdQuantityIndicator && (
                                <div style={{ marginLeft: '5px' }}>
                                  <img
                                    className="slds-tooltip-trigger"
                                    title="Threshold Exceed"
                                    src={iconThreshold}
                                    alt="threshold"
                                    style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                            <div className="row-control">
                              <Input
                                id="counter-input-3"
                                minValue={1}
                                maxValue={MAX_QUANTITY}
                                disabled={
                                  (!(isUserOpsType && type === 'Failed') &&
                                    (lineItem?.lineVisualIndicator?.toLowerCase() === 'approved' ||
                                      (lineItem?.lineVisualIndicator?.toLowerCase() === 'manual' &&
                                        type === 'Open') ||
                                      ['requested', 'approved', 'cancelled'].includes(
                                        visualIndicator.toLowerCase()
                                      ) ||
                                      type === 'Processed' ||
                                      (type === 'Failed' && !isUserOps))) ||
                                  viewOnly
                                }
                                onChange={(
                                  event: any,
                                  data: { value: string; number: number }
                                ): void => {
                                  handleCountalert(event, data, lineItem);
                                }}
                                value={lineItem.counterValue}
                                variant="counter"
                              />
                            </div>
                          </div>

                          {tabConfig.includes('actualUom') ? (
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              {getUoM(lineItem.salesUnitOfMeasure, lineItem.packContent)}
                            </div>
                          ) : (
                            ''
                          )}
                          {tabConfig.includes('actualQty') && (
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              {Number(lineItem.actualQuantity) > 0
                                ? lineItem.actualQuantity
                                : calculateActualQuantitity(lineItem, lineItem?.counterValue) || ''}
                            </div>
                          )}
                          {costVisibilityForRSM && (
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              <p>{lineItem.cost ? parseFloat(lineItem.cost).toFixed(2) : '0.00'}</p>
                            </div>
                          )}
                          <div
                            className="vertical-align-middle"
                            style={{ paddingLeft: '5px', textAlign: 'center' }}
                          >
                            <Button
                              disabled={
                                (lineItem &&
                                  lineItem.lineVisualIndicator &&
                                  lineItem.lineVisualIndicator.toLowerCase() === 'approved' &&
                                  type === 'Open') ||
                                visualIndicator.toLowerCase() === 'requested' ||
                                visualIndicator.toLowerCase() === 'approved' ||
                                visualIndicator.toLowerCase() === 'cancelled' ||
                                type === 'Processed' ||
                                viewOnly ||
                                (type === 'Failed' && !isUserOps)
                              }
                              iconCategory="utility"
                              iconName="delete"
                              iconSize="large"
                              iconVariant="bare"
                              variant="icon"
                              onClick={(): void => {
                                // eslint-disable-next-line no-unused-expressions
                                type === 'Failed'
                                  ? handleUserWorkflowAction(lineItem)
                                  : handleDelete(lineItem);
                              }}
                            />
                          </div>
                        </div>
                      ) : null;
                    })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <div className="order-list-container" style={costVisibilityTableStyle}>
          <DragDropContext onDragEnd={handleDragOnHand}>
            <Droppable droppableId="items">
              {(provided: any): any => (
                <div className="inventory-table-container-review">
                  <div
                    className={`parts-table-header ${tableClass} `}
                    style={{
                      fontFamily: '"SFProText-Bold", "SF pro text Bold", "SF pro text", sans-serif',
                      color: '#000000',
                    }}
                  >
                    {tabConfig.includes('part') && <p>Part</p>}
                    {tabConfig.includes('status') && (
                      <p style={{ textAlign: 'left', marginLeft: '25px' }}>Status</p>
                    )}
                    {tabConfig.includes('requestedQuantity') && (
                      <p style={{ textAlign: 'center' }}>Requested Qty</p>
                    )}
                    {tabConfig.includes('actualUom') && (
                      <p style={{ textAlign: 'center' }}>Actual UOM</p>
                    )}
                    {tabConfig.includes('actualQty') && (
                      <p style={{ textAlign: 'center' }}>Actual Qty</p>
                    )}
                    {!is3PL && <p style={{ textAlign: 'center' }}>Cost</p>}
                    <div style={{ width: 'max-content', height: '25px', display: 'flex' }}>
                      <Button
                        style={{ color: 'black', fontWeight: 600 }}
                        label="Approve All"
                        onClick={handleAproveAll}
                        disabled={type !== 'Review'}
                      />
                      <Button
                        style={{ color: 'black', fontWeight: 600 }}
                        label="Reject All"
                        onClick={handleRejectAll}
                        disabled={type !== 'Review'}
                      />
                    </div>
                  </div>
                  {items?.length > 0 &&
                    items.map((lineItem: any) => {
                      return !lineItem.deleted ? (
                        <div
                          className={`parts-table-header ${tableClass} `}
                          style={{
                            background: 'white',
                            fontWeight: 'unset',
                            lineHeight: 1,
                          }}
                        >
                          {tabConfig.includes('part') && (
                            <div style={{ paddingLeft: '5px', textAlign: 'left' }}>
                              {`${lineItem.productNo} - ${lineItem.productdescription}`}
                              {lineItem.isAlreadyRequested && (
                                <img
                                  className="slds-tooltip-trigger"
                                  title="Trial Part"
                                  src={trialPartInd}
                                  alt="Trial Part"
                                  style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                                />
                              )}
                            </div>
                          )}
                          <div
                            style={{
                              paddingLeft: '5px',
                              textAlign: 'center',
                              alignItems: 'center',
                              display: 'inline-flex',
                            }}
                          >
                            <div>
                              <EventStatusBadge status={lineItem.lineVisualIndicator || ''} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                              {lineItem.productOnHold ? (
                                <img
                                  src={holdIconPath}
                                  className="logo-image"
                                  alt="company"
                                  style={{
                                    marginRight: '-26%',
                                    width: '16px',
                                    height: '17px',
                                    position: 'relative',
                                    top: '-49%',
                                  }}
                                />
                              ) : (
                                ''
                              )}
                              {lineItem.lineThresholdQuantityIndicator && (
                                <img
                                  className="slds-tooltip-trigger"
                                  title="Threshold Exceed"
                                  src={iconThreshold}
                                  alt="threshold"
                                  style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                                />
                              )}
                            </div>
                          </div>
                          <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                            <div className="row-control">
                              <Input
                                id="counter-input-3"
                                minValue={1}
                                maxValue={MAX_QUANTITY}
                                disabled={
                                  (type !== 'Review' &&
                                    visualIndicator.toLowerCase() === 'requested') ||
                                  !!viewOnly
                                }
                                onChange={(
                                  event: any,
                                  data: { value: string; number: number }
                                ): void => {
                                  handleCountalert(event, data, lineItem);
                                }}
                                value={lineItem.counterValue}
                                variant="counter"
                              />
                            </div>
                          </div>
                          {tabConfig.includes('actualUom') && (
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              {getUoM(lineItem.salesUnitOfMeasure, lineItem.packContent)}
                            </div>
                          )}
                          {tabConfig.includes('actualQty') && (
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              {Number(lineItem.actualQuantity) > 0
                                ? lineItem.actualQuantity
                                : calculateActualQuantitity(lineItem, lineItem?.counterValue) || ''}
                            </div>
                          )}
                          {!is3PL && (
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              <p>{lineItem.cost ? lineItem.cost : '0.00'}</p>
                            </div>
                          )}
                          <div style={{ display: 'flex' }}>
                            <div style={{ width: '74%' }}>
                              <InventoryApproveRejectDataCell
                                item={lineItem}
                                viewOnly={!!viewOnly}
                                handleUserWorkflowAction={handleUserAction}
                                typeVal={type}
                              />
                            </div>
                            <div
                              className="vertical-align-middle"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '20px',
                                textAlign: 'center',
                              }}
                            >
                              <Button
                                disabled={
                                  (type !== 'Review' &&
                                    visualIndicator.toLowerCase() === 'requested') ||
                                  !!viewOnly
                                }
                                iconCategory="utility"
                                iconName="delete"
                                iconSize="large"
                                iconVariant="bare"
                                variant="icon"
                                onClick={(): void => {
                                  // eslint-disable-next-line no-unused-expressions
                                  handleDelete(lineItem);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null;
                    })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </IconSettings>
  );
};

/** Custom Header on InventoryRequestDetailCatalog Page **/
export default InventoryRequestTable;
