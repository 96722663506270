/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any*/
/* eslint-disable @typescript-eslint/explicit-function-return-type*/
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable  @typescript-eslint/no-use-before-define */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable-next-line prefer-const */

import React, { FC, useState, useCallback, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import moment from 'moment';
import RequestTransfer from './NewTransfer';
import InventoryTransferFilter from './TransferFilter';
import './index.scss';

import { GET_TRANSFER_LIST } from '../../../graphql/getInventoryTransferList';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
// import { GET_ORDER_LIST } from '../../../graphql/getOrderList';
import { DEFAULT_OFFSET, LIMIT, NEXT_DATA, PREV_DATA, holdTypes } from '../../../util/constants';
import debounced from '../../../util/debounced';
import { getUserInfo } from '../../../store/ducks/userInfo';
import FilterTags from '../../Shared/FilterTags';
import TransferDataTable from './TransferDataTable';
import TransferHeader from './TransferHeader';
import NewRepTransfer from './NewRepTransfer';
import {
  setInventoryTransferFilters,
  getInventoryTransferFilters,
} from '../../../store/ducks/inventoryTransferFilters';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';
import { setHoldBannerStatus } from '../../../store/ducks/holdBanner';
import { GET_USER_HOLD } from '../../../graphql/getUserHold';
import { GET_FILTER_PRESETS } from '../../../graphql/getFilterPresets';

export interface Item {
  createddate: string;
  id?: string;
  ordernumber: string;
  receivingRepId?: string;
  receivingRepName?: string;
  salesRepId?: string;
  salesRepName?: string;
  status: string;
  type?: string;
  accountId: string;
  accountName: string;
  accountNumber: string;
  createdDate: string;
  effectiveDate: string;
  externalId: string | null;
  fromSubInventoryCode: string;
  orderNumber: string;
  priceBook2Id: null;
  recordTypeId: string;
  toSubInventoryCode: string;
  visualIndicator: string;
}
interface DynamicValue {
  [key: string]: any;
}
interface Filters {
  search?: string;
  status?: string[];
  submitToDate?: Date | string;
  submitFromDate?: Date | string;
}
interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}
interface Filter {
  search?: string;
  status: SelectedData[];
  submitToDate: Date | string;
  submitFromDate: Date | string;
}
interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  filterName?: string;
}

const TrasferTab: FC = () => {
  const history = useHistory();
  const userInfo = useSelector(getUserInfo);
  const dispatch = useDispatch();
  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const { pathname } = history.location;
  const statusUrl = pathname.substr(pathname.lastIndexOf('/') + 1, pathname.length - 1);
  const [selectedFilter, setSelectedFilter] = useState<FilterPreset | DynamicValue | null>(null);
  const [openRequestFilter, setOpenRequestFilter] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    ['SENT', 'RECEIVED', 'FAILED', 'COMPLETED'].indexOf(statusUrl.toUpperCase()) >= 0
      ? ['SENT', 'RECEIVED', 'FAILED', 'COMPLETED'].indexOf(statusUrl.toUpperCase())
      : 0
  );
  const [showRequestTransferModal, setShowRequestTransferModal] = useState(false);
  const [showNewTransferModal, setShowNewTransferModal] = useState(false);
  const [cancelModalSM, setCancelModalSM] = useState(false);
  const [cancelModalNonSM, setCancelModalNonSM] = useState(false);
  const [transferType, setTransferType] = useState('');
  const [sortColumn, setSortColumn] = useState<any>(true);
  const [transferDataSent, setTransferDataSent] = useState<Item[]>([]);
  const [transferDataRec, setTransferDataRec] = useState<Item[]>([]);
  const [transferDataCompleted, setTransferDataCompleted] = useState<Item[]>([]);
  const [transferDataFailed, setTransferDataFailed] = useState<Item[]>([]);
  const [updatedSelectedFilter, setUpdatedSelectedFilter] = useState<
    FilterPreset | DynamicValue | null
  >(null);
  const [offsetSent, setOffsetSent] = useState(0);
  const [offsetRec, setOffsetRec] = useState(0);
  const [offsetComp, setOffsetComp] = useState(0);

  const [getTransferDataSent, { data: TransferSent, loading }] = useLazyQuery(GET_TRANSFER_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [getTransferDataRec, { data: TransferRec }] = useLazyQuery(GET_TRANSFER_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [getTransferDataCompleted, { data: TransferCompleted }] = useLazyQuery(GET_TRANSFER_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [getTransferDataFailed, { data: TransferFailed }] = useLazyQuery(GET_TRANSFER_LIST, {
    fetchPolicy: 'no-cache',
  });
  const { data: holddata } = useQuery(GET_USER_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: userInfo?.id,
    },
  });

  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<any>(null);
  const [filterEventModalVisible, setFilterEventModalVisible] = useState(false);
  const [isEditFilter, setIsEditFilter] = useState(false);
  const { data: filterPresets, refetch: refetchFilterPresets } = useQuery(GET_FILTER_PRESETS, {
    variables: {
      userId: userInfo?.id,
      type: 'Transfers',
    },
  });
  const filterData = filterPresets?.getFilterPresets;

  useEffect(() => {
    if (holddata?.getUserHoldInfo && holddata?.getUserHoldInfo?.length > 0) {
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Custom Hold'] ||
            e === holdTypes[0]['Regulatory Noncompliance'] ||
            e === holdTypes[0]['Sunshine Act Hold']
        )?.length > 0
      ) {
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
      } else {
        dispatch(setHoldBannerStatus(false));
      }
    }
  }, [dispatch, holddata]);
  // const [
  //   getTransferListFailedItems,
  //   { data: transferFailedItems, loading: loadingInventoryRequestFailedItems },
  // ] = useLazyQuery(GET_ORDER_LIST, { fetchPolicy: 'network-only' });
  // const transferFailedListData = transferFailedItems?.getOrderList;

  // const [
  //   getTransferListProgressItems,
  //   { data: transferProgressItems, loading: loadingInventoryRequestProgressItems },
  // ] = useLazyQuery(GET_ORDER_LIST, { fetchPolicy: 'network-only' });
  // const transferProcessedListData = transferProgressItems?.getOrderList;
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  const handleClickRequestTransfer = useCallback((): void => {
    setShowRequestTransferModal(true);
  }, []);
  const handleCloseRequestTranfer = (): void => {
    // setShowRequestTransferModal(!showRequestTransferModal);
    setCancelModalSM(!cancelModalSM);
  };

  const handleNewTranferModalToggle = (): void => {
    // setShowNewTransferModal(!showNewTransferModal);
    setCancelModalNonSM(!cancelModalNonSM);
  };

  const handleCancelModalConfirmPressSM = (): void => {
    setShowRequestTransferModal(!showRequestTransferModal);
    setCancelModalSM(false);
  };

  const handleCancelModalConfirmPressNonSM = (): void => {
    setShowNewTransferModal(!showNewTransferModal);
    setCancelModalNonSM(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const getFilterApiValue = (filterValues: DynamicValue): Filters => {
    let reducedFilterValues = {};

    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          if (key === 'recevingAccount') {
            values = filterValues[key].map((item: any) => item.label);
          } else {
            values = filterValues[key].map((item: SelectedData) => item.id);
          }

          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (Array.isArray(filterValues[key]) && filterValues[key]) {
          if (filterValues[key].length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterDataa = { ...reducedFilterValues };
      return filterDataa;
    }
    return {};
  };

  const handleRefetchItemsWithFilters = useCallback(
    (filterDatas: Filters | null): void => {
      setOffsetComp(0);
      setOffsetRec(0);
      setOffsetSent(0);
      getTransferDataSent({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'SENT',
          filters: filterDatas,
        },
      });
      getTransferDataRec({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'RECEIVED',
          filters: filterDatas,
        },
      });
      getTransferDataCompleted({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'COMPLETED',
          filters: filterDatas,
        },
      });
      getTransferDataFailed({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'FAILED',
          filters: filterDatas,
        },
      });
    },
    [getTransferDataCompleted, getTransferDataRec, getTransferDataSent, getTransferDataFailed]
  );

  const handleFilterRequest = (
    filterValues: Filter | DynamicValue | null,
    filterName?: string
  ): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setUpdatedSelectedFilter(filter);
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      delete filters.search;
      handleRefetchItemsWithFilters(filters);
      setSelectedFilterApiValues(filters);
      dispatch(setInventoryTransferFilters(JSON.stringify(filter)));
    } else {
      handleRefetchItemsWithFilters(null);
    }
  };

  const handleRefetchItems = useCallback((): void => {
    setOffsetSent(0);
    setOffsetRec(0);
    setOffsetComp(0);
    // setShowRequestTransferModal(!showRequestTransferModal);
    getTransferDataSent({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'SENT',
      },
    });
    getTransferDataRec({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'RECEIVED',
      },
    });
    getTransferDataCompleted({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'COMPLETED',
      },
    });
    getTransferDataFailed({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'FAILED',
      },
    });
  }, [
    getTransferDataSent,
    getTransferDataRec,
    getTransferDataCompleted,
    // showRequestTransferModal,
    getTransferDataFailed,
  ]);

  const getFormattedList = useCallback((items: any[]): any[] => {
    if (items && items.length > 0) {
      const list = items.map((item: any) => {
        return {
          ...item,
          createdDate: item.createdDate ? moment(item.createdDate).format('L') : null,
          account:
            item.receivingRepAccount && item.receivingRepAccountName
              ? `${item.receivingRepAccount} - ${item.receivingRepAccountName}`
              : `${item.accountNumber} - ${item.accountName}`,
        };
      });
      return list;
    }
    return [];
  }, []);

  useEffect(() => {
    if (TransferSent && TransferSent.getInventoryTransferList) {
      setTransferDataSent(getFormattedList(TransferSent.getInventoryTransferList));
    }
    if (TransferRec && TransferRec.getInventoryTransferList) {
      setTransferDataRec(getFormattedList(TransferRec.getInventoryTransferList));
    }
    if (TransferCompleted && TransferCompleted.getInventoryTransferList) {
      setTransferDataCompleted(getFormattedList(TransferCompleted.getInventoryTransferList));
    }
    if (TransferFailed && TransferFailed.getInventoryTransferList) {
      setTransferDataFailed(getFormattedList(TransferFailed.getInventoryTransferList));
    }
  }, [
    TransferSent,
    TransferRec,
    TransferCompleted,
    TransferFailed,
    getFormattedList,
    setTransferDataSent,
  ]);

  useEffect(() => {
    // dispatch(setInventoryTransferFilters(null));
    setOffsetSent(0);
    setOffsetRec(0);
    setOffsetComp(0);
    setSelectedFilter(null);
    setUpdatedSelectedFilter(null);
    getTransferDataSent({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'SENT',
      },
    });
    getTransferDataRec({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'RECEIVED',
      },
    });
    getTransferDataCompleted({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'COMPLETED',
      },
    });
    getTransferDataFailed({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'FAILED',
      },
    });
  }, [
    getTransferDataSent,
    getTransferDataRec,
    getTransferDataCompleted,
    getTransferDataFailed,
    showRequestTransferModal,
    history,
    currentTab,
    dispatch,
  ]);

  const filterStore = useSelector(getInventoryTransferFilters);

  useEffect(() => {
    const setFilters = JSON.parse(filterStore || '{}');

    setUpdatedSelectedFilter(setFilters);
    setSelectedFilter(setFilters);
    setSelectedFilterApiValues(setFilters);
    const filters = getFilterApiValue(setFilters?.filterValues);
    delete filters.search;
    handleRefetchItemsWithFilters(filters);
  }, [currentTab, filterStore, handleRefetchItemsWithFilters]);

  const setReturnSearch = (searchedItem: string): void => {
    setOffsetSent(0);
    setOffsetRec(0);
    setOffsetComp(0);
    if (searchedItem.length >= 3) {
      getTransferDataSent({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'SENT',
          filters: {
            searchText: searchedItem,
          },
        },
      });
      getTransferDataRec({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'RECEIVED',
          filters: {
            searchText: searchedItem,
          },
        },
      });
      getTransferDataCompleted({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'COMPLETED',
          filters: {
            searchText: searchedItem,
          },
        },
      });
      getTransferDataFailed({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'FAILED',
          filters: {
            searchText: searchedItem,
          },
        },
      });
    } else {
      getTransferDataSent({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'SENT',
        },
      });
      getTransferDataRec({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'RECEIVED',
        },
      });
      getTransferDataCompleted({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'COMPLETED',
        },
      });
      getTransferDataFailed({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'FAILED',
        },
      });
    }
  };

  const debouncedFetchReturnItems = useCallback(debounced(500, setReturnSearch), [
    debounced,
    setReturnSearch,
  ]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchReturnItems(value);
      } else {
        debouncedFetchReturnItems(value);
      }
    },
    [debouncedFetchReturnItems]
  );

  const handleRequestFilter = (): void => {
    setSelectedFilter(updatedSelectedFilter);
    setOpenRequestFilter(!openRequestFilter);
  };

  const handleRequestFilterModal = (): void => {
    setOpenRequestFilter(!openRequestFilter);
  };

  const handleTabs = (item: any): void => {
    setCurrentTab(item);
    if (item === 0) {
      history.push('/inventory/Transfer/sent');
    } else if (item === 1) {
      history.push('/inventory/Transfer/received');
    } else if (item === 2) {
      history.push('/inventory/Transfer/failed');
    } else if (item === 3) {
      history.push('/inventory/Transfer/completed');
    }
  };

  const sortedcolumn = (property: any): void => {
    setSortColumn(property);
  };

  const handleTransferActionDropdownSelect = (value: any): void => {
    setTransferType(value.label);
    setShowNewTransferModal(!showNewTransferModal);
  };

  // Transfer Pagination
  const handlePaginationButtonClicked = (type: string): void => {
    let finalOffset;
    if (currentTab === 0) {
      if (type === NEXT_DATA) {
        finalOffset = offsetSent + LIMIT;
      } else {
        finalOffset = offsetSent - LIMIT;
      }

      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getTransferDataSent({
          variables: {
            limit: LIMIT,
            offset: finalOffset,
            type: 'SENT',
            orderBy: sortProperty,
            orderSortType: sortDirection,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getTransferDataSent({
          variables: {
            limit: LIMIT,
            offset: finalOffset,
            type: 'SENT',
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetSent(finalOffset);
    } else if (currentTab === 1) {
      if (type === NEXT_DATA) {
        finalOffset = offsetRec + LIMIT;
      } else {
        finalOffset = offsetRec - LIMIT;
      }

      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getTransferDataRec({
          variables: {
            limit: LIMIT,
            offset: finalOffset,
            type: 'RECEIVED',
            orderBy: sortProperty,
            orderSortType: sortDirection,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getTransferDataRec({
          variables: {
            limit: LIMIT,
            offset: finalOffset,
            type: 'RECEIVED',
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetRec(finalOffset);
    } else if (currentTab === 2) {
      if (type === NEXT_DATA) {
        finalOffset = offsetComp + LIMIT;
      } else {
        finalOffset = offsetComp - LIMIT;
      }

      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getTransferDataFailed({
          variables: {
            limit: LIMIT,
            offset: finalOffset,
            type: 'FAILED',
            orderBy: sortProperty,
            orderSortType: sortDirection,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getTransferDataFailed({
          variables: {
            limit: LIMIT,
            offset: finalOffset,
            type: 'FAILED',
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetComp(finalOffset);
    } else if (currentTab === 3) {
      if (type === NEXT_DATA) {
        finalOffset = offsetComp + LIMIT;
      } else {
        finalOffset = offsetComp - LIMIT;
      }

      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getTransferDataCompleted({
          variables: {
            limit: LIMIT,
            offset: finalOffset,
            type: 'COMPLETED',
            orderBy: sortProperty,
            orderSortType: sortDirection,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getTransferDataCompleted({
          variables: {
            limit: LIMIT,
            offset: finalOffset,
            type: 'COMPLETED',
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetComp(finalOffset);
    }
  };

  const handleEditFilter = (editFilter: boolean): void => {
    setIsEditFilter(editFilter);
  };

  const handleEditPress = (filter: FilterPreset, isEditPress: boolean): void => {
    setSelectedFilter(filter);
    setFilterEventModalVisible(!filterEventModalVisible);
    setOpenRequestFilter(!openRequestFilter);
    if (isEditPress) {
      handleEditFilter(true);
    }
  };

  const handleFilterEvents = (
    filterValues: Filter | DynamicValue | null,
    filterName?: string
  ): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setUpdatedSelectedFilter(filter);
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
      delete filters.search;
      handleRefetchItems();
      dispatch(setInventoryTransferFilters(JSON.stringify(filter)));
    } else {
      setSelectedFilterApiValues(null);
      handleRefetchItems();
    }
  };

  const resetFilters = useCallback((): void => {
    setSelectedFilter(null);
    setUpdatedSelectedFilter(null);
    setIsEditFilter(false);
    setSelectedFilterApiValues(null);
    getTransferDataSent({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'SENT',
      },
    });
    getTransferDataRec({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'RECEIVED',
      },
    });
    getTransferDataCompleted({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'COMPLETED',
      },
    });
    getTransferDataFailed({
      variables: {
        limit: LIMIT,
        offset: DEFAULT_OFFSET,
        type: 'FAILED',
      },
    });
  }, [getTransferDataSent, getTransferDataRec, getTransferDataCompleted, getTransferDataFailed]);

  const handleDeleteFilterPreset = (externalId: string): void => {
    const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
    if (filterExternalId && filterExternalId === externalId) {
      resetFilters();
    }
  };

  const handleToggleFilterEvent = (): void => {
    handleEditFilter(false);
    setSelectedFilter(updatedSelectedFilter);
    setFilterEventModalVisible(!filterEventModalVisible);
    setOpenRequestFilter(!openRequestFilter);
  };

  return (
    <IconSettings iconPath="/icons">
      <TransferHeader
        selectedFilterApiValues={selectedFilterApiValues}
        handleSearch={handleSearch}
        handleTransfer={handleClickRequestTransfer}
        handleFilter={handleRequestFilter}
        isSportMed={userInfo?.businessunit === 'Sports Medicine'}
        handleTransferActionDropdownSelect={handleTransferActionDropdownSelect}
        filterPresets={filterData}
        onEditPress={handleEditPress}
        handleFilterEvents={handleFilterEvents}
        resetFilters={resetFilters}
      />
      {selectedFilter && selectedFilter.filterValues && !isEditFilter && (
        <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterRequest} />
      )}
      {loading && (
        <Spinner
          size="large"
          variant="base"
          assistiveText={{ label: 'Loading...' }}
          key="spinner"
        />
      )}
      {
        <Tabs
          onSelect={handleTabs}
          variant="scoped"
          id="inventory-tabs-scoped"
          selectedIndex={currentTab}
        >
          <TabsPanel label="Sent">
            {transferDataSent && transferDataSent.length > 0 ? (
              <TransferDataTable
                data={transferDataSent || []}
                offsetData={offsetSent}
                type="Sent"
                sortOrderList={(items: any[]): void => setTransferDataSent(items)}
                getTransferList={getTransferDataSent}
                selectedFilter={selectedFilter}
                sortedcolumn={sortedcolumn}
              />
            ) : (
              <div className="no-data-found-container">
                <h1 className="no-data-found-text">No Records Found</h1>
              </div>
            )}
          </TabsPanel>
          <TabsPanel label="Received">
            {transferDataRec && transferDataRec.length > 0 ? (
              <TransferDataTable
                data={transferDataRec || []}
                type="Received"
                offsetData={offsetRec}
                sortOrderList={(items: any[]): void => setTransferDataRec(items)}
                getTransferList={getTransferDataRec}
                selectedFilter={selectedFilter}
                sortedcolumn={sortedcolumn}
              />
            ) : (
              <div className="no-data-found-container">
                <h1 className="no-data-found-text">No Records Found</h1>
              </div>
            )}
          </TabsPanel>
          <TabsPanel label="Failed">
            {transferDataFailed && transferDataFailed.length > 0 ? (
              <TransferDataTable
                data={transferDataFailed || []}
                offsetData={offsetComp}
                type="Failed"
                sortOrderList={(items: any[]): void => setTransferDataFailed(items)}
                getTransferList={getTransferDataFailed}
                selectedFilter={selectedFilter}
                sortedcolumn={sortedcolumn}
              />
            ) : (
              <div className="no-data-found-container">
                <h1 className="no-data-found-text">No Records Found</h1>
              </div>
            )}
          </TabsPanel>
          <TabsPanel label="Processed">
            {transferDataCompleted && transferDataCompleted.length > 0 ? (
              <TransferDataTable
                data={transferDataCompleted || []}
                offsetData={offsetComp}
                type="Completed"
                sortOrderList={(items: any[]): void => setTransferDataCompleted(items)}
                getTransferList={getTransferDataCompleted}
                selectedFilter={selectedFilter}
                sortedcolumn={sortedcolumn}
              />
            ) : (
              <div className="no-data-found-container">
                <h1 className="no-data-found-text">No Records Found</h1>
              </div>
            )}
          </TabsPanel>
          {/* <TabsPanel label="Failed">No Record Found</TabsPanel> */}
        </Tabs>
      }
      {currentTab === 0 && (
        <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronleft"
              iconSize="small"
              disabled={!(offsetSent > 0)}
              iconVariant="bare"
              onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
              variant="icon"
            />
          </div>
          <div className="slds-col slds-p-left_medium slds-p-right_medium">
            {currentTab === 0 && <span>{`${offsetSent + 1} - ${offsetSent + LIMIT}`}</span>}
          </div>
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronright"
              iconSize="small"
              iconVariant="bare"
              disabled={transferDataSent && transferDataSent.length < 50}
              onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
              variant="icon"
            />
          </div>
        </div>
      )}
      {currentTab === 1 && (
        <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronleft"
              iconSize="small"
              disabled={!(offsetRec > 0)}
              iconVariant="bare"
              onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
              variant="icon"
            />
          </div>
          <div className="slds-col slds-p-left_medium slds-p-right_medium">
            {currentTab === 1 && <span>{`${offsetRec + 1} - ${offsetRec + LIMIT}`}</span>}
          </div>
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronright"
              iconSize="small"
              iconVariant="bare"
              disabled={transferDataRec && transferDataRec.length < 50}
              onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
              variant="icon"
            />
          </div>
        </div>
      )}
      {currentTab === 2 && (
        <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronleft"
              iconSize="small"
              disabled={!(offsetComp > 0)}
              iconVariant="bare"
              onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
              variant="icon"
            />
          </div>
          <div className="slds-col slds-p-left_medium slds-p-right_medium">
            {<span>{`${offsetComp + 1} - ${offsetComp + LIMIT}`}</span>}
          </div>
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronright"
              iconSize="small"
              iconVariant="bare"
              disabled={transferDataFailed && transferDataFailed.length < 50}
              onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
              variant="icon"
            />
          </div>
        </div>
      )}
      {currentTab === 3 && (
        <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronleft"
              iconSize="small"
              disabled={!(offsetComp > 0)}
              iconVariant="bare"
              onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
              variant="icon"
            />
          </div>
          <div className="slds-col slds-p-left_medium slds-p-right_medium">
            {<span>{`${offsetComp + 1} - ${offsetComp + LIMIT}`}</span>}
          </div>
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronright"
              iconSize="small"
              iconVariant="bare"
              disabled={transferDataCompleted && transferDataCompleted.length < 50}
              onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
              variant="icon"
            />
          </div>
        </div>
      )}
      <div>
        {showRequestTransferModal && (
          <RequestTransfer
            requestTransferModalVisible={showRequestTransferModal}
            handleRequestTransferModal={handleCloseRequestTranfer}
            handleRefetchItems={handleRefetchItems}
          />
        )}
      </div>
      {openRequestFilter && (
        <InventoryTransferFilter
          isOpen={openRequestFilter}
          toggleOpen={handleRequestFilterModal}
          selectedFilter={selectedFilter}
          handleFilterRequest={handleFilterRequest}
          currentTab={currentTab}
          isRequest
          handleToggleFilterEvent={handleToggleFilterEvent}
          refetchFilterPresets={refetchFilterPresets}
          handleFilterEvents={handleFilterEvents}
          handleEditFilter={handleEditFilter}
          isEditFilter={isEditFilter}
          handleClearFilter={handleDeleteFilterPreset}
        />
      )}
      {showNewTransferModal && (
        <NewRepTransfer
          isEdit={false}
          isOpen={showNewTransferModal}
          toggleOpen={handleNewTranferModalToggle}
          formData={data}
          transferType={transferType}
          handleCancelButton={handleNewTranferModalToggle}
          handleRefetchItems={handleRefetchItems}
        />
      )}
      {showRequestTransferModal && (
        <CancelEventModal
          isTransfer
          canceModalVisible={cancelModalSM}
          handleCancelModal={handleCloseRequestTranfer}
          handleCancelModalConfirmPress={handleCancelModalConfirmPressSM}
        />
      )}
      {showNewTransferModal && (
        <CancelEventModal
          isTransfer
          canceModalVisible={cancelModalNonSM}
          handleCancelModal={handleNewTranferModalToggle}
          handleCancelModalConfirmPress={handleCancelModalConfirmPressNonSM}
        />
      )}
    </IconSettings>
  );
};

/** Events Tab component**/
export default TrasferTab;
