/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useCallback, useEffect } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { escapeRegExp } from 'lodash';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import DatePicker from '../Shared/DatePicker';
import { getUserId } from '../../store/ducks/userId';
import { getUserInfo } from '../../store/ducks/userInfo';
import { GET_SALES_REPS } from '../../graphql/getSalesReps';
import AutocompleteSingleSelectPicker from '../Shared/AutocompleteSingleSelectPicker';
import AutocompleteSingleSelectPickerWithSearch from '../Shared/AutocompleteSingleSelectPickerWithSearch';
import { GET_FILTERED_PRODUCT_GROUPS } from '../../graphql/getFilteredProductGroups';
import { GET_FILTERED_ACCOUNTS } from '../../graphql/getFilteredAccounts';
import {
  DATE_VIEW_FORMATTER,
  FILTER_CYCLECOUNT_STATUS,
  COUNT_TYPE_ACTIONS,
  COUNT_TYPE_ACTIONS_NON_SM,
  FILTER_CYCLE_COUNT,
  NOTIFY_TYPE,
  SPORTS_MED,
} from '../../util/constants';
import { GET_USER_TERRITORIES } from '../../graphql/getUserTerritories';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import { GET_ACCOUNTS } from '../../graphql/getAccounts';
import { GET_USER_REGION_AND_TERRITORIES } from '../../graphql/getUserRegionAndTerritories';
import { getRegionTerritoryInfo, setRegionTerritoryInfo } from '../../store/ducks/userRegionAndTerritory';
import DeleteFilterPreset from '../FilterEvent/DeleteFilterPreset';
import SaveFilter from '../FilterEvent/SaveFilter';
import CREATE_FILTER_PRESET from '../../graphql/mutations/createFilterPreset';
import UPDATE_FILTER_PRESET from '../../graphql/mutations/updateFilterPreset';
import DELETE_FILTER_PRESET from '../../graphql/mutations/deleteFilterPreset';

//import { clearOrderFilters } from '../../store/ducks/orderFilters';

interface Props {
  handleToggleFilterCycleCount: () => void;
  filterModalVisible: boolean;
  handleFilterCycleCount: (filter: DynamicType | null) => void;
  resetFilters: () => void;
  selectedFilter?: DynamicType | null;
  type?: string;
  handleEditFilter: (editEvent: boolean) => void;
  handleClearFilter: (externalId: string) => void;
  refetchFilterPresets: () => void;
  isEditFilter: boolean;
}

interface SelectedData {
  key: any;
  number: any;
  id: string;
  label: string;
}

interface DynamicType {
  [key: string]: any;
}

interface ValueInterface {
  value: string;
}
interface SelectedFilters {
  status: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  account: SelectedData[];
  salesRep: SelectedData[];
  countType: string[];
}

type UserRegionAndTerritory = {
  accountId: string;
  [region: string]: string;
  territory: string;
  userId: string;
  __typename: string;
};

type RegionTerritoryDropdown = {
  accountId?: string;
  label?: string;
  region?: string;
  regionId?: string;
  territoryId?: string;
  userId?: string;
  value?: string;
  __typename?: string;
};

type CustomOptionObject = {
  __typename: string;
  name: string;
  number: string;
  value: string;
  region: string;
  territory: string;
};

interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}

// enum DetailTypes {
//   InventoryLocation = 'UserTerritory',
// }

enum DetailTypes {
  Account = 'Account',
  SalesRep = 'SalesRep',
  ProcedureDetail = 'ProcedureDetail',
  CoveringRep = 'CoveringRep',
  Surgeon = 'Surgeon',
  userTerritory = 'UserTerritory',
  TBAAccount = 'TBAAccount',
  ProductGroup = 'ProductGroup',
}

enum DetailTypesSM {
  InventoryLocation = 'UserTerritory',
}
const FilterCycleCount: FC<Props> = ({
  handleToggleFilterCycleCount,
  filterModalVisible,
  selectedFilter,
  handleFilterCycleCount,
  resetFilters,
  handleEditFilter,
  handleClearFilter,
  refetchFilterPresets,
  isEditFilter,
}) => {
  const dispatch = useDispatch();
  const userRegionTerritoryInfo: any = useSelector(getRegionTerritoryInfo);
  const userId: any = useSelector(getUserId);
  const userInfo = useSelector(getUserInfo);
  const filterValues = selectedFilter?.filterValues;
  const filterName = selectedFilter ? selectedFilter.filterName : '';
  const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;

  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {});

  const [territoryOptions, setTerritoryOptions] = useState<RegionTerritoryDropdown[]>();
  const [regionOptions, setRegionOptions] = useState<RegionTerritoryDropdown[]>();

  const [inputValueAccount, setInputValueAccount] = useState('');
  const [accountOptions, setAccountOptions] = useState<any>();
  const [inputValue, setInputValue] = useState('');
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [createFilterPreset, { data: filterPresets }] = useMutation(CREATE_FILTER_PRESET);
  const [updateFilterPreset, { data: updatedfilterPresets }] = useMutation(UPDATE_FILTER_PRESET);
  const [deleteFilterPreset] = useMutation(DELETE_FILTER_PRESET);

  const [
    getEligibleAccounts,
    { data: eligibleAccountsData, loading: loadingEligibleAccounts },
  ] = useLazyQuery(GET_ACCOUNTS, { fetchPolicy: 'no-cache' });

  const [getSalesRepsItems, { data: salesRepsItems, loading: loadingSalesRep }] = useLazyQuery(
    GET_SALES_REPS
  );

  const { data: filteredproductGroups, loading: loadingProductGroup } = useQuery(
    GET_FILTERED_PRODUCT_GROUPS
  );
  const [getRegionAndTerritory, { data: regionAndTerritoryData }] = useLazyQuery(GET_USER_REGION_AND_TERRITORIES);
  useEffect(() => {
    getSalesRepsItems();
    if (selectedFilters && selectedFilters.salesRep) {
      getEligibleAccounts({
        variables: {
          salesRepId: selectedFilters.salesRep[0]?.id,
          limit: 1000
        },
      });
    }else{
      getEligibleAccounts({
        variables: {
          limit: 1000
        },
      });
    }
  }, [selectedFilters]);

  const handleCreateFilterPreset = (name: string): void => {
    let reducedFilterValues = {};
    if (selectedFilters) {
      Object.keys(selectedFilters).forEach((key: string) => {
        if (Array.isArray(selectedFilters[key]) && key !== 'eventType') {
          if (key === 'salesRep') {
            const values = selectedFilters[key].map((item: SelectedData) => {
              const itemCopy = { id: item.id, label: item.label };
              return { ...itemCopy };
            });
            reducedFilterValues = { ...reducedFilterValues, salesRep: [...values] };
          } else if (key === 'countType') {
            const values = selectedFilters[key].map((item: SelectedData) => {
              const itemCopy = { id: item?.key, label: item?.label };
              return { ...itemCopy };
            });
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          } else {
            const values = selectedFilters[key].map((item: SelectedData) => {
              const itemCopy = { id: item?.id, label: item.label };
              return { ...itemCopy };
            });
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key !== '__typename' && selectedFilters[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: selectedFilters[key] };
        }
      });
    }
    const filterData = { ...reducedFilterValues };

    if (!filterExternalId || filterExternalId === '') {
      createFilterPreset({
        variables: {
          userId,
          filterValues: filterData,
          name,
          type: 'CycleCount',
        },
      });
    } else if (filterExternalId) {
      updateFilterPreset({
        variables: {
          externalId: filterExternalId,
          filterValues: filterData,
          name,
          type: 'CycleCount',
        },
      });
    }
  };

  const handleSaveModalVisible = (): void => {
    if (!filterExternalId || filterExternalId === '') {
      setSaveModalVisible(!saveModalVisible);
    } else {
      handleCreateFilterPreset(filterName);
    }
  };

  const handleDeleteModalVisible = useCallback((): void => {
    setDeleteModalVisible(!deleteModalVisible);
  }, [deleteModalVisible]);

  const handleDeleteFilterPreset = (): void => {
    deleteFilterPreset({
      variables: {
        externalId: filterExternalId,
      },
    }).then(response => {
      // if delete filter preset is successful
      const deleteUsageResponse =
        response.data &&
        response.data.deleteFilterPreset &&
        response.data.deleteFilterPreset.message === 'success';
      if (deleteUsageResponse) {
        handleDeleteModalVisible();
        handleToggleFilterCycleCount();
        handleClearFilter(filterExternalId);
        if (refetchFilterPresets) {
          handleEditFilter(false);
          refetchFilterPresets();
        }
      }
    });
  };

  useEffect(() => {
    if (
      updatedfilterPresets &&
      updatedfilterPresets.updateFilterPreset &&
      updatedfilterPresets.updateFilterPreset.message === 'success'
    ) {
      handleToggleFilterCycleCount();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
        reset();
      }
    }
  }, [
    handleEditFilter,
    handleFilterCycleCount,
    handleToggleFilterCycleCount,
    refetchFilterPresets,
    selectedFilters,
    updatedfilterPresets,
  ]);

  useEffect(() => {
    if (
      filterPresets &&
      filterPresets.createFilterPreset &&
      filterPresets.createFilterPreset.message === 'success'
    ) {
      handleToggleFilterCycleCount();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
      }
    }
  }, [
    filterPresets,
    handleEditFilter,
    handleFilterCycleCount,
    handleToggleFilterCycleCount,
    refetchFilterPresets,
    selectedFilters,
  ]);

  useEffect(() => {
    if (selectedFilters && selectedFilters.account && selectedFilters.salesRep) {
      selectedFilters.account = [];
    }
  }, [selectedFilters?.salesRep])

  const getApiDateTime = useCallback((eventDate: any, name: string): string => {
    const formattedSelectedDate = new Date(eventDate);
    let updatedDate;

    if (name.toLowerCase().includes('enddate') || name.toLowerCase().includes('todate')) {
      updatedDate = new Date(formattedSelectedDate).setHours(23);
      updatedDate = new Date(updatedDate).setMinutes(59);
      updatedDate = new Date(updatedDate).setSeconds(59);
    } else {
      updatedDate = new Date(formattedSelectedDate).setHours(0);
      updatedDate = new Date(updatedDate).setMinutes(0);
      updatedDate = new Date(updatedDate).setSeconds(0);
    }

    const finalDate = format(new Date(updatedDate), DATE_VIEW_FORMATTER);
    return finalDate;
  }, []);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };
      setSelectedFilters(selectedFilterValues);
      if (key === 'salesReps'){
        const selectedFilterValues = 
        { ...selectedFilters,
          [key]: filterValuesObj,
          region: [],
          territory: [],
        };
        setSelectedFilters(selectedFilterValues);
      }
      if (key === 'region'){
        const selectedFilterValues = 
        { ...selectedFilters,
          [key]: filterValuesObj,
          territory: [],
        };
        setSelectedFilters(selectedFilterValues);
      }
      if (key === 'salesRep' && filterValuesObj?.length === 0) {
        const newObject = {
          ...selectedFilters,
          salesRep: [],
          region: [],
          territory: [],
        };
        setSelectedFilters(newObject);
      } else if (key === 'region' && filterValuesObj?.length === 0) {
        const newObject = {
          ...selectedFilters,
          region: [],
          territory: [],
        };
        setSelectedFilters(newObject);
      }
    },
    [selectedFilters]
  );

  const handleSelect = (data: any, name: string) => {    
    const { selection } = data;
    if (selection) {
      setFilterValue(name, selection);
    } else {
      setFilterValue(name, data);
    }
  };

  const handleSelectItemAccount = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValueAccount('');
      setFilterValue('inventoryLocation', selection);
    },
    [setFilterValue]
  );

  // const handleChangeOnAccount = useCallback(
  //   (_event, selectedValue: ValueInterface) => {
  //     const { value } = selectedValue;
  //     _event.persist();
  //     setInputValueAccount(value);
  //   },
  //   [setInputValueAccount]
  // );

  // const handleRemoveSelectionAccount = useCallback(
  //   (_event, data: any) => {
  //     const { selection } = data;
  //     setInputValueAccount('');
  //     setFilterValue('inventoryLocation', selection);
  //   },
  //   [setFilterValue]
  // );

  const getAccountdata = (searchText: string): void => {
    getEligibleAccounts({
    variables: {
      salesRepId: (selectedFilters && selectedFilters.salesRep && selectedFilters.salesRep[0]) ? selectedFilters.salesRep[0]?.id : '',
      limit: 1000,
      filters: {
        searchText,
      },
    },
  });
};

  const comboboxFilterAndLimit = (parameters: any): any => {
    const { searchedValue, options, selection } = parameters;
    const inputValueRegExp = new RegExp(escapeRegExp(searchedValue), 'ig');
    return options.filter((option: any) => {
      const searchTermFound = option?.label ? option?.label.match(inputValueRegExp) : false;
      const isSeparator = false;
      const notAlreadySelected = !selection?.some((sel: any) => sel?.id === option?.id);
      return (!searchedValue || isSeparator || searchTermFound) && notAlreadySelected;
    });
  };

  const reset = (): void => {
    handleToggleFilterCycleCount();
    setSelectedFilters(null);
    // setSelectedOrderTypes([]);
    resetFilters();
    //   dispatch(clearOrderFilters());
  };

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate) {
      const utcDate = getApiDateTime(selectedDate, name);
      setFilterValue(name, utcDate);
    }
  };

  const handleApplyFilter = (): void => {
    handleToggleFilterCycleCount();
    handleFilterCycleCount(selectedFilters);
  };

  const handleCheckboxChange = (value: any, checked: boolean): void => {
    if (checked) {
      // setFilterValue('countType', [...selectedFilters?.countType, value])
      const countTypes = selectedFilters?.countType || [];
      countTypes.push(value);
      setFilterValue('countType', countTypes);
    } else {
      const countType = selectedFilters?.countType?.filter((item: any) => item !== value) || [];
      setFilterValue('countType', countType);
    }
    // setFilterValue('countType', event.target.value);
  };

  const optionsWithLabelTerritory = (subInventoryCode: any): object =>
    subInventoryCode &&
    subInventoryCode.map((elem: { value: any; __typename: string; subInventoryCode: any }) => {
      if ([DetailTypesSM.InventoryLocation].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.value}` };
      }
    });

  const optionsWithSalesRepLabel = (value: any): any[] =>
    value &&
    value.map((elem: CustomOptionObject) => {
      if (elem.__typename === 'SalesRep') {
        return { ...elem, label: `${elem.value}` };
      }
      return { ...elem, label: `${elem.value}` };
    });

  const optionsWithLabel = (value: any): any =>
    value &&
    value.map((elem: CustomOptionObject) => {
      if (elem.__typename === 'UserTerritory') {
        return { ...elem, label: `${elem?.value}` };
      }
      if ([DetailTypes.Account].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: ` ${elem.number} ${elem.value}` };
      }
      return { ...elem, label: `${elem?.number} - ${elem?.name}` };
    });

  const optionsWithPGLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      if ([DetailTypes.ProductGroup].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.value}` };
      }
    });

  const handleChangeOnType = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );

  const removeDuplicates = (array: any): any => {
    const newArray: any[] = [];
    array.filter((item: any) => {
      const i = newArray.findIndex(x => x?.number === item?.number);
      if (i <= -1) {
        newArray.push(item);
      }
      return null;
    });
    return newArray;
  };
  const handleSelectPG = useCallback(
    (data: any, name: string) => {
      if (data?.selection) {
        const { selection } = data;
        setFilterValue(name, selection);
      } else {
        setFilterValue(name, data);
      }
    },
    [setFilterValue, userId]
  );

  const salesRepsData = optionsWithSalesRepLabel(salesRepsItems?.getSalesReps);
  const productGroup = optionsWithPGLabel(filteredproductGroups?.getProductGroups);

  useEffect(() => {
    // if (territoryData?.getUserTerritories && userInfo?.businessunit === SPORTS_MED) {
    //   setAccountOptions(optionsWithLabelTerritory(territoryData?.getUserTerritories));
    // } else {
    if (eligibleAccountsData?.getAccounts) {
      setAccountOptions(optionsWithLabel(removeDuplicates(eligibleAccountsData?.getAccounts)));
    }
    //  }
  }, [eligibleAccountsData]);

  const handleSelectItem = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValue('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc: any, cur: any) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      handleSelectPG(updatedSelection, 'productGroup');
    },
    [setInputValue, handleSelectPG]
  );

  useEffect(() => {
    if (userId) {
      getRegionAndTerritory({
        variables: {
          userId,
        },
      });
    }
  }, [getRegionAndTerritory, userId]);

  const getUniqueRegionTerritory = (array: UserRegionAndTerritory[]): UserRegionAndTerritory[] => {
    const cleanedArray = array.filter((item: any) => item !== undefined && item !== null);
    return cleanedArray.filter(
      (item: UserRegionAndTerritory, index: number, self: UserRegionAndTerritory[]) =>
        index === self.findIndex((element: UserRegionAndTerritory) => item.id === element.id)
    );
  };

  const getRegionTerritoryOptions = () => {
    let tempRegions: any[] = [];
    let tempTerritories: any[] = [];
    if (selectedFilters && selectedFilters?.salesRep?.length && !selectedFilters?.region?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item.userId === selectedFilters.salesRep[0].id)
          tempRegions.push({ id: item.regionId, value: item.region });
      });
      userRegionTerritoryInfo?.map((item: any) => {
        if (item.userId === selectedFilters.salesRep[0].id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && selectedFilters?.region?.length && selectedFilters?.salesRep?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item?.userId === selectedFilters?.salesRep?.[0]?.id && item?.regionId === selectedFilters?.region?.[0]?.id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && selectedFilters?.region?.length && !selectedFilters?.salesRep?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item?.regionId === selectedFilters?.region?.[0]?.id)
          tempTerritories.push({ id: item.territoryId, value: item.territory });
      });
    }
    if (selectedFilters && !selectedFilters?.region?.length && !selectedFilters?.salesRep?.length) {
      tempRegions = userRegionTerritoryInfo?.map((item: any, index: number) => {
        return { id: item.regionId, value: item.region };
      });
      tempTerritories = userRegionTerritoryInfo?.map((item: any, index: number) => {
        return { id: item.territoryId, value: item.territory };
      });
    }
    setRegionOptions(getUniqueRegionTerritory(tempRegions));
    setTerritoryOptions(getUniqueRegionTerritory(tempTerritories));
  };

  useEffect(() => {
    if (userRegionTerritoryInfo) getRegionTerritoryOptions()
  }, [selectedFilters,userRegionTerritoryInfo]);

  useEffect(() => {
    if (regionAndTerritoryData) 
      dispatch(setRegionTerritoryInfo(regionAndTerritoryData?.getUserRegionAndTerritories));
  }, [regionAndTerritoryData]);

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={filterModalVisible}
        onRequestClose={handleToggleFilterCycleCount}
        footer={[
          <Button
          className="slds-float_left slds-text-color_default"
          label="Save Filter"
          disabled={
            selectedFilters?.endDate &&
            selectedFilters?.startDate &&
            selectedFilters.endDate < selectedFilters.startDate
          }
          key="Save"
          onClick={handleSaveModalVisible}
        />,
          <Button label="Reset Filter" key="Reset" onClick={reset} />,
          <Button
            className="yellow-btn"
            label="Apply"
            onClick={handleApplyFilter}
            disabled={
              selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate
            }
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            Filters
          </div>,
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">Attributes</h1>
            <div className="filter_date_container" style={{ marginTop: '-17px' }}>
              <p className="selectbox_label" style={{ marginBottom: '-4px' }}>
                Count Type
              </p>
              <Combobox
                id="countType"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'countType'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'countType'),
                }}
                labels={{
                  label: ``,
                  placeholderReadOnly: 'Select Count Type',
                  noOptionsFound: 'No data found',
                }}
                multiple
                // placeholder="Select Count Type"
                options={FILTER_CYCLE_COUNT || []}
                selection={selectedFilters ? selectedFilters?.countType : []}
                variant="readonly"
              />
            </div>
            <div className="filter_date_container">
              <p className="selectbox_label" style={{ marginBottom: '-4px' }}>
                Status
              </p>
              <Combobox
                id="status"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'status'),
                }}
                labels={{
                  label: ``,
                  placeholderReadOnly: 'Select Status',
                  noOptionsFound: 'No data found',
                }}
                multiple
                // placeholder="Select Status"
                options={FILTER_CYCLECOUNT_STATUS || []}
                selection={selectedFilters ? selectedFilters?.status : []}
                variant="readonly"
              />
            </div>
            <div className="filter_date_container">
              <p className="selectbox_label" style={{ marginBottom: '-4px' }}>
                Product Group
              </p>
              <Combobox
                id="product"
                options={comboboxFilterAndLimit({
                  searchedValue: inputValue,
                  options: productGroup || [],
                  selection: selectedFilters?.productGroup || [],
                })}
                events={{
                  onChange: handleChangeOnType,
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelectPG(data, 'productGroup'),
                  onSelect: handleSelectItem,
                }}
                optionsAddItem={[]}
                labels={{
                  label: ``,
                  placeholder: 'Select an Option',
                  noOptionsFound: 'No data found',
                }}
                disabled={false}
                multiple
                // placeholder="Select an Option"
                menuPosition="relative"
                value={inputValue}
                hasInputSpinner={loadingProductGroup}
                selection={selectedFilters ? selectedFilters?.productGroup || [] : []}
                variant="base"
              />
            </div>
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">Date</h1>
            <div className="filter_date_container" style={{ marginTop: '-17px' }}>
              <p className="selectbox_label">Start Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="Select Start Date"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'startDate')}
                    disabled={false}
                    isFilterDate={true}
                    value={selectedFilters ? selectedFilters.startDate : null}
                  />
                </div>
              </div>
            </div>
            <div className="filter_date_container">
              <p className="selectbox_label">End Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="Select End Date"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'endDate')}
                    disabled={false}
                    isFilterDate={true}
                    value={selectedFilters ? selectedFilters.endDate : null}
                  />
                </div>
              </div>
            </div>
            {selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate && (
                <Pill
                  labels={{
                    label: 'Error: To Date must be greater than or equal to From Date',
                  }}
                  hasError
                  icon={
                    <Icon
                      title="Error"
                      category="utility"
                      name="warning"
                      className="slds-icon-text-error"
                    />
                  }
                />
              )}
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">Other</h1>
            <div className="filter_date_container" style={{ marginTop: '-17px' }}>
              <p className="selectbox_label" style={{ marginBottom: '-12px' }}>
                Sales Rep
              </p>
              <AutocompleteSingleSelectPicker
                items={salesRepsData}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'salesRep');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters?.salesRep || [] : []}
                placeholder="Select Sales Rep"
                label=""
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="salesRep"
                autoSelectValue={false}
              />
            </div>
            <p className="selectbox_label" style={{ marginBottom: '-12px' }}>
              Region
            </p>
            <AutocompleteSingleSelectPicker
              items={regionOptions || []}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'region');
              }}
              disabled={false}
              selectedItem={selectedFilters ? selectedFilters.region || [] : []}
              placeholder="Select Region"
              label=""
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="region"
              autoSelectValue={false}
            />
            <p className="selectbox_label" style={{ marginBottom: '-12px' }}>
              Territory
            </p>
            <AutocompleteSingleSelectPicker
              items={territoryOptions || []}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'territory');
              }}
              disabled={false}
              selectedItem={selectedFilters ? selectedFilters.territory || [] : []}
              placeholder="Select Territory"
              label=""
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="territory"
              autoSelectValue={false}
            />
            <p className="selectbox_label" style={{ marginBottom: '-12px' }}>
              Account
            </p>
            <AutocompleteSingleSelectPicker
              items={accountOptions}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'account');
              }}
              disabled={false}
              selectedItem={selectedFilters ? selectedFilters?.account || [] : []}
              placeholder="Select Account"
              label=""
              multiple={true}
              variant="base"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="account"
              autoSelectValue={false}
            />
            {/* <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">INVENTORY LOCATION</h1> */}
            {/* <Combobox
              id="Inventory Location"
              optionsAddItem={[]}
              events={{
                // onRequestOpen: (): void => {
                //   setOpenDropDownAccount(true);
                // },
                // onRequestClose: (): void => {
                //   setOpenDropDownAccount(false);
                // },
                onChange: handleChangeOnAccount,
                onRequestRemoveSelectedOption: handleRemoveSelectionAccount,
                onSelect: handleSelectItemAccount,
              }}
              // isOpen={openDropDownAccount}
              labels={{
                label: ``,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              options={FILTER_CYCLE_COUNT}
              multiple
              hasInputSpinner={userInfo?.businessunit === SPORTS_MED? loadingTerritory : loadingEligibleAccounts}
              selection={selectedFilters ? selectedFilters.inventoryLocation : [] || []}
              // menuItemVisibleLength={5}
              // menuPosition="relative"
              value={inputValueAccount}
              // variant="base"
            /> */}

            {/* <Combobox
              id="account"
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'inventoryLocation'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'inventoryLocation'),
              }}
              labels={{
                label: `Account`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              options={accountOptions || []}
              multiple
              placeholder="Select"
              options={inventoryLocation || []}
              selection={selectedFilters ? selectedFilters.inventoryLocation : [] || []}
              menuPosition="relative"
              variant="readonly"
            /> */}
          </div>
          {/* </div> */}
          {saveModalVisible && (
            <SaveFilter
              handleSaveModalVisible={handleSaveModalVisible}
              saveModalVisible={saveModalVisible}
              handleCreateFilterPreset={handleCreateFilterPreset}
              selectedFilterName={filterName}
            />
          )}
          {deleteModalVisible && (
            <DeleteFilterPreset
              deleteModalVisible={deleteModalVisible}
              handleDeleteModalVisible={handleDeleteModalVisible}
              handleDeleteFilterPreset={handleDeleteFilterPreset}
            />
          )}
        </section>
      </Modal>
    </IconSettings>
  );
};

export default FilterCycleCount;
