/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
// import Spinner from '@salesforce/design-system-react/components/spinner';
// import './index.scss';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Pill from '@salesforce/design-system-react/components/pill';
import SaveFilter from './SaveFilter';
import DatePicker from '../../../Shared/DatePicker';
import AutocompleteSingleSelectPicker from '../../../Shared/AutocompleteSingleSelectPicker';
import { GET_SALES_REPS } from '../../../../graphql/getSalesReps';
import { GET_USER_TERRITORIES } from '../../../../graphql/getUserTerritories';
import CREATE_UPDATE_INVENTORY_FILTER_PRESET from '../../../../graphql/mutations/createUpdateInventoryFilterPreset';
import {
  INVENTORY_STATUS_OPTIONS,
  INVENTORY_SERVICE_LEVEL_OPTIONS,
  RECORD_TYPE_OPTIONS,
  CATALOG_PARTS_OPTIONS,
  INVENTORY_3PL_OPTIONS,
  FILTER_ORDER_REVIEW_CATALOGED_PARTS_OPTIONS,
} from '../../../../util/constants';
import { GET_PRODUCT_GROUP } from '../../../../graphql/getProductGroups';
import { GET_PRIMARY_ACCOUNT } from '../../../../graphql/getPrimaryAccount';
import { GET_OPS_LIST } from '../../../../graphql/getOpsList';
import {
  getRegionTerritoryInfo,
  setRegionTerritoryInfo,
} from '../../../../store/ducks/userRegionAndTerritory';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import { GET_USER_REGION_AND_TERRITORIES } from '../../../../graphql/getUserRegionAndTerritories';
import UPDATE_FILTER_PRESET from '../../../../graphql/mutations/updateFilterPreset';
import DeleteFilterPreset from '../InventoryQueueHeader/DeleteFilterPreset';
import CREATE_FILTER_PRESET from '../../../../graphql/mutations/createFilterPreset';
import DELETE_FILTER_PRESET from '../../../../graphql/mutations/deleteFilterPreset';

interface DynamicType {
  [key: string]: any;
}
interface Props {
  openFilter: boolean;
  handleToggleFilter: () => void;
  applyFilters: (filters: object | null) => void;
  selectedFilter?: DynamicType | null;
  handleToggleFilterEvent: () => void;
  handleClearFilter: (externalId: string) => void;
  handleFilterEvents: (filter: DynamicType | null) => void;
  handleEditFilter: (editEvent: boolean) => void;
  refetchFilterPresets: () => void;
  isEditFilter: boolean;
}

interface SelectedData {
  id: string;
  label: string;
}
interface DropdownSelect {
  id?: string;
  label?: string;
  value?: string;
  userId?: string;
}

interface DateFilter {
  submitFromDate?: string;
  submitToDate?: string;
  needByDateFromDate?: string;
  needByDateToDate?: string;
}

interface OpsDropdownSelect {
  label: string;
  value: string;
  id: string;
}

type UserRegionAndTerritory = {
  id?: string;
  accountId: string;
  region: string;
  regionId: string;
  territory: string;
  territoryId: string;
  userId: string;
  __typename: string;
};

const optionsWithLabel = (arr: any): any[] =>
  arr &&
  arr.map((elem: any) => {
    return { ...elem, label: `${elem.value}` };
  });

const optionsWithLabelAccount = (arr: []): any[] =>
  arr &&
  arr.map((elem: any) => {
    return {
      ...elem,
      label:
        elem?.isRepStockAccount === true
          ? ` * ${elem.number} ${elem.value}`
          : ` ${elem.number} ${elem.value}`,
    };
  });

const InventoryQueueFilter: FC<Props> = ({
  openFilter,
  handleToggleFilter,
  applyFilters,
  selectedFilter,
  refetchFilterPresets,
  handleToggleFilterEvent,
  handleEditFilter,
  isEditFilter,
  handleClearFilter,
}) => {
  const dispatch = useDispatch();
  const userRegionTerritoryInfo: any = useSelector(getRegionTerritoryInfo);
  const filterName = selectedFilter ? selectedFilter.filterName : '';
  const filterValues = selectedFilter ? selectedFilter.filterValues : '';
  const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const { data: salesRepsData } = useQuery(GET_SALES_REPS);
  const location = useLocation();
  const [getOpsList, { data: opsData }] = useLazyQuery(GET_OPS_LIST);
  const [getRegionAndTerritory, { data: regionAndTerritoryData }] = useLazyQuery(
    GET_USER_REGION_AND_TERRITORIES
  );
  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [salesRepOptions, setSalesRepOptions] = useState<DropdownSelect[]>();
  const [accountsOptions, setAccountsOptions] = useState<DropdownSelect[]>();
  const [selectedSalesRep, setSelectedSalesRep] = useState<DropdownSelect[]>();
  const [selectedAccount, setSelectedAccount] = useState<DropdownSelect[]>();
  const [selectedStatus, setSelectedStatus] = useState<DropdownSelect[]>();
  const [selectedCatalogedParts, setSelectedCatalogedParts] = useState<DropdownSelect[]>();
  const [selectedRecordType, setSelectedRecordType] = useState<DropdownSelect[]>();
  const [selectedThreePl, setSelectedThreePl] = useState<DropdownSelect[]>();
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<DropdownSelect[]>();
  const [createFilterPreset, { data: filterPresets }] = useMutation(CREATE_FILTER_PRESET);
  const [updateFilterPreset, { data: updatedfilterPresets }] = useMutation(UPDATE_FILTER_PRESET);
  const [selectedServiceLevel, setSelectedServiceLevel] = useState<DropdownSelect[]>();
  const [selectedProductGroup, setSelectedProductGroup] = useState<DropdownSelect[]>();
  const [dates, setDates] = useState<DateFilter | null>(null);
  const [filter, setFilter] = useState<DynamicType | null>(selectedFilter || null);
  const [productGroup, { data: productGroupItems }] = useLazyQuery(GET_PRODUCT_GROUP);
  const productGroupList = productGroupItems?.getProductGroups;
  const [options, setOptions] = useState<OpsDropdownSelect[]>();
  const [territoryOptions, setTerritoryOptions] = useState<UserRegionAndTerritory[]>();
  const [regionOptions, setRegionOptions] = useState<UserRegionAndTerritory[]>();
  const [selectedRegion, setSelectedRegion] = useState<UserRegionAndTerritory[]>();
  const [selectedTerritory, setSelectedTerritory] = useState<UserRegionAndTerritory[]>();
  const [presetPayload, setPresetPayload] = useState<DynamicType | null>();
  const [deleteFilterPreset] = useMutation(DELETE_FILTER_PRESET);
  const optionsWithLabelProduct =
    productGroupList &&
    productGroupList.map((elem: any) => {
      return { ...elem, label: `${elem.value}` };
    });
  const userInfo = useSelector(getUserInfo);
  const [getPrimaryAccountItems, { data: primaryAccountItems }] = useLazyQuery(
    GET_PRIMARY_ACCOUNT,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const accountItemsData = primaryAccountItems?.getPrimaryAccount;
  useEffect(() => {
    if (salesRepsData?.getSalesReps) {
      setSalesRepOptions(optionsWithLabel(salesRepsData.getSalesReps));
    }
  }, [salesRepsData]);

  useEffect(() => {
    if (accountItemsData) {
      setAccountsOptions(optionsWithLabelAccount(accountItemsData));
    }
  }, [accountItemsData]);

  useEffect(() => {
    getPrimaryAccountItems({
      variables: {
        salesRepId: userInfo?.id,
        limit: 500,
      },
    });
  }, [getPrimaryAccountItems, userInfo]);
  useEffect(() => {
    if (userInfo) {
      getOpsList({
        variables: {
          personaName: userInfo?.personas,
        },
      });
    }
  }, [getOpsList, userInfo]);
  useEffect(() => {
    productGroup();
  }, [productGroup]);

  useEffect(() => {
    if (filterValues) {
      if (filterValues?.assignedTo) {
        setSelectedAssignedTo(filterValues?.assignedTo);
      }
      if (filterValues?.status) {
        setSelectedStatus(filterValues?.status);
      }
      if (filterValues?.serviceLevel) {
        setSelectedServiceLevel(filterValues?.serviceLevel);
      }
      if (filterValues?.iscataloged) {
        setSelectedCatalogedParts(filterValues?.iscataloged);
      }
      if (filterValues?.account) {
        setSelectedAccount(filterValues?.account);
      }
      if (filterValues?.recordType) {
        setSelectedRecordType(filterValues?.recordType);
      }
      if (filterValues?.threePL) {
        setSelectedThreePl(filterValues?.threePL);
      }
      if (filterValues?.productGroup) {
        setSelectedProductGroup(filterValues?.productGroup);
      }
      if (filterValues?.territory) {
        setSelectedTerritory(filterValues?.territory);
      }
      if (filterValues?.region) {
        setSelectedRegion(filterValues?.region);
      }
      if (filterValues?.salesRep) {
        setSelectedSalesRep(filterValues?.salesRep);
      }
    }
  }, [filterValues]);

  useEffect(() => {
    if (selectedFilter && !filterExternalId) {
      if (selectedFilter.assignedTo) {
        const filteredAssign = options?.filter(
          (item: any) => item.id === selectedFilter.assignedTo[0]
        );
        setSelectedAssignedTo(filteredAssign);
      } else {
        setSelectedAssignedTo([]);
      }
      if (selectedFilter.status) {
        const output = selectedFilter.status.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedStatus(output);
      } else {
        setSelectedStatus([]);
      }
      if (selectedFilter.productGroup) {
        const output = selectedFilter.productGroup.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedProductGroup(output);
      } else {
        setSelectedProductGroup([]);
      }
      if (selectedFilter.serviceLevel) {
        const output = selectedFilter.serviceLevel.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedServiceLevel(output);
      } else {
        setSelectedServiceLevel([]);
      }
      if (selectedFilter.zipCode) {
        const output = selectedFilter.zipCode.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedTerritory(output);
      } else {
        setSelectedTerritory([]);
      }
      if (selectedFilter.iscataloged) {
        const output = selectedFilter.iscataloged.map((item: any) => ({
          id: item,
          value: item,
          label: item,
        }));
        setSelectedCatalogedParts(output);
      } else {
        setSelectedCatalogedParts([]);
      }
      if (selectedFilter.threePL) {
        const output = selectedFilter.threePL.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedThreePl(output);
      } else {
        setSelectedThreePl([]);
      }
      if (selectedFilter.recordType) {
        const output = selectedFilter.recordType.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedRecordType(output);
      } else {
        setSelectedRecordType([]);
      }
      if (selectedFilter.salesRep) {
        const filteredSalesRep = salesRepOptions?.filter(
          (item: any) => item.id === selectedFilter?.salesRep?.[0]
        );
        setSelectedSalesRep(filteredSalesRep);
      } else {
        setSelectedSalesRep([]);
      }
      if (selectedFilter.region) {
        const filteredRegion = regionOptions?.filter(
          (item: any) => item.id === selectedFilter?.region?.[0]
        );
        setSelectedRegion(filteredRegion);
      } else {
        setSelectedRegion([]);
      }
      if (selectedFilter.territory) {
        const filteredTerritory = territoryOptions?.filter(
          (item: any) => item.id === selectedFilter?.territory?.[0]
        );
        setSelectedTerritory(filteredTerritory);
      } else {
        setSelectedTerritory([]);
      }
      if (selectedFilter.account) {
        const filteredSalesRep = accountsOptions?.filter(
          (item: any) => item.id === selectedFilter.account[0]
        );
        setSelectedAccount(filteredSalesRep);
      } else {
        setSelectedAccount([]);
      }
    }
  }, [selectedFilter, options, salesRepOptions, accountsOptions]);

  useEffect(() => {
    if (opsData && opsData?.getOPSList) {
      const optionList = optionsWithLabel(opsData?.getOPSList);
      optionList.unshift({ id: 'Unassigned', value: 'Unassigned', label: 'UNASSIGNED' });
      setOptions(optionList);
    }
  }, [opsData]);

  function removeTypename(jsonData: any): any {
    for (const key in jsonData) {
      if (Array.isArray(jsonData[key])) {
        jsonData[key] = jsonData[key].map((item: any) => {
          const { __typename: _, ...newItem } = item;
          return newItem;
        });
      }
    }
    return jsonData;
  }

  useEffect(() => {
    if (selectedFilter?.filterValues) {
      const isTruthy = (value: any): boolean => {
        return value !== null && value !== undefined && value !== false;
      };
      const filteredObject = Object.fromEntries(
        Object.entries(selectedFilter?.filterValues).filter(([key, value]) => isTruthy(value))
      );
      removeTypename(filteredObject);
      setFilter(filteredObject);
      setPresetPayload(filteredObject);
    }
  }, [selectedFilter]);

  const handleSaveModalVisible = (): void => {
    setSaveModalVisible(!saveModalVisible);
  };

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const handleDeleteModalVisible = useCallback((): void => {
    setDeleteModalVisible(!deleteModalVisible);
  }, [deleteModalVisible]);

  const handleDeleteFilterPreset = (): void => {
    deleteFilterPreset({
      variables: {
        externalId: filterExternalId,
      },
    }).then((response: any) => {
      // if delete filter preset is successful
      const deleteUsageResponse =
        response.data &&
        response.data.deleteFilterPreset &&
        response.data.deleteFilterPreset.message === 'success';
      if (deleteUsageResponse) {
        handleDeleteModalVisible();
        handleToggleFilterEvent();
        handleClearFilter(filterExternalId);
        if (refetchFilterPresets) {
          handleEditFilter(false);
          refetchFilterPresets();
        }
      }
    });
  };

  const handleCreateFilterPreset = (name: string): void => {
    delete presetPayload?.__typename;
    if (!filterExternalId || filterExternalId === '') {
      createFilterPreset({
        variables: {
          userId: userInfo?.id,
          filterValues: presetPayload,
          name,
          type: 'Review Request',
        },
      })
        .then(res => {
          if (res && res?.data && res?.data?.createFilterPreset?.message === 'success') {
            setSaveModalVisible(!saveModalVisible);
            applyFilters(filter);
            handleToggleFilter();
            refetchFilterPresets();
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    } else if (filterExternalId) {
      updateFilterPreset({
        variables: {
          externalId: filterExternalId,
          filterValues: presetPayload,
          name,
          type: 'Review Request',
        },
      });
    }
  };

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate && name) {
      const obj = {
        ...filter,
        [name]: moment(selectedDate).format('YYYY-MM-DD'),
      };
      setFilter(obj);
      setDates({ ...dates, [name]: selectedDate });
    }
  };

  const removeSelectedData = (name: string, data: any): void => {
    switch (name) {
      case 'recordType':
        setSelectedRecordType(data);
        break;
      case 'status':
        setSelectedStatus(data);
        break;
      case 'threePL':
        setSelectedThreePl(data);
        break;
      case 'productGroup':
        setSelectedProductGroup(data);
        break;
      case 'iscataloged':
        setSelectedCatalogedParts(data);
        break;
      case 'salesRep': {
        setSelectedSalesRep(data);
        setSelectedRegion(data);
        setSelectedTerritory(data);
        break;
      }
      case 'region': {
        setSelectedRegion(data);
        setSelectedTerritory(data);
        break;
      }
      case 'territory':
        setSelectedTerritory(data);
        break;
      case 'account':
        setSelectedAccount(data);
        break;
      case 'assignedTo':
        setSelectedAssignedTo(data);
        break;
      case 'serviceLevel':
        setSelectedServiceLevel(data);
        break;
      default:
        break;
    }
  };

  const setSelelctedFilterData = (name: string, data: any): void => {
    switch (name) {
      case 'recordType':
        setSelectedRecordType(data.selection);
        break;
      case 'iscataloged':
        setSelectedCatalogedParts(data.selection);
        break;
      case 'threePL':
        setSelectedThreePl(data.selection);
        break;
      case 'productGroup':
        setSelectedProductGroup(data.selection);
        break;
      case 'serviceLevel':
        setSelectedServiceLevel(data.selection);
        break;
      case 'status':
        setSelectedStatus(data.selection);
        break;
      case 'salesRep': {
        setSelectedSalesRep(data);
        setSelectedRegion([]);
        setSelectedTerritory([]);
        break;
      }
      case 'account':
        setSelectedAccount(data);
        break;
      case 'assignedTo':
        setSelectedAssignedTo(data);
        break;
      case 'region': {
        setSelectedRegion(data);
        setSelectedTerritory([]);
        break;
      }
      case 'territory':
        setSelectedTerritory(data);
        break;
      default:
        break;
    }
  };

  const preparePresetPayload = (name: string, data: any): void => {
    let presetFilterData: any;
    if (data.selection) {
      presetFilterData = data.selection.map((item: any) => {
        return { id: item.id ? item.id : item.label, label: item.label };
      });
    } else {
      presetFilterData = data.map((item: any) => {
        return { id: item.id ? item.id : item.label, label: item.label };
      });
    }
    const temp = {
      ...presetPayload,
      [name]: presetFilterData,
    };
    setPresetPayload(temp);
  };

  const prepareFilterPayload = (name: string, data: any): void => {
    let filterData: any;
    if (data.selection) {
      filterData = data.selection.map((item: any) => (item.id ? item.id : item.value));
    } else {
      filterData = data.map((item: any) => (item.id ? item.id : item.value));
    }
    const obj = {
      ...filter,
      [name]: filterData,
    };
    setFilter(obj);
  };

  const handleSelect = (data: any, name: any): void => {
    if (Array.isArray(data) && !data.length) {
      removeSelectedData(name, data);
    } else {
      setSelelctedFilterData(name, data);
      preparePresetPayload(name, data);
      prepareFilterPayload(name, data);
    }
  };

  const handleApplyFilter = (): void => {
    applyFilters(filter);
    handleToggleFilter();
    handleToggleFilterEvent();
  };
  const resetFilters = useCallback((): void => {
    setSelectedSalesRep([]);
    setSelectedAccount([]);
    setSelectedRegion([]);
    setSelectedTerritory([]);
    setSelectedStatus([]);
    setSelectedAssignedTo([]);
    setSelectedServiceLevel([]);
    setSelectedCatalogedParts([]);
    setSelectedThreePl([]);
    setSelectedRecordType([]);
    setSelectedProductGroup([]);
    setDates(null);
    setFilter(null);
    applyFilters(null);
  }, [applyFilters]);

  useEffect(() => {
    if (
      updatedfilterPresets &&
      updatedfilterPresets.updateFilterPreset &&
      updatedfilterPresets.updateFilterPreset.message === 'success'
    ) {
      handleToggleFilterEvent();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
        resetFilters();
      }
    }
  }, [
    handleEditFilter,
    handleToggleFilterEvent,
    refetchFilterPresets,
    resetFilters,
    updatedfilterPresets,
  ]);

  useEffect(() => {
    if (userInfo) {
      getRegionAndTerritory({
        variables: {
          userId: userInfo?.id,
        },
      });
    }
  }, [getRegionAndTerritory, userInfo]);

  const getUniqueRegionTerritory = (array: UserRegionAndTerritory[]): UserRegionAndTerritory[] => {
    const cleanedArray = array.filter((item: any) => item !== undefined && item !== null);
    return cleanedArray.filter(
      (item: UserRegionAndTerritory, index: number, self: UserRegionAndTerritory[]) =>
        index === self.findIndex((element: UserRegionAndTerritory) => item.id === element.id)
    );
  };

  const getRegionTerritoryOptions = (): void => {
    let tempRegions: any[] = [];
    let tempTerritories: any[] = [];
    if (selectedSalesRep?.length && !selectedRegion?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item.userId === selectedSalesRep[0].id || item.id === selectedSalesRep[0].id) {
          tempRegions.push({ id: item.regionId, label: item.region });
          tempTerritories.push({ id: item.territoryId, label: item.territory });
        }
      });
    }
    if (selectedSalesRep?.length && selectedRegion?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (
          item?.userId === selectedSalesRep?.[0]?.id &&
          item?.regionId === selectedRegion?.[0]?.id
        )
          tempTerritories.push({ id: item.territoryId, label: item.territory });
      });
    }
    if (!selectedSalesRep?.length && selectedRegion?.length) {
      userRegionTerritoryInfo?.map((item: any) => {
        if (item?.regionId === selectedRegion?.[0]?.id)
          tempTerritories.push({ id: item.territoryId, label: item.territory });
      });
    }
    if (!selectedSalesRep?.length && !selectedRegion?.length) {
      tempRegions = userRegionTerritoryInfo?.map((item: any, index: number) => {
        return { id: item.regionId, label: item.region };
      });
      tempTerritories = userRegionTerritoryInfo?.map((item: any, index: number) => {
        return { id: item.territoryId, label: item.territory };
      });
    }
    setRegionOptions(getUniqueRegionTerritory(tempRegions));
    setTerritoryOptions(getUniqueRegionTerritory(tempTerritories));
  };

  useEffect(() => {
    if (userRegionTerritoryInfo) getRegionTerritoryOptions();
  }, [selectedSalesRep, selectedRegion, selectedTerritory, userRegionTerritoryInfo]);

  useEffect(() => {
    if (regionAndTerritoryData)
      dispatch(setRegionTerritoryInfo(regionAndTerritoryData?.getUserRegionAndTerritories));
  }, [regionAndTerritoryData]);

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={openFilter}
        onRequestClose={handleToggleFilterEvent}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Save Filters"
            onClick={handleSaveModalVisible}
          />,
          <Button label="Reset Filter" key="Reset" onClick={resetFilters} />,
          <Button
            className="yellow-btn"
            label="Apply Filters"
            onClick={handleApplyFilter}
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            {`${
              filterName && filterName !== ''
                ? `${isEditFilter ? 'Edit ' : ''}Filter Preset ${filterName}`
                : 'Filters'
            }`}
          </div>,

          isEditFilter && (
            <div className="slds-float_right" key="delete">
              <Button
                className="delete_button"
                label="Delete Preset"
                key="delete"
                onClick={handleDeleteModalVisible}
              />
            </div>
          ),
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          <div className="slds-card slds-p-around--medium">
            <span className="filtterTabName">Attributes</span>
            <Combobox
              id="recordType"
              labels={{
                label: `Record Type`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openType}
              multiple
              placeholder="Select"
              options={RECORD_TYPE_OPTIONS || []}
              selection={selectedRecordType || []}
              variant="readonly"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpen(false);
                  setOpenType(true);
                },
                onRequestClose: (event: any): void => {
                  setOpenType(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'recordType'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'recordType'),
              }}
            />
            <Combobox
              id="status"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpen(true);
                  setOpenType(false);
                },
                onRequestClose: (event: any): void => {
                  setOpen(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'status'),
              }}
              labels={{
                label: `Status`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={open}
              multiple
              placeholder="Select"
              options={INVENTORY_STATUS_OPTIONS || []}
              selection={selectedStatus || []}
              variant="readonly"
            />
            <Combobox
              id="3PL Delivery"
              labels={{
                label: `3PL Delivery`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'threePL'),
              }}
              placeholder="Select"
              options={INVENTORY_3PL_OPTIONS || []}
              selection={selectedThreePl || []}
              variant="readonly"
            />
            <Combobox
              id="productGroup"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpen(false);
                  setOpenType(false);
                  setOpenProduct(true);
                },
                onRequestClose: (event: any): void => {
                  setOpenProduct(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'productGroup'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'productGroup'),
              }}
              labels={{
                label: `Product Group`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openProduct}
              multiple
              placeholder="Select"
              options={optionsWithLabelProduct || []}
              selection={selectedProductGroup ? selectedProductGroup || [] : []}
              variant="readonly"
            />
            <Combobox
              id="iscataloged"
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'iscataloged'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'iscataloged'),
              }}
              labels={{
                label: `Cataloged Parts`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              placeholder="Select"
              options={FILTER_ORDER_REVIEW_CATALOGED_PARTS_OPTIONS || []}
              selection={selectedCatalogedParts ? selectedCatalogedParts || [] : []}
              multiple
              variant="readonly"
            />
          </div>
          {/* </section> */}
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">Date</h1>
            <div className="date-error">
              {((dates?.needByDateToDate &&
                dates?.needByDateFromDate &&
                dates?.needByDateToDate < dates?.needByDateFromDate) ||
                (dates?.submitToDate &&
                  dates?.submitFromDate &&
                  dates?.submitToDate < dates?.submitFromDate)) && (
                <Pill
                  labels={{
                    label: 'Error: To Date must be greater than or equal to From Date',
                  }}
                  hasError
                />
              )}
            </div>
            <div>
              <p className="dateLabel">Submitted Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="From"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'submitFromDate')}
                    disabled={false}
                    isFilterDate
                    value={dates?.submitFromDate || null}
                  />
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <span>-</span>
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="To"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'submitToDate')}
                    disabled={false}
                    isFilterDate
                    value={dates?.submitToDate || null}
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="dateLabel">Need by Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="From"
                    handleChangeDate={(data: any): void => {
                      handleChangeDate(data, 'needByDateFromDate');
                    }}
                    disabled={false}
                    isFilterDate
                    value={dates?.needByDateFromDate || null}
                  />
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <span>-</span>
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="To"
                    handleChangeDate={(data: any): void => {
                      handleChangeDate(data, 'needByDateToDate');
                    }}
                    disabled={false}
                    isFilterDate
                    value={dates?.needByDateToDate || null}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <section className="slds-p-around_medium slds-theme_shade"> */}
          <div className="slds-card slds-p-around--medium">
            <span className="filtterTabName">Other</span>
            <p style={{ marginBottom: -12, fontSize: 16 }}>Sales Rep</p>
            <AutocompleteSingleSelectPicker
              items={salesRepOptions || []}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'salesRep');
              }}
              disabled={false}
              selectedItem={selectedSalesRep ? selectedSalesRep || [] : []}
              placeholder="Sales Rep"
              label=""
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="salesRep"
              autoSelectValue={false}
            />
            <AutocompleteSingleSelectPicker
              items={regionOptions || []}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'region');
              }}
              disabled={false}
              selectedItem={selectedRegion ? selectedRegion || [] : []}
              placeholder="Select Region"
              label="Region"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="region"
              autoSelectValue={false}
            />
            <AutocompleteSingleSelectPicker
              items={territoryOptions || []}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'territory');
              }}
              disabled={false}
              selectedItem={selectedTerritory ? selectedTerritory || [] : []}
              placeholder="Select Territory"
              label="Territory"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="territory"
              autoSelectValue={false}
            />
            <AutocompleteSingleSelectPicker
              items={accountsOptions}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'account');
              }}
              disabled={false}
              selectedItem={selectedAccount || []}
              placeholder="Account"
              label="Account"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="account"
              autoSelectValue={false}
            />
            <AutocompleteSingleSelectPicker
              items={options}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'assignedTo');
              }}
              disabled={false}
              selectedItem={selectedAssignedTo || []}
              placeholder="Assigned To"
              label="Assigned To"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="assignedTo"
              autoSelectValue={false}
            />
            <Combobox
              id="serviceLevel"
              labels={{
                label: `Service Level`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'serviceLevel'),
              }}
              placeholder="Select"
              options={INVENTORY_SERVICE_LEVEL_OPTIONS || []}
              selection={selectedServiceLevel || []}
              variant="readonly"
            />
          </div>
        </section>
        {saveModalVisible && (
          <SaveFilter
            handleSaveModalVisible={handleSaveModalVisible}
            saveModalVisible={saveModalVisible}
            handleCreateFilterPreset={handleCreateFilterPreset}
            selectedFilterName={filterName}
          />
        )}
        {deleteModalVisible && (
          <DeleteFilterPreset
            deleteModalVisible={deleteModalVisible}
            handleDeleteModalVisible={handleDeleteModalVisible}
            handleDeleteFilterPreset={handleDeleteFilterPreset}
          />
        )}
      </Modal>
    </IconSettings>
  );
};

export default InventoryQueueFilter;
