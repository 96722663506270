/*
 * Get Multiple PDF GraphQL specification
 * @module src/graphql/getMultiplePDFData
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_MULTIPLE_PDF_DATA = gql`
  query getMultiplePDFData(
    $limit: Int
    $offset: Int
    $salesRepId: String
    $filters: GetMultiplePDFFilter
  ) {
    getMultiplePDFData(limit: $limit, offset: $offset, salesRepId: $salesRepId, filters: $filters) {
      requestId
      details {
        status
        caseId
        requestId
        requestedDate
        failure_reason
        attachmentid
        parentStatus
        expiredOn
        accountName
        accountId
        salesrepId
        CaseSfid
        surgeryDate
      }
      __typename
    }
  }
`;
